import axios from 'axios'
import env from './env'
// import ServiceCrypto from '../crypto/classes/ServiceCrypto'

const axiosClient = axios.create({
  baseURL: env.API_URL
})

/*axiosClient.interceptors.request.use(
  (request) => {
    // console.log('interceptor request', request)

    const token = sessionStorage.getItem('_k')
    if (token && token !== null)
      request.headers['Authorization'] = `Bearer ${token}`

    const encryptKeys = request.encryptKeys
    if (!encryptKeys) return request

    const {
      accesoPublico,
      accesoPrivado,
      accesoSimetrico,
      codigoAutentificacionHash
    } = encryptKeys

    const cipher = new ServiceCrypto(
      accesoPublico,
      accesoPrivado,
      accesoSimetrico,
      codigoAutentificacionHash
    )

    const cipherData = request.data
      ? cipher.encrypt(request.url, request.data)
      : null

    request.data = cipherData

    return request
  }, error => {
    console.log('interceptor request error: ', error.data)
    console.log(error.data)
    console.log(error.status)
    console.log(error.headers)
    return Promise.reject(error)
  }
)*/

/*axiosClient.interceptors.response.use(
  (response) => {
    // console.log('interceptor response', response)

    const { encryptKeys } = response.config
    if (!encryptKeys) return response

    const {
      accesoPublico,
      accesoPrivado,
      accesoSimetrico,
      codigoAutentificacionHash
    } = encryptKeys
    const responseResultado = response.data.resultado || {}
    const url = response.config.url

    const decipher = new ServiceCrypto(
      accesoPublico,
      accesoPrivado,
      accesoSimetrico,
      codigoAutentificacionHash
    )
    const decipherResultado = Object.entries(responseResultado).length
      ? decipher.decrypt(url, responseResultado)
      : null

    response.data.resultado = decipherResultado

    return response
  }, error => {
    console.log('interceptor response error: ', error.response)
    console.log(error.data)
    console.log(error.status)
    console.log(error.headers)

    return Promise.reject(error)
  }
)*/

axiosClient.interceptors.request.use(request => {
  // console.log('interceptor request', request)
  const token = sessionStorage.getItem('_k')
  if (token)
    request.headers['Authorization'] = `Bearer ${token}`
  return request
})

axiosClient.interceptors.response.use(response => {
  // console.log('interceptor response', response)

  return response
})

export default axiosClient
