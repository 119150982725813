import {
  deleteUser,
  fetchChangePassword,
  fetchForgotPassword,
  fetchLogin,
  fetchLogout,
  fetchModules,
  fetchNewPassword,
  fetchRefreshToken,
  fetchSession,
  fetchUser,
  fetchUserProfile,
  fetchUsers,
  saveUser,
  updateUser
} from '../services/auth.services'
import Swal from 'sweetalert2'

export const LOADER_ON = 'LOADER_ON'
export const LOADER_OFF = 'LOADER_OFF'
export const SESSION_INIT = 'SESSION_INIT'
export const SESSION_SUCCESS = 'SESSION_SUCCESS'
export const SESSION_ERROR = 'SESSION_ERROR'
export const LOGIN_INIT = 'LOGIN_INIT'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_ERROR = 'LOGIN_ERROR'
export const EXPIRE_PASSWORD = 'EXPIRE_PASSWORD'
export const LOGOUT_INIT = 'LOGOUT_INIT'
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS'
export const LOGOUT_ERROR = 'LOGOUT_ERROR'
export const REFRESH_TOKEN_INIT = 'REFRESH_TOKEN_INIT'
export const REFRESH_TOKEN_SUCCESS = 'REFRESH_TOKEN_SUCCESS'
export const REFRESH_TOKEN_ERROR = 'REFRESH_TOKEN_ERROR'
export const FOTGOT_PASSWORD_INIT = 'FOTGOT_PASSWORD_INIT'
export const FOTGOT_PASSWORD_SUCCESS = 'FOTGOT_PASSWORD_SUCCESS'
export const FOTGOT_PASSWORD_ERROR = 'FOTGOT_PASSWORD_ERROR'
export const NEW_PASSWORD_INIT = 'NEW_PASSWORD_INIT'
export const NEW_PASSWORD_SUCCESS = 'NEW_PASSWORD_SUCCESS'
export const NEW_PASSWORD_ERROR = 'NEW_PASSWORD_ERROR'
export const USER_PROFILE_INIT = 'USER_PROFILE_INIT'
export const USER_PROFILE_SUCCESS = 'USER_PROFILE_SUCCESS'
export const USER_PROFILE_ERROR = 'USER_PROFILE_ERROR'
export const CHECK_IF_USER_IS_LOGGED_IN = 'CHECK_IF_USER_IS_LOGGED_IN'
export const GET_ALL_USER_BY_ROLE_ERROR = 'GET_ALL_USER_BY_ROLE_ERROR'
export const GET_ALL_USER_BY_ROLE_INIT = 'GET_ALL_USER_BY_ROLE_INIT'
export const GET_ALL_USER_BY_ROLE_SUCCESS = 'GET_ALL_USER_BY_ROLE_SUCCESS'
export const GET_MODULES_ERROR = 'GET_MODULES_ERROR'
export const GET_MODULES_INIT = 'GET_MODULES_INIT'
export const GET_MODULES_SUCCESS = 'GET_MODULES_SUCCESS'
export const NEW_USER_INIT = 'NEW_USER_INIT'
export const NEW_USER_SUCCESS = 'NEW_USER_SUCCESS'
export const NEW_USER_ERROR = 'NEW_USER_ERROR'
export const GET_USERS_INIT = 'GET_USERS_INIT'
export const GET_USERS_SUCCESS = 'GET_USERS_SUCCESS'
export const GET_USERS_ERROR = 'GET_USERS_ERROR'
export const GET_USER_INIT = 'GET_USER_INIT'
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS'
export const GET_USER_ERROR = 'GET_USER_ERROR'
export const UPDATE_USER_INIT = 'UPDATE_USER_INIT'
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS'
export const UPDATE_USER_ERROR = 'UPDATE_USER_ERROR'
export const DELETE_USER_INIT = 'DELETE_USER_INIT'
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS'
export const DELETE_USER_ERROR = 'DELETE_USER_ERROR'
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS'
export const CHANGE_PASSWORD_ERROR = 'CHANGE_PASSWORD_ERROR'
export const CHANGE_PASSWORD_INIT = 'CHANGE_PASSWORD_INIT'

export function checkIfUserIsLoggedIn() {
  return dispatch => {
    const token = sessionStorage.getItem('_k')
    const user = JSON.parse(sessionStorage.getItem('_u'))
    dispatch({ type: CHECK_IF_USER_IS_LOGGED_IN, token, user })
  }
}

export function session(username, password, encryptKeys) {
  return async (dispatch) => {
    try {
      dispatch({ type: SESSION_INIT })
      const result = await fetchSession(username, password, encryptKeys)
      const data = result.data.data
      console.log('session: data: 🍄🍄🍄🍄🍄 ', data)
      sessionStorage.setItem('_sa', 'false')

      // if (result && result.data.data.jwt) {
      if (data && data.jwt) {
        console.log('session: data: 🍄🍄🍄🍄🍄 user: ', data.jwt_decode.user[0])
        // result.data.usuario = { roleId: 1, name: 'Miguel Angel' }
        sessionStorage.setItem('_k', data.jwt)
        sessionStorage.setItem('_e', data.jwt_decode.exp)
        sessionStorage.setItem('_u', JSON.stringify(data.jwt_decode.user[0]))
        sessionStorage.setItem('_unknown', 'true')
        dispatch({ type: SESSION_SUCCESS, data: { ...data } })
      } else {
        dispatch({ type: SESSION_ERROR })
        await Swal.fire(
          'Advertencia',
          'Ha ocurrido algo inesperado, contacte a soporte',
          'warning'
        )
      }
    } catch (e) {
      console.log(e)
      const sessionActive =
        e.response.data.detalles[0] ===
        'El usuario cuenta con una sesion activa, puedes intentar nuevamente despúes de 10 minutos.'
      if (sessionActive) {
        Swal.fire({
          title: 'Error',
          text: e.response.message,
          confirmButtonText: 'Aceptar'
        }).then(async (result) => {
          if (result.isConfirmed) {
            dispatch({ type: SESSION_ERROR })
          }
        })
      } else {
        await Swal.fire('Error', e.response.data.detalles[0], 'warning')
      }
    }
  }
}

export function login(username, password, encryptKeys) {
  return async (dispatch) => {
    try {
      dispatch({ type: LOGIN_INIT })
      const result = await fetchLogin(username, password, encryptKeys)
      sessionStorage.setItem('_sa', 'false')
      if (result.data.resultado.usuario.contraseniaExpirada === true) {
        sessionStorage.setItem('_k', result.data.resultado.token)
        sessionStorage.setItem('_e', result.data.resultado.expiracionToken)
        dispatch({ type: EXPIRE_PASSWORD, data: { ...result.data.resultado } })
      } else {
        if (result.data.resultado.usuario) {
          sessionStorage.setItem('_k', result.data.resultado.token)
          sessionStorage.setItem('_e', result.data.resultado.expiracionToken)
          sessionStorage.setItem('_unknown', 'true')
          sessionStorage.setItem('_u', JSON.stringify(result.data.resultado.usuario))

          const resultUserProfile = await fetchUserProfile(result.data.resultado.token)
          localStorage.setItem('_p', JSON.stringify(resultUserProfile.data.resultado.perfilAccesos))

          const newState = {
            ...result.data.resultado,
            profile: { ...resultUserProfile.data.resultado.perfilAccesos }
          }
          dispatch({ type: LOGIN_SUCCESS, data: { ...newState } })
        } else {
          dispatch({ type: LOGIN_ERROR })
          await Swal.fire(
            'Advertencia',
            'Ha ocurrido algo inesperado, contacte a soporte',
            'warning'
          )
        }
      }
    } catch (e) {
      const sessionActive =
        e.response.data.detalles[0] ===
        'El usuario cuenta con una sesion activa, puedes intentar nuevamente despúes de 10 minutos.'
      if (sessionActive) {
        Swal.fire({
          title: 'Error',
          text: e.response.data.detalles[0],
          confirmButtonText: 'Aceptar'
        }).then(async (result) => {
          if (result.isConfirmed) {
            dispatch({ type: LOGIN_ERROR })
          }
        })
      } else {
        await Swal.fire('Error', e.response.data.detalles[0], 'warning')
      }
    }
  }
}

export function logout() {
  return async (dispatch) => {
    try {
      dispatch({ type: LOGOUT_INIT })
      const result = await fetchLogout()
      // console.log('logout: result: ', result)
      dispatch({ type: LOGOUT_SUCCESS, data: { ...result.data } })
    } catch (e) {
      console.log('logout: error: ', e)
      dispatch({ type: LOGOUT_ERROR })
      await Swal.fire('Error', e.response.data.detalles[0], 'warning')
    }
  }
}

export function getUserProfile(userId) {
  return async (dispatch) => {
    try {
      dispatch({ type: USER_PROFILE_INIT })
      const result = await fetchUserProfile(userId)
      // console.log(result)
      sessionStorage.setItem('_u', JSON.stringify(result.data.resultado.usuario))
      localStorage.setItem('_p', JSON.stringify(result.data.resultado.perfilAccesos))
      dispatch({
        type: USER_PROFILE_SUCCESS,
        profile: { ...result.data.resultado.perfilAccesos }
      })
    } catch (e) {
      console.log('getUserProfile: error: ', e)
      dispatch({ type: USER_PROFILE_ERROR })
      await Swal.fire('Error', e.response.data.detalles[0], 'warning')
    }
  }
}

export function refreshToken() {
  return async (dispatch) => {
    try {
      dispatch({ type: REFRESH_TOKEN_INIT })
      const result = await fetchRefreshToken()
      // console.log(result)
      sessionStorage.setItem('_k', result.data.resultado.token)
      sessionStorage.setItem('_e', +result.data.resultado.expiracionToken)
      dispatch({ type: REFRESH_TOKEN_SUCCESS, data: { ...result.data } })
    } catch (e) {
      console.log('refreshToken: error: ', e)
      dispatch({ type: REFRESH_TOKEN_ERROR })
      await Swal.fire('Error', e.response.data.detalles[0], 'warning')
    }
  }
}

export function forgotPassword(email, encryptKeys) {
  return async (dispatch) => {
    try {
      dispatch({ type: FOTGOT_PASSWORD_INIT })
      const result = await fetchForgotPassword(email, encryptKeys)
      // console.log(result)
      dispatch({ type: FOTGOT_PASSWORD_SUCCESS, data: { ...result.data } })
    } catch (e) {
      console.log('forgotPassword: error: ', e)
      dispatch({ type: FOTGOT_PASSWORD_ERROR })
      await Swal.fire('Error', e.response.data.detalles[0], 'warning')
    }
  }
}

export function getModules() {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_MODULES_INIT })
      const result = await fetchModules()
      // console.log(result)
      dispatch({ type: GET_MODULES_SUCCESS, data: { ...result.data } })
    } catch (e) {
      console.log('getModules: error: ', e)
      dispatch({ type: GET_MODULES_ERROR })
      await Swal.fire('Error', e.response.data.detalles[0], 'warning')
    }
  }
}

export function newPassword(values, encryptKeys) {
  return async (dispatch) => {
    try {
      dispatch({ type: NEW_PASSWORD_INIT })
      const result = await fetchNewPassword(values, encryptKeys)
      // console.log(result)
      dispatch({ type: NEW_PASSWORD_SUCCESS, data: { ...result.data } })
    } catch (e) {
      console.log('newPassword: error: ', e)
      dispatch({ type: NEW_PASSWORD_ERROR })
      await Swal.fire('Error', e.response.data.detalles[0], 'warning')
    }
  }
}

export function newUser(values) {
  return async (dispatch) => {
    try {
      dispatch({ type: NEW_USER_INIT })
      const result = await saveUser(values)
      // console.log(result)
      dispatch({ type: NEW_USER_SUCCESS, data: { ...result.data } })
    } catch (e) {
      console.log('newUser: error: ', e)
      dispatch({ type: NEW_USER_ERROR })
      await Swal.fire('Error', e.response.data.detalles[0], 'warning')
    }
  }
}

export function getUsers() {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_USERS_INIT })
      const result = await fetchUsers()
      // console.log(result)
      dispatch({ type: GET_USERS_SUCCESS, data: { ...result.data } })
    } catch (e) {
      console.log('getUsers: error: ', e)
      dispatch({ type: GET_USERS_ERROR })
      await Swal.fire('Error', e.response.data.detalles[0], 'warning')
    }
  }
}

export function retrieveUser(userId) {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_USER_INIT })
      const result = await fetchUser(userId)
      // console.log(result)
      dispatch({ type: GET_USER_SUCCESS, data: { ...result.data } })
    } catch (e) {
      console.log('retrieveUser: error: ', e)
      dispatch({ type: GET_USER_ERROR })
      await Swal.fire('Error', e.response.data.detalles[0], 'warning')
    }
  }
}

export function editUser(values) {
  return async (dispatch) => {
    try {
      dispatch({ type: UPDATE_USER_INIT })
      const result = await updateUser(values)
      // console.log(result)
      dispatch({ type: UPDATE_USER_SUCCESS, data: { ...result.data } })
    } catch (e) {
      console.log('editUser: error: ', e)
      dispatch({ type: UPDATE_USER_ERROR })
      await Swal.fire('Error', e.response.data.detalles[0], 'warning')
    }
  }
}

export function removeUser(userId) {
  return async (dispatch) => {
    try {
      dispatch({ type: DELETE_USER_INIT })
      const result = await deleteUser(userId)
      // console.log(result)
      dispatch({ type: DELETE_USER_SUCCESS, data: { ...result.data } })
    } catch (e) {
      console.log('removeUser: error: ', e)
      dispatch({ type: DELETE_USER_ERROR })
      await Swal.fire('Error', e.response.data.detalles[0], 'warning')
    }
  }
}

export function changePassword(contrasenia, newContrasenia, encryptKeys) {
  return async (dispatch) => {
    try {
      dispatch({ type: CHANGE_PASSWORD_INIT })
      const result = await fetchChangePassword(
        contrasenia,
        newContrasenia,
        encryptKeys
      )
      // console.log(result)
      dispatch({ type: CHANGE_PASSWORD_SUCCESS, data: { ...result.data } })
    } catch (e) {
      console.log('changePassword: error: ', e)
      dispatch({ type: CHANGE_PASSWORD_ERROR })
      await Swal.fire('Error', e.response.data.detalles[0], 'warning')
    }
  }
}
