import React from 'react'
import { Redirect, Route } from 'react-router-dom'

import { ADMIN_ROLE, prefixes, STUDENT_ROLE, TEACHER_ROLE } from '../constants'
import Login from '../../../containers/anonymous/Login/Login'

export const AnonymousRoutes = [
  {
    component: Login,
    path: '/login'
  }
]

const generateRedirect = (user) => {
  console.log('AnonymousPrivateRoute: user: ', user)
  console.log('AnonymousPrivateRoute: roleId: ', user?.role.id)
  if (user) {
    switch (+user?.role.id) {
      case TEACHER_ROLE:
        return <Redirect to={`${prefixes.TEACHER}/home`}/>
      case STUDENT_ROLE:
        return <Redirect to={`${prefixes.STUDENT}/home`}/>
      case ADMIN_ROLE:
        return <Redirect to={`${prefixes.ADMIN}/home`}/>
      // default:
      //   return <Redirect to="/login"/>
    }
  }
}

const AnonymousPrivateRoute = ({ user, component: Component, ...rest }) => {
  console.log('AnonymousPrivateRoute: user: ', user)
  console.log('AnonymousPrivateRoute: rest: ', { ...rest })
  return <Route {...rest} render={props => (!user ? <Component {...props} /> : generateRedirect(user))}/>
}

export default AnonymousPrivateRoute
