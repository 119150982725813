import React from 'react'

import imgProfile from '../../../../../commons/assets/images/img-profile.jpg'
import styles from './Interaction.module.css'

const Interaction = () => {

  return (
    <section className={styles.interaction}>
      <h2 className="bigTitle center">Interacción</h2>
      <div className={`${styles.profileGrid} ${styles.threeCol}`}>
        <div className={styles.profileCol}>
          <div className={styles.imgCont}><img src={imgProfile}/></div>
          <p className={styles.name}>Ana Fuentes</p>
          <p className={styles.courseName}>Teología</p>
          <div className={styles.progress}>
            <div className={styles.bar}><span style={{ width: '50%' }}></span></div>
            <p className={styles.percentage}>Avance de curso<span>50%</span></p>
          </div>
        </div>
        <div className={styles.profileCol}>
          <div className={styles.imgCont}><img src={imgProfile}/></div>
          <p className={styles.name}>Ana Fuentes</p>
          <p className={styles.courseName}>Teología</p>
          <div className={styles.progress}>
            <div className={styles.bar}><span style={{ width: '76%' }}></span></div>
            <p className={styles.percentage}>Avance de curso<span>76%</span></p>
          </div>
        </div>
        <div className={styles.profileCol}>
          <div className={styles.imgCont}><img src={imgProfile}/></div>
          <p className={styles.name}>Ana Fuentes</p>
          <p className={styles.courseName}>Teología</p>
          <div className={styles.progress}>
            <div className={styles.bar}><span style={{ width: '15%' }}></span></div>
            <p className={styles.percentage}>Avance de curso<span>15%</span></p>
          </div>
        </div>
      </div>
    </section>

)
}

export default Interaction
