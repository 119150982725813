import React, { useEffect } from 'react'

import imgProfile from '../../../../../../../../commons/assets/images/img-profile.jpg'
import imgProfile02 from '../../../../../../../../commons/assets/images/img-profile-02.jpg'

import $ from 'jquery'

import './ClassInfo.css'

const ClassInfo = ({ roleId = 3, syllabus }) => {
  console.log(syllabus)
  useEffect(() => {
    $('.respTabs').responsiveTabs({
      startCollapsed: 'accordion'
    })
  }, [])

  return (
    <section className="respTabs">
      <ul>
        {+roleId === 3 && (<li><a href="#tab-1" className="r-tabs-anchor-custom">Temario</a></li>)}
        <li><a href="#tab-2" className="r-tabs-anchor-custom">Mis notas</a></li>
        <li><a href="#tab-3" className="r-tabs-anchor-custom">Chat con profesor</a></li>
        <li><a href="#tab-4" className="r-tabs-anchor-custom">Biblioteca</a></li>
        <li><a href="#tab-5" className="r-tabs-anchor-custom">Evaluación</a></li>
      </ul>

      {/*TEMARIO*/}
      <div id="tab-1" className="tabCont grad">
        <div className="courseSyllabus">
          {
            syllabus && syllabus.map(s => <div>{s.topic}</div>)
          }
        </div>
      </div>

      {/*MIS NOTAS*/}
      <div id="tab-2" className="tabCont grad">
        <div className="courseNotes">
          <form action="#">
            <fieldset>
              <textarea className="tiny"></textarea>
            </fieldset>
            <fieldset>
              <div className="groupBtn right">
                <button type="submit" className="btn noteBtn">Guardar</button>
              </div>
            </fieldset>
          </form>
        </div>
      </div>

      {/*CHAT*/}
      <div id="tab-3" className="tabCont grad">
        <div className="courseChat">
          <div className="courseChatText">
            <div className="person"><img src={imgProfile02} alt="Alfredo Botello"/></div>
            <div className="content">
              <div className="textDetails">
                <p className="name">Alfredo Botello</p>
                <p className="date">14:30<span>20/02/2020</span></p>
              </div>
              <div className="textInfo">
                <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium.</p>
              </div>
            </div>
          </div>
          <div className="courseChatText">
            <div className="person"><img src={imgProfile} alt="Ana Fuentes"/></div>
            <div className="content">
              <div className="textDetails">
                <p className="name">Ana Fuentes</p>
                <p className="date">14:30<span>20/02/2020</span></p>
              </div>
              <div className="textInfo">
                <p>Li Europan lingues es membres del sam familie. Lor separat existentie es un myth. Por scientie,
                  musica, sport etc, litot Europa usa li sam vocabular. Li lingues
                  differe solmen in li grammatica, li pro.</p>
              </div>
            </div>
          </div>
          <form action="" className="sendMessage">
            <fieldset>
              <input type="text"/>
            </fieldset>
            <fieldset>
              <div className="groupBtn right">
                <button type="submit" className="btn noteBtn">Enviar</button>
                <button type="attachment" className="btn noteBtn">
                  <div className="fa fa-paperclip"></div>
                </button>
              </div>
            </fieldset>
          </form>
        </div>
      </div>

      {/*BIBLIOTECA*/}
      <div id="tab-4" className="tabCont grad">
        <div className="courseLibrary">
          <div className="courseLibraryText">
            <div className="person"><img src={imgProfile02} alt="Ana Fuentes"/></div>
            <div className="content">
              <div className="textDetails">
                <p className="name">Alfredo Botello</p>
              </div>
              <div className="textInfo">
                <div className="resource">
                  <div className="rVideo">
                    <img src="//via.placeholder.com/85x57"/>
                    <div className="play"></div>
                  </div>
                  <p className="nameResource">Paz.mp4</p>
                </div>
                <div className="download"><a href="#" className="fa fa-download"></a></div>
              </div>
            </div>
          </div>
          <div className="courseLibraryText">
            <div className="person"><img src={imgProfile} alt="Ana Fuentes"/></div>
            <div className="content">
              <div className="textDetails">
                <p className="name">Ana Fuentes</p>
              </div>
              <div className="textInfo">
                <div className="resource">
                  <div className="rPDF"></div>
                  <p className="nameResource">Paz.mp4</p>
                </div>
                <div className="download"><a href="#" className="fa fa-download"></a></div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/*EVALUACIÓN*/}
      <div id="tab-5" className="tabCont grad">
        <div className="courseEval">
          <div className="courseEvalItem">
            <div className="text">
              <div className="textCont">
                <h4>Lorem ipsum dolor sit amet, consectetuer adipiscing elit.</h4>
                <p>Suspendisse quis convallis sem, ac pellentesque ante.</p>
              </div>
            </div>
            <div className="grades">
              <div className="gIco complete ok"></div>
              <div className="gIco notComplete"></div>
              <div className="gIco question"></div>
            </div>
          </div>
          <div className="courseEvalItem">
            <div className="text">
              <div className="textCont">
                <h4>Lorem ipsum dolor sit amet, consectetuer adipiscing elit.</h4>
                <p>Suspendisse quis convallis sem, ac pellentesque ante.</p>
              </div>
            </div>
            <div className="grades">
              <div className="gIco complete ok"></div>
              <div className="gIco notComplete"></div>
              <div className="gIco question"></div>
            </div>
          </div>
          <div className="courseEvalItem">
            <div className="text">
              <div className="textCont">
                <h4>Lorem ipsum dolor sit amet, consectetuer adipiscing elit.</h4>
                <p className="red">Suspendisse quis convallis sem, ac pellentesque ante.</p>
              </div>
            </div>
            <div className="grades">
              <div className="gIco complete"></div>
              <div className="gIco notComplete ok"></div>
              <div className="gIco question"></div>
            </div>
          </div>
          <div className="courseEvalItem">
            <div className="text">
              <div className="textCont">
                <h4>Lorem ipsum dolor sit amet, consectetuer adipiscing elit.</h4>
                <p className="red">Suspendisse quis convallis sem, ac pellentesque ante.</p>
              </div>
              <div className="questText">
                <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit.</p>
              </div>
            </div>
            <div className="grades">
              <div className="gIco complete"></div>
              <div className="gIco notComplete"></div>
              <div className="gIco question ok"></div>
            </div>
          </div>
          <div className="courseEvalItem">
            <div className="text">
              <div className="textCont">
                <h4>Lorem ipsum dolor sit amet, consectetuer adipiscing elit.</h4>
                <p className="red">Suspendisse quis convallis sem, ac pellentesque ante.</p>
              </div>
              <div className="questText">
                <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit.</p>
              </div>
            </div>
            <div className="grades">
              <div className="gIco complete"></div>
              <div className="gIco notComplete"></div>
              <div className="gIco question ok"></div>
            </div>
          </div>
          <div className="courseEvalItem">
            <div className="text">
              <div className="textCont">
                <h4>Lorem ipsum dolor sit amet, consectetuer adipiscing elit.</h4>
                <input type="text" placeholder="Escribe tu respuesta"/>
              </div>
            </div>
            <div className="grades">
              <div className="gIco complete"></div>
              <div className="gIco notComplete"></div>
              <div className="gIco question"></div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default ClassInfo
