import React from 'react'

import styles from './Students.module.css'
import MySlider from '../../../../commons/components/MySlider/MySlider'
import UserProfile from '../../../../commons/components/UserProfile/UserProfile'
import MyDevotional from '../../../../commons/components/MyDevotional/MyDevotional'
import Congrats from '../../../../commons/components/Congrats/Congrats'
import InviteFriend from '../../../../commons/components/InviteFriend/InviteFriend'
import Footer from '../../../../commons/components/Footer/Footer'
import MyStudents from './MyStudents/MyStudents'

const Students = () => {
  return (
    <div className={styles.mainContainer}>
      <MySlider/>
      <UserProfile roleId={3}/>
      <MyStudents/>
      <MyDevotional/>
      <Congrats/>
      <InviteFriend/>
      <Footer/>
    </div>
  )
}

export default Students
