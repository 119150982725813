import React, { useEffect } from 'react'

import bgCourse1 from '../../../../../commons/assets/images/bg-course-1.png'
import iconBadgePuntuality from '../../../../../commons/assets/images/icon-badge-puntuality.svg'
import iconBadgeUse from '../../../../../commons/assets/images/icon-badge-use.svg'
import iconBadgeQualification from '../../../../../commons/assets/images/icon-badge-qualification.svg'
import iconBadgeDevotionals from '../../../../../commons/assets/images/icon-badge-devotionals.svg'
import imgProfile from '../../../../../commons/assets/images/img-profile.jpg'
import bgCourse2 from '../../../../../commons/assets/images/bg-course-2.png'
import bgCourse3 from '../../../../../commons/assets/images/bg-course-3.png'
import imgCourseExample01 from '../../../../../commons/assets/images/img-course-example-1.png'
import imgCourseExample02 from '../../../../../commons/assets/images/img-course-example-2.png'

import $ from 'jquery'

import './MyCourses.css'

const MyCourses = () => {

  useEffect(() => {
    $('.gridMyCourses').gridtab({
      grid: 3,
      tabPadding: 0,
      borderWidth: 0,
      contentPadding: 0,
      responsive: [
        {
          breakpoint: 760,
          settings: {
            grid: 2
          }
        },
        {
          breakpoint: 520,
          settings: {
            grid: 1
          }
        }
      ]
    })
  }, [])

  return (
    <section>
      <div className="gridCourses">
        <dl className="gridMyCourses">

          <dt>
            <div className="courseCard">
              <div className="imgCont">
                <img src={bgCourse1} alt="Básico"/>
              </div>
              <div className="courseCardTitle">
                <p className="title">Básico</p>
                <p className="percentage">3 materías</p>
              </div>
            </div>
          </dt>
          <dd>
            <div className="mainContainer">
              <div className="homeCourse">
                <div className="bigTitle">Curso Básico</div>
                <div className="homeCourseDetailTitleSection">
                  <h2 className="sectionHeader">Objetivo</h2>
                </div>
                <div className="homeCourseDetailContent">
                  <p className="text">El liderazgo efectivo es simplemente aquél que logra resultados. Aquél que se ve y
                    no del cual se habla. Un líder efectivo, pone por encima de su deseo por obtener reconocimiento, la
                    necesidad de triunfar y alcanzar el éxito tanto para sí mismo, como para los demás.</p>
                  <p className="text">
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet consequatur
                    deleniti distinctio facilis laudantium nihil nobis provident quibusdam vel veniam. Assumenda autem
                    distinctio dolor, dolorem doloremque expedita ipsum iste quas.
                    <span>Aliquid atque, blanditiis corporis dolore doloribus et expedita, ipsum laborum laudantium maxime, nam odio odit perspiciatis quis veniam voluptatem voluptates? Aliquid commodi distinctio nobis possimus rerum sunt unde! Eveniet, iste!</span>
                    <span>Architecto cupiditate doloremque eum expedita facilis impedit libero magni, maiores minima nulla possimus praesentium qui recusandae sint tenetur, voluptatem voluptatibus. Cupiditate, ratione, soluta? Beatae illum laudantium numquam officia porro voluptatibus!</span>
                    <span>Ad architecto consectetur, corporis cum dicta dolorem eveniet ex incidunt labore modi nihil nulla numquam perferendis provident quae quam quod, ratione sapiente sit suscipit ullam veritatis, vitae! Blanditiis dolorem, eaque.</span>
                    <span>Aperiam asperiores, aut autem deserunt doloremque et, fuga, impedit incidunt ipsam nam nesciunt officia quibusdam repudiandae sint sit sunt voluptates? Aliquid aperiam blanditiis harum ipsa maiores modi ratione, sed vero!</span>
                  </p>
                </div>
                <div className="homeCourseDetailList">
                  <h2 className="sectionHeader">Materias</h2>
                  <ul className="text">
                    <li>Homelítica</li>
                    <li>Teología</li>
                    <li>Hermenéutica</li>
                    <li>Antiguo testamento</li>
                    <li>Profesía bíblica</li>
                    <li>Evangélios sinópticos</li>
                    <li>História de la iglesia</li>
                    <li>Espítiru santo</li>
                  </ul>
                </div>
                <div className="homeCourseDetailList">
                  <h2 className="sectionHeader sectionHeaderCol2">Inicio</h2>
                  <ul className="text textCol2">
                    <li>15/05/2020</li>
                  </ul>
                  <h2 className="sectionHeader sectionHeaderCol2">Duración</h2>
                  <ul className="text textCol2">
                    <li>6 meses</li>
                  </ul>
                  <h2 className="sectionHeader sectionHeaderCol2">Créditos para aprobación</h2>
                  <ul className="text textCol2">
                    <li>100</li>
                  </ul>
                </div>
                <div className="homeCourseDetailList">
                  <h2 className="sectionHeader">Mis Materias</h2>
                  <div className="adminConsole">
                    <div className="mainTeacherConsole">
                      <div className="consoleSection consoleUsers">
                        <div className="consoleUsersList">
                          <dl className="gridTabSubjects">
                            <dt>
                              <div className="userCard square">
                                <div className="imgProfile"><img src={imgCourseExample01} alt="Hermenéutica"/></div>
                                <p className="name">Hermenéutica</p>
                                <p className="role">9 Créditos</p>
                              </div>
                            </dt>
                            <dt>
                              <div className="userCard square">
                                <div className="imgProfile"><img src={imgCourseExample02} alt="Homelítica"/></div>
                                <p className="name">Homelítica</p>
                                <p className="role">10 Créditos</p>
                              </div>
                            </dt>
                          </dl>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </dd>

          <dt>
            <div className="courseCard">
              <div className="imgCont">
                <img src={bgCourse2} alt="Avanzado"/>
              </div>
              <div className="courseCardTitle">
                <p className="title">Avanzado</p>
                <p className="percentage">12 materías</p>
              </div>
            </div>
          </dt>
          <dd>
            <div className="mainContainer">
              <div className="homeCourse">
                <div className="bigTitle">Curso Básico</div>
                <div className="homeCourseDetailTitleSection">
                  <h2 className="sectionHeader">Objetivo</h2>
                </div>
                <div className="homeCourseDetailContent">
                  <p className="text">El liderazgo efectivo es simplemente aquél que logra resultados. Aquél que se ve y
                    no del cual se habla. Un líder efectivo, pone por encima de su deseo por obtener reconocimiento, la
                    necesidad de triunfar y alcanzar el éxito tanto para sí mismo, como para los demás.</p>
                  <p className="text">
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet consequatur
                    deleniti distinctio facilis laudantium nihil nobis provident quibusdam vel veniam. Assumenda autem
                    distinctio dolor, dolorem doloremque expedita ipsum iste quas.
                    <span>Aliquid atque, blanditiis corporis dolore doloribus et expedita, ipsum laborum laudantium maxime, nam odio odit perspiciatis quis veniam voluptatem voluptates? Aliquid commodi distinctio nobis possimus rerum sunt unde! Eveniet, iste!</span>
                    <span>Architecto cupiditate doloremque eum expedita facilis impedit libero magni, maiores minima nulla possimus praesentium qui recusandae sint tenetur, voluptatem voluptatibus. Cupiditate, ratione, soluta? Beatae illum laudantium numquam officia porro voluptatibus!</span>
                    <span>Ad architecto consectetur, corporis cum dicta dolorem eveniet ex incidunt labore modi nihil nulla numquam perferendis provident quae quam quod, ratione sapiente sit suscipit ullam veritatis, vitae! Blanditiis dolorem, eaque.</span>
                    <span>Aperiam asperiores, aut autem deserunt doloremque et, fuga, impedit incidunt ipsam nam nesciunt officia quibusdam repudiandae sint sit sunt voluptates? Aliquid aperiam blanditiis harum ipsa maiores modi ratione, sed vero!</span>
                  </p>
                </div>
                <div className="homeCourseDetailList">
                  <h2 className="sectionHeader">Materias</h2>
                  <ul className="text">
                    <li>Homelítica</li>
                    <li>Teología</li>
                    <li>Hermenéutica</li>
                    <li>Antiguo testamento</li>
                    <li>Profesía bíblica</li>
                    <li>Evangélios sinópticos</li>
                    <li>História de la iglesia</li>
                    <li>Espítiru santo</li>
                  </ul>
                </div>
                <div className="homeCourseDetailList">
                  <h2 className="sectionHeader sectionHeaderCol2">Inicio</h2>
                  <ul className="text textCol2">
                    <li>15/05/2020</li>
                  </ul>
                  <h2 className="sectionHeader sectionHeaderCol2">Duración</h2>
                  <ul className="text textCol2">
                    <li>6 meses</li>
                  </ul>
                  <h2 className="sectionHeader sectionHeaderCol2">Créditos para aprobación</h2>
                  <ul className="text textCol2">
                    <li>100</li>
                  </ul>
                </div>
                <div className="homeCourseDetailList">
                  <h2 className="sectionHeader">Mis Materias</h2>
                  <div className="adminConsole">
                    <div className="mainTeacherConsole">
                      <div className="consoleSection consoleUsers">
                        <div className="consoleUsersList">
                          <dl className="gridTabSubjects">
                            <dt>
                              <div className="userCard square">
                                <div className="imgProfile"><img src={imgCourseExample01} alt="Hermenéutica"/></div>
                                <p className="name">Hermenéutica</p>
                                <p className="role">9 Créditos</p>
                              </div>
                            </dt>
                            <dt>
                              <div className="userCard square">
                                <div className="imgProfile"><img src={imgCourseExample02} alt="Homelítica"/></div>
                                <p className="name">Homelítica</p>
                                <p className="role">10 Créditos</p>
                              </div>
                            </dt>
                          </dl>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </dd>

          <dt>
            <div className="courseCard">
              <div className="imgCont">
                <img src={bgCourse3} alt="Especialidad"/>
              </div>
              <div className="courseCardTitle">
                <p className="title">Especialidad</p>
                <p className="percentage">4 materías</p>
              </div>
            </div>
          </dt>
          <dd>
            <div className="mainContainer">
              <div className="homeCourse">
                <div className="bigTitle">Curso Básico</div>
                <div className="homeCourseDetailTitleSection">
                  <h2 className="sectionHeader">Objetivo</h2>
                </div>
                <div className="homeCourseDetailContent">
                  <p className="text">El liderazgo efectivo es simplemente aquél que logra resultados. Aquél que se ve y
                    no del cual se habla. Un líder efectivo, pone por encima de su deseo por obtener reconocimiento, la
                    necesidad de triunfar y alcanzar el éxito tanto para sí mismo, como para los demás.</p>
                  <p className="text">
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet consequatur
                    deleniti distinctio facilis laudantium nihil nobis provident quibusdam vel veniam. Assumenda autem
                    distinctio dolor, dolorem doloremque expedita ipsum iste quas.
                    <span>Aliquid atque, blanditiis corporis dolore doloribus et expedita, ipsum laborum laudantium maxime, nam odio odit perspiciatis quis veniam voluptatem voluptates? Aliquid commodi distinctio nobis possimus rerum sunt unde! Eveniet, iste!</span>
                    <span>Architecto cupiditate doloremque eum expedita facilis impedit libero magni, maiores minima nulla possimus praesentium qui recusandae sint tenetur, voluptatem voluptatibus. Cupiditate, ratione, soluta? Beatae illum laudantium numquam officia porro voluptatibus!</span>
                    <span>Ad architecto consectetur, corporis cum dicta dolorem eveniet ex incidunt labore modi nihil nulla numquam perferendis provident quae quam quod, ratione sapiente sit suscipit ullam veritatis, vitae! Blanditiis dolorem, eaque.</span>
                    <span>Aperiam asperiores, aut autem deserunt doloremque et, fuga, impedit incidunt ipsam nam nesciunt officia quibusdam repudiandae sint sit sunt voluptates? Aliquid aperiam blanditiis harum ipsa maiores modi ratione, sed vero!</span>
                  </p>
                </div>
                <div className="homeCourseDetailList">
                  <h2 className="sectionHeader">Materias</h2>
                  <ul className="text">
                    <li>Homelítica</li>
                    <li>Teología</li>
                    <li>Hermenéutica</li>
                    <li>Antiguo testamento</li>
                    <li>Profesía bíblica</li>
                    <li>Evangélios sinópticos</li>
                    <li>História de la iglesia</li>
                    <li>Espítiru santo</li>
                  </ul>
                </div>
                <div className="homeCourseDetailList">
                  <h2 className="sectionHeader sectionHeaderCol2">Inicio</h2>
                  <ul className="text textCol2">
                    <li>15/05/2020</li>
                  </ul>
                  <h2 className="sectionHeader sectionHeaderCol2">Duración</h2>
                  <ul className="text textCol2">
                    <li>6 meses</li>
                  </ul>
                  <h2 className="sectionHeader sectionHeaderCol2">Créditos para aprobación</h2>
                  <ul className="text textCol2">
                    <li>100</li>
                  </ul>
                </div>
                <div className="homeCourseDetailList">
                  <h2 className="sectionHeader">Mis Materias</h2>
                  <div className="adminConsole">
                    <div className="mainTeacherConsole">
                      <div className="consoleSection consoleUsers">
                        <div className="consoleUsersList">
                          <dl className="gridTabSubjects">
                            <dt>
                              <div className="userCard square">
                                <div className="imgProfile"><img src={imgCourseExample01} alt="Hermenéutica"/></div>
                                <p className="name">Hermenéutica</p>
                                <p className="role">9 Créditos</p>
                              </div>
                            </dt>
                            <dt>
                              <div className="userCard square">
                                <div className="imgProfile"><img src={imgCourseExample02} alt="Homelítica"/></div>
                                <p className="name">Homelítica</p>
                                <p className="role">10 Créditos</p>
                              </div>
                            </dt>
                          </dl>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </dd>

        </dl>

        <div className="groupBtn">
          <a className="btn btnGris" href="#">Cargar más</a>
        </div>
      </div>
    </section>


)
}

export default MyCourses
