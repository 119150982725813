import React from 'react'

import './EnrollmentContainer.css'
import EnrollmentForm from '../EnrollmentForm/EnrollmentForm'

const EnrollmentContainer = ({ keyOp, item }) => {

  return (
    <EnrollmentForm keyOp={keyOp}
                    item={item}/>
  )

}

export default EnrollmentContainer