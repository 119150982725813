import axiosClient from '../../network/apiClient'
import axiosClientAuth from '../../network/apiClientAuth'
import qs from 'qs'

import { USER_ENDPOINT } from '../../const'

export const fetchSession = async (username, password, keys) => {
  const url = USER_ENDPOINT.SESSION
  const method = 'post'
  const encryptKeys = keys
  const headers = {
    // 'x-id-acceso': keys.idAcceso,
    // 'content-type': 'application/x-www-form-urlencoded'
    'Content-Type': 'application/json'
  }

  /*const params = qs.stringify({
    grant_type: 'client_credentials',
    client_id: '444956495341535f504f5254414c',
    client_secret: '604c6337303544723631375358306846',
    idUsuario: username,
    contrasenia: password
  })*/



  const params = {
    username,
    password
  }
  // console.log('fetchSession: params: ', params)

  return await axiosClientAuth({
    method,
    url,
    data: params,
    headers,
    encryptKeys
  })
}

export const fetchLogin = async (username, password, keys) => {
  const url = USER_ENDPOINT.LOGIN
  const method = 'post'
  const encryptKeys = keys
  const headers = {
    'x-id-acceso': keys.idAcceso
  }

  const params = {
    idUsuario: username,
    contrasenia: password
  }
  // console.log(params)

  return await axiosClient({
    method,
    url,
    data: params,
    headers,
    encryptKeys
  })
}

export const fetchLogout = async () => {
  const url = USER_ENDPOINT.LOGOUT
  const method = 'post'
  const encryptKeys = null
  const headers = {}

  const params = {}
  // console.log(params)

  return await axiosClient({
    method,
    url,
    data: params,
    headers,
    encryptKeys
  })
}

export const fetchRefreshToken = async () => {
  const url = USER_ENDPOINT.REFRESH_TOKEN
  const method = 'post'
  const encryptKeys = null
  const headers = {}

  const params = {}
  // console.log(params)

  return await axiosClient({
    method,
    url,
    data: params,
    headers,
    encryptKeys
  })
}

export const fetchForgotPassword = async (userId, keys) => {
  const url = USER_ENDPOINT.FORGOT_PASSWORD
  const method = 'post'
  const encryptKeys = keys
  const headers = {
    'x-id-acceso': keys.idAcceso
  }

  const params = {idUsuario: userId}
  // console.log(params)

  return await axiosClient({
    method,
    url,
    data: params,
    headers,
    encryptKeys
  })
}

export const fetchNewPassword = async (values, keys) => {
  // let tokenBearer = values.token
  const url = USER_ENDPOINT.RESET_PASSWORD
  const method = 'put'
  const encryptKeys = keys
  const {password, token} = values
  const headers = {
    Authorization: `Bearer ${values.token}`,
    'x-id-acceso': keys.idAcceso
  }

  const params = {contrasenia: password}

  return await axiosClient({
    method,
    url,
    data: params,
    headers,
    encryptKeys
  })
}

export const fetchUserProfile = async (userId) => {
  // export const fetchUserProfile = async() => {

  const url = `${USER_ENDPOINT.PROFILE}/${userId}`
  const method = 'get'
  const encryptKeys = null
  const headers = {}

  const params = {}
  // console.log(params)

  return await axiosClient({
    method,
    url,
    data: params,
    headers,
    encryptKeys
  })
}

export const fetchModules = async () => {
  const url = USER_ENDPOINT.MODULES
  const method = 'get'
  const encryptKeys = null
  const headers = {}

  const params = {}
  // console.log(params)

  return await axiosClient({
    method,
    url,
    data: params,
    headers,
    encryptKeys
  })
}

export const saveUser = async (values) => {
  const url = USER_ENDPOINT.NEW_USER
  const method = 'post'
  const encryptKeys = null
  const headers = {}

  const params = {
    ...values
  }
  // console.log(params)

  return await axiosClient({
    method,
    url,
    data: params,
    headers,
    encryptKeys
  })
}

export const fetchUsers = async () => {
  const token = sessionStorage.getItem('_k')
  const url = USER_ENDPOINT.GET_USERS
  const method = 'get'
  const encryptKeys = null
  const headers = {
    Authorization: `Bearer ${token}`
  }

  const params = {}
  // console.log(params)

  return await axiosClient({
    method,
    url,
    data: params,
    headers,
    encryptKeys
  })
}

export const fetchUser = async (userId) => {
  const url = `${USER_ENDPOINT.GET_USERS}/${userId}`
  const method = 'get'
  const encryptKeys = null
  const headers = {}

  const params = {}
  // console.log(params)

  return await axiosClient({
    method,
    url,
    data: params,
    headers,
    encryptKeys
  })
}

export const updateUser = async (user) => {
  const url = `${USER_ENDPOINT.GET_USERS}/actualizar`
  const method = 'put'
  const encryptKeys = null
  const headers = {}

  const params = {
    ...user
  }
  // console.log(params)

  return await axiosClient({
    method,
    url,
    data: params,
    headers,
    encryptKeys
  })
}

export const deleteUser = async (userId) => {
  const url = '/usuarios/actualizar-estatus'
  const method = 'put'
  const encryptKeys = null
  const headers = {}

  const params = {
    id: userId
  }
  // console.log(params)

  return await axiosClient({
    method,
    url,
    data: params,
    headers,
    encryptKeys
  })
}

export const fetchChangePassword = async (
  contrasenia,
  contraseniaNueva,
  keys
) => {
  const token = sessionStorage.getItem('token')
  const url = USER_ENDPOINT.CHANGE_PASSWORD
  const method = 'put'
  const encryptKeys = keys
  const headers = {
    Authorization: `Bearer ${token}`,
    'x-id-acceso': keys.idAcceso
  }

  const params = {
    contrasenia: contrasenia,
    contraseniaNueva: contraseniaNueva
  }
  // console.log(params)

  return await axiosClient({
    method,
    url,
    data: params,
    headers,
    encryptKeys
  })
}
