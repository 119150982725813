import React from 'react'

import styles from './Congrats.module.css'

const Congrats = () => {
  return (
    <section className={`${styles.calMessage} ${styles.gray}`}>
      <p>¡Felicidades por tu avance, vas muy bien!</p>
    </section>
  )
}

export default Congrats