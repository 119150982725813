import React from 'react'

import iconBadgePuntuality from '../../../../../../../commons/assets/images/icon-badge-puntuality.svg'
import iconBadgeUse from '../../../../../../../commons/assets/images/icon-badge-use.svg'
import iconBadgeQualification from '../../../../../../../commons/assets/images/icon-badge-qualification.svg'
import iconBadgeDevotionals from '../../../../../../../commons/assets/images/icon-badge-devotionals.svg'

import './StudentCourse.css'

const StudentCourse = () => {

  return (
    <>
      <div className="badgesCardsTitle">
        <h2 className="sectionHeader withElements"><span>Cursando</span></h2>
        <div className="title">Curso básico</div>
        <div className="badges">
          <span className="puntuality"><img src={iconBadgePuntuality} alt="Puntualidad"/></span>
          <span className="use"><img src={iconBadgeUse} alt="Aprovechamiento"/></span><span
          className="qualification"><img src={iconBadgeQualification} alt="Calificación"/></span>
          <span className="devotionals"><img src={iconBadgeDevotionals} alt="Devocionales"/></span>
        </div>
      </div>
      <div className="subjectCardsTitle">
        <p className="subject">Materias</p>
        <p className="title">Fecha inicio</p>
        <p className="title">Fecha fin</p>
        <p className="title">Avance</p>
      </div>
      <div className="subjectCard">
        <span className="title">Homelítica</span>
        <span className="subject">02/06/2020</span>
        <span className="subject">02/06/2020</span>
        <span className="progress__bar">
                    <div className="bar"><span style={{ width: '100%' }}></span></div>
                    <p className="percentage">100%</p>
                  </span>
      </div>
      <div className="subjectCard">
        <span className="title">Teología</span>
        <span className="subject">02/06/2020</span>
        <span className="subject">02/06/2020</span>
        <span className="progress__bar">
                    <div className="bar"><span style={{ width: '50%' }}></span></div>
                    <p className="percentage">50%</p>
                  </span>
      </div>
      <div className="subjectCard">
        <span className="title">Homelítica</span>
        <span className="subject">02/06/2020</span>
        <span className="subject">02/06/2020</span>
        <span className="progress__bar">
                    <div className="bar"><span style={{ width: '100%' }}></span></div>
                    <p className="percentage">100%</p>
                  </span>
      </div>
      <div className="academySituationCardsTitle">
        <h2 className="sectionHeader withElements"><span>Inscripción</span></h2>
        <form className="studentInfo subjetcsInfo" action="">
          <div className="academySituationCard">
            <div className="inscription"><span className="title">Curso avanzado</span></div>
            <div className="button">
              <button className="btn purple" type="submit">Inscribir</button>
            </div>
            <div className="inscription"><span className="title">Curso especialidad</span></div>
            <div className="button">
              <button className="btn purple" type="submit">Inscribir</button>
            </div>
          </div>
        </form>
      </div>
      <div className="academySituationCardsTitle">
        <h2 className="sectionHeader withElements"><span>Situación académica</span></h2>
        <form className="studentInfo subjetcsInfo" action="">
          <div className="academySituationCard">
            <div className="academy">
              <span className="title">Suspender matrícula</span>
              <div className="switch">switch</div>
            </div>
            <div className="academy">
              <span className="title">Dar de baja definitivamente</span>
              <div className="switch">switch</div>
            </div>
            <div className="comments">
              <span className="title">Comentarios</span>
              <div className="switch">
                <textarea className="white" placeholder="Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo"></textarea>
              </div>
            </div>
            <div className="fieldset right">
              <button className="btn purple" type="submit">Guardar</button>
            </div>
          </div>
        </form>
      </div>
    </>
  )
}

export default StudentCourse