import React, { useEffect, useState } from 'react'
import createActivityDetector from 'activity-detector'

export const useIdle = (options) => {
  const [idle, setIdle] = useState(false)

  useEffect(() => {
    const activityDetector = createActivityDetector(options)
    // console.log('activityDetector: 🍭🍭🍭🍭🍭🍭🍭🍭 ', activityDetector)
    activityDetector.on('idle', () => {
      setIdle(true)
      // console.log('activityDetector: 🟡🟡🟡🟡🟡🟡🟡 The user is not interacting with the page')
    })
    activityDetector.on('active', () => {
      setIdle(false)
      // console.log('activityDetector: 🟢🟢🟢🟢🟢🟢🟢 The user is using the page')
    })

    return () => activityDetector.stop()
  }, [])

  return {
    userIsActive: !idle
  }
}
