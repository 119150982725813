import React, {useEffect} from 'react'

import bgCourse1 from '../../../../../commons/assets/images/bg-course-1.png'
import iconBadgePuntuality from '../../../../../commons/assets/images/icon-badge-puntuality.svg'
import iconBadgeUse from '../../../../../commons/assets/images/icon-badge-use.svg'
import iconBadgeQualification from '../../../../../commons/assets/images/icon-badge-qualification.svg'
import iconBadgeDevotionals from '../../../../../commons/assets/images/icon-badge-devotionals.svg'
import imgProfile from '../../../../../commons/assets/images/img-profile.jpg'
import bgCourse2 from '../../../../../commons/assets/images/bg-course-2.png'
import bgCourse3 from '../../../../../commons/assets/images/bg-course-3.png'

import $ from 'jquery'

import './MyCourses.css'

const MyCourses = () => {

  useEffect(() => {
    $('.gridMyCourses').gridtab({
      grid: 3,
      tabPadding: 0,
      borderWidth: 0,
      contentPadding: 0,
      responsive: [
        {
          breakpoint: 760,
          settings: {
            grid: 2,
          },
        },
        {
          breakpoint: 520,
          settings: {
            grid: 1,
          },
        },
      ],
    });
  }, [])

  return (
    <section>
      <div className="gridCourses">
        <dl className="gridMyCourses">

          <dt>
            <div className="courseCard">
              <div className="imgCont">
                <img src={bgCourse1} alt="Básico"/>
              </div>
              <div className="courseCardTitle">
                <p className="title">Básico</p>
              </div>
              <div className="progress__bar">
                <div className="bar"><span style={{width: "50%"}}></span></div>
                <p className="percentage">3 de 12 materías</p>
              </div>
              <div className="badge">
                <span className="puntuality">
                  <img src={iconBadgePuntuality} alt="Puntualidad"/>
                </span>
                <span className="use">
                  <img src={iconBadgeUse} alt="Aprovechamiento"/>
                </span>
                <span className="qualification">
                  <img src={iconBadgeQualification} alt="Calificación"/>
                </span>
                <span className="devotionals">
                  <img src={iconBadgeDevotionals} alt="Devocionales"/>
                </span>
              </div>
            </div>
          </dt>
          <dd>
            <div className="gridMyCourses">
              <div className="coursesTitle">
                <p>Curso Básico</p>
                <div className="courseCard">
                  <span>
                    <p className="status2 progress__bar">
                      Cursando actualmente
                    </p>
                  </span>
                </div>
              </div>
              <div className="coursesCardsObjectiveTitle">
                <span>Objetivo del curso</span>
                <div className="courseCardObjective">
                  <span>
                    Un ejemplo sirve para explicar o ilustrar una
                    afirmación general, o para proporcionar un caso
                    particular que hace de modelo para el caso general. El
                    ejemplo es escogido libremente, pero busca aclarar la
                    comprensión de un fenómeno o proceso
                  </span>
                  <br/><br/>
                  <span>
                    El texto está disponible bajo la Licencia Creative
                    Commons Atribución Compartir Igual 3.0; pueden aplicarse
                    cláusulas adicionales. Al usar este sitio, usted acepta
                    nuestros términos de uso y nuestra política de
                    privacidad. Al usar este sitio, usted acepta nuestros
                    términos de uso y nuestra política de privacidad
                  </span>
                </div>
              </div>
              <div className="coursesCardsTitle"><span>Materias</span></div>

              <div className="courseCardRow">
                <span className="teacher">
                  <img src={imgProfile} alt="Profesor"/></span>
                <span className="subject">Homelítica</span>
                <span className="progress__bar">
                  <div className="bar"><span style={{width: "100%"}}></span></div>
                  <p className="percentage">100%</p>
                </span>
                <span><p className="status2 approved">Aprobado</p></span>
                <span><p className="status2 date">Marzo 1, 2020</p></span>
              </div>

              <div className="courseCardRow">
                <span className="teacher">
                  <img src={imgProfile} alt="Profesor"/>
                </span>
                <span className="subject">Teología</span>
                <span className="progress__bar">
                  <div className="bar"><span style={{width: "75%"}}></span></div>
                  <p className="percentage">75%</p>
                </span>
                <span><p className="status2 progress__bar">Aprobado</p></span>
                <span><p className="status2 date">Septiembre 1, 2020</p></span>
              </div>

              <div className="courseCardRow">
                <span className="teacher">
                  <img src={imgProfile} alt="Profesor"/>
                </span>
                <span className="subject">Hermenéutica</span>
                <span className="progress__bar">
                  <div className="bar"><span style={{width: "15%"}}></span></div>
                  <p className="percentage">15%</p>
                </span>
                <span><p className="status2 standby">Pendiente</p></span>
                <span><p className="status2 date">Septiembre 1, 2020</p></span>
              </div>

              <div className="courseCardRow">
                <span className="teacher">
                  <img src={imgProfile} alt="Profesor"/>
                </span>
                <span className="subject">Evangélios sinópticos</span>
                <span className="progress__bar">
                  <div className="bar"><span style={{width: "85%"}}></span></div>
                  <p className="percentage">85%</p>
                </span>
                <span><p className="status2 unsubscribed">No suscrito</p></span>
                <span><p className="status2 date">Septiembre 1, 2020</p></span>
              </div>

              <div className="coursesCardsTitle">
                <span>Tus insignias</span>
              </div>
              <div className="bagdeBox">
                <div className="bagdeBox__img__wrap">
                  <img src={iconBadgePuntuality} alt="Puntualidad"/>
                </div>
                <div className="bagdeBox__title">Puntualidad</div>
                <p>
                  Has iniciado y culminado tus cursos en el tiempo sugerido.
                  Sigue adenlante con &eacute;ste buen h&aacute;bito.
                </p>
              </div>
              <div className="bagdeBox">
                <div className="bagdeBox__img__wrap">
                  <img src={iconBadgeUse} alt="Aprovechamiento"/>
                </div>
                <div className="bagdeBox__title">Aprovechamiento</div>
                <p>Has cumplido con la tareas que el profesor dicta.</p>
              </div>
              <div className="bagdeBox">
                <div className="bagdeBox__img__wrap">
                  <img src={iconBadgeQualification} alt="Calificación"/>
                </div>
                <div className="bagdeBox__title">Calificación</div>
                <p>Has alcanzando un buen puntaje en tus evaluaciones.</p>
              </div>
              <div className="bagdeBox">
                <div className="bagdeBox__img__wrap">
                  <img src={iconBadgeDevotionals} alt="Devocionales"/>
                </div>
                <div className="bagdeBox__title">Devocionales</div>
                <p>
                  Has cumplido con tus lecturas programadas dentro del
                  tiempo recomendado.
                </p>
              </div>
            </div>
          </dd>

          <dt>
            <div className="courseCard">
              <div className="imgCont">
                <img src={bgCourse2} alt="Avanzado"/>
              </div>
              <div className="courseCardTitle">
                <p className="title">Avanzado</p>
              </div>
              <div className="progress__bar">
                <div className="bar"><span style={{width: "0%"}}></span></div>
                <p className="percentage">Termina el curso</p>
              </div>
              <div className="badge">
                <span className="puntuality">
                  <img src={iconBadgePuntuality} alt="Puntualidad"/>
                </span>
                <span className="use">
                  <img src={iconBadgeUse} alt="Aprovechamiento"/>
                </span>
                <span className="qualification">
                  <img src={iconBadgeQualification} alt="Calificación"/>
                </span>
                <span className="devotionals">
                  <img src={iconBadgeDevotionals} alt="Devocionales"/>
                </span>
              </div>
            </div>
          </dt>
          <dd>
            <div className="gridMyCourses">
              <div className="coursesTitle">
                <p>Curso Avanzado</p>
                <div className="courseCard">
                      <span>
                        <p className="status2 progress__bar">
                          Cursando actualmente
                        </p></span
                      >
                </div>
              </div>
              <div className="coursesCardsObjectiveTitle">
                <span>Objetivo del curso</span>
                <div className="courseCardObjective">
                      <span
                      >Un ejemplo sirve para explicar o ilustrar una
                        afirmación general, o para proporcionar un caso
                        particular que hace de modelo para el caso general. El
                        ejemplo es escogido libremente, pero busca aclarar la
                        comprensión de un fenómeno o proceso</span
                      ><br/><br/><span
                >El texto está disponible bajo la Licencia Creative
                        Commons Atribución Compartir Igual 3.0; pueden aplicarse
                        cláusulas adicionales. Al usar este sitio, usted acepta
                        nuestros términos de uso y nuestra política de
                        privacidad. Al usar este sitio, usted acepta nuestros
                        términos de uso y nuestra política de privacidad
                      </span>
                </div>
              </div>
              <div className="coursesCardsTitle"><span>Materias</span></div>
              <div className="courseCardRow">
                    <span className="teacher"
                    ><img src={imgProfile} alt="Profesor"/></span
                    ><span className="subject">Homelítica</span
              ><span className="progress__bar">
                      <div className="bar"><span style={{width: "100%"}}></span></div>
                      <p className="percentage">100%</p></span
              ><span> <p className="status2 approved">Aprobado</p></span
              ><span> <p className="status2 date">Marzo 1, 2020</p></span>
              </div>
              <div className="courseCardRow">
                    <span className="teacher"
                    ><img src={imgProfile} alt="Profesor"/></span
                    ><span className="subject">Teología</span
              ><span className="progress__bar">
                      <div className="bar"><span style={{width: "75%"}}></span></div>
                      <p className="percentage">75%</p></span
              ><span> <p className="status2 approved">Aprobado</p></span
              ><span>
                      <p className="status2 date">Septiembre 1, 2020</p></span
              >
              </div>
              <div className="courseCardRow">
                    <span className="teacher"
                    ><img src={imgProfile} alt="Profesor"/></span
                    ><span className="subject">Hermenéutica</span
              ><span className="progress__bar">
                      <div className="bar"><span style={{width: "50%"}}></span></div>
                      <p className="percentage">50%</p></span
              ><span> <p className="status2 approved">Aprobado</p></span
              ><span> <p className="status2 date">Octubre 1, 2020</p></span>
              </div>
              <div className="courseCardRow">
                    <span className="teacher"
                    ><img src={imgProfile} alt="Profesor"/></span
                    ><span className="subject">Antiguo testamento</span
              ><span className="progress__bar">
                      <div className="bar"><span style={{width: "25%"}}></span></div>
                      <p className="percentage">25%</p></span
              ><span> <p className="status2 progress__bar">En curso</p></span
              ><span> <p className="status2 date">Mayo 1, 2020</p></span>
              </div>
              <div className="courseCardRow">
                    <span className="teacher"
                    ><img src={imgProfile} alt="Profesor"/></span
                    ><span className="subject">Profesía bíblica</span
              ><span className="progress__bar">
                      <div className="bar"><span style={{width: "15%"}}></span></div>
                      <p className="percentage">15%</p></span
              ><span> <p className="status2 standby">Pendiente</p></span
              ><span>
                      <p className="status2 date">Diciembre 25, 2020</p></span
              >
              </div>
              <div className="courseCardRow">
                    <span className="teacher"
                    ><img src={imgProfile} alt="Profesor"/></span
                    ><span className="subject">Evangélios sinópticos</span
              ><span className="progress__bar">
                      <div className="bar"></div>
                      <p className="percentage">0%</p></span
              ><span>
                      <p className="status2 unsubscribed">No suscrito</p></span
              ><span> <p className="status2 date">Marzo 23, 1975</p></span>
              </div>
              <div className="courseCardRow">
                    <span className="teacher"
                    ><img src={imgProfile} alt="Profesor"/></span
                    ><span className="subject">História de la iglesia</span
              ><span className="progress__bar">
                      <div className="bar"></div>
                      <p className="percentage">0%</p></span
              ><span> <p className="status2 standby">Pendiente</p></span
              ><span> <p className="status2 date">Julio 15, 1980</p></span>
              </div>
              <div className="courseCardRow">
                    <span className="teacher"
                    ><img src={imgProfile} alt="Profesor"/></span
                    ><span className="subject">Espíritu santo</span
              ><span className="progress__bar">
                      <div className="bar"></div>
                      <p className="percentage">0%</p></span
              ><span> <p className="status2 standby">Pendiente</p></span
              ><span> <p className="status2 date">Febrero 08, 1980</p></span>
              </div>
              <div className="courseCardRow">
                    <span className="teacher"
                    ><img src={imgProfile} alt="Profesor"/></span
                    ><span className="subject">Cristología</span
              ><span className="progress__bar">
                      <div className="bar"></div>
                      <p className="percentage">0%</p></span
              ><span> <p className="status2 standby">Pendiente</p></span
              ><span> <p className="status2 date">Abril 12, 2019</p></span>
              </div>
              <div className="coursesCardsTitle">
                <span>Tus insignias</span>
              </div>
              <div className="bagdeBox">
                <div className="bagdeBox__img__wrap">
                  <img
                    src={iconBadgePuntuality}
                    alt="Puntualidad"
                  />
                </div>
                <div className="bagdeBox__title">Puntualidad</div>
                <p>
                  Has iniciado y culminado tus cursos en el tiempo sugerido.
                  Sigue adenlante con &eacute;ste buen h&aacute;bito.
                </p>
              </div>
              <div className="bagdeBox">
                <div className="bagdeBox__img__wrap">
                  <img
                    src={iconBadgeUse}
                    alt="Aprovechamiento"
                  />
                </div>
                <div className="bagdeBox__title">Aprovechamiento</div>
                <p>Has cumplido con la tareas que el profesor dicta.</p>
              </div>
              <div className="bagdeBox">
                <div className="bagdeBox__img__wrap">
                  <img
                    src={iconBadgeQualification}
                    alt="Calificación"
                  />
                </div>
                <div className="bagdeBox__title">Calificación</div>
                <p>Has alcanzando un buen puntaje en tus evaluaciones.</p>
              </div>
              <div className="bagdeBox">
                <div className="bagdeBox__img__wrap">
                  <img
                    src={iconBadgeDevotionals}
                    alt="Devocionales"
                  />
                </div>
                <div className="bagdeBox__title">Devocionales</div>
                <p>
                  Has cumplido con tus lecturas programadas dentro del
                  tiempo recomendado.
                </p>
              </div>
            </div>
          </dd>

          <dt>
            <div className="courseCard">
              <div className="imgCont">
                <img src={bgCourse3} alt="Especialidad"/>
              </div>
              <div className="courseCardTitle">
                <p className="title">Especialidad</p>
              </div>
              <div className="progress__bar">
                <div className="bar">
                  <span className="completed" style={{width: "100%"}}></span>
                </div>
                <p className="percentage">Curso terminado</p>
              </div>
              <div className="badge">
                    <span className="puntuality"
                    ><img
                      src={iconBadgePuntuality}
                      alt="Puntualidad"/></span
                    ><span className="use"
              ><img
                src={iconBadgeUse}
                alt="Aprovechamiento"/></span
              ><span className="qualification"
              ><img
                src={iconBadgeQualification}
                alt="Calificación"/></span
              ><span className="devotionals"
              ><img
                src={iconBadgeDevotionals}
                alt="Devocionales"
              /></span>
              </div>
            </div>
          </dt>
          <dd>
            <div className="gridMyCourses">
              <div className="coursesTitle">
                <p>Curso Especialidad</p>
                <div className="courseCard">
                      <span>
                        <p className="status2 progress__bar">
                          Cursando actualmente
                        </p></span
                      >
                </div>
              </div>
              <div className="coursesCardsObjectiveTitle">
                <span>Objetivo del curso</span>
                <div className="courseCardObjective">
                      <span
                      >Un ejemplo sirve para explicar o ilustrar una
                        afirmación general, o para proporcionar un caso
                        particular que hace de modelo para el caso general. El
                        ejemplo es escogido libremente, pero busca aclarar la
                        comprensión de un fenómeno o proceso</span
                      ><br/><br/><span
                >El texto está disponible bajo la Licencia Creative
                        Commons Atribución Compartir Igual 3.0; pueden aplicarse
                        cláusulas adicionales. Al usar este sitio, usted acepta
                        nuestros términos de uso y nuestra política de
                        privacidad. Al usar este sitio, usted acepta nuestros
                        términos de uso y nuestra política de privacidad
                      </span>
                </div>
              </div>
              <div className="coursesCardsTitle"><span>Materias</span></div>
              <div className="courseCardRow">
                    <span className="teacher"
                    ><img src={imgProfile} alt="Profesor"/></span
                    ><span className="subject">Homelítica</span
              ><span className="progress__bar">
                      <div className="bar"><span style={{width: "100%"}}></span></div>
                      <p className="percentage">100%</p></span
              ><span> <p className="status2 approved">Aprobado</p></span
              ><span> <p className="status2 date">Marzo 1, 2020</p></span>
              </div>
              <div className="courseCardRow">
                    <span className="teacher"
                    ><img src={imgProfile} alt="Profesor"/></span
                    ><span className="subject">Teología</span
              ><span className="progress__bar">
                      <div className="bar"><span style={{width: "75%"}}></span></div>
                      <p className="percentage">75%</p></span
              ><span> <p className="status2 approved">Aprobado</p></span
              ><span>
                      <p className="status2 date">Septiembre 1, 2020</p></span
              >
              </div>
              <div className="courseCardRow">
                    <span className="teacher"
                    ><img src={imgProfile} alt="Profesor"/></span
                    ><span className="subject">Hermenéutica</span
              ><span className="progress__bar">
                      <div className="bar"><span style={{width: "50%"}}></span></div>
                      <p className="percentage">50%</p></span
              ><span> <p className="status2 approved">Aprobado</p></span
              ><span> <p className="status2 date">Octubre 1, 2020</p></span>
              </div>
              <div className="courseCardRow">
                    <span className="teacher"
                    ><img src={imgProfile} alt="Profesor"/></span
                    ><span className="subject">Antiguo testamento</span
              ><span className="progress__bar">
                      <div className="bar"><span style={{width: "25%"}}></span></div>
                      <p className="percentage">25%</p></span
              ><span> <p className="status2 progress__bar">En curso</p></span
              ><span> <p className="status2 date">Mayo 1, 2020</p></span>
              </div>
              <div className="courseCardRow">
                    <span className="teacher"
                    ><img src={imgProfile} alt="Profesor"/></span
                    ><span className="subject">Profesía bíblica</span
              ><span className="progress__bar">
                      <div className="bar"><span style={{width: "15%"}}></span></div>
                      <p className="percentage">15%</p></span
              ><span> <p className="status2 standby">Pendiente</p></span
              ><span>
                      <p className="status2 date">Diciembre 25, 2020</p></span
              >
              </div>
              <div className="courseCardRow">
                    <span className="teacher"
                    ><img src={imgProfile} alt="Profesor"/></span
                    ><span className="subject">Evangélios sinópticos</span
              ><span className="progress__bar">
                      <div className="bar"></div>
                      <p className="percentage">0%</p></span
              ><span>
                      <p className="status2 unsubscribed">No suscrito</p></span
              ><span> <p className="status2 date">Marzo 23, 1975</p></span>
              </div>
              <div className="courseCardRow">
                    <span className="teacher"
                    ><img src={imgProfile} alt="Profesor"/></span
                    ><span className="subject">História de la iglesia</span
              ><span className="progress__bar">
                      <div className="bar"></div>
                      <p className="percentage">0%</p></span
              ><span> <p className="status2 standby">Pendiente</p></span
              ><span> <p className="status2 date">Julio 15, 1980</p></span>
              </div>
              <div className="courseCardRow">
                    <span className="teacher"
                    ><img src={imgProfile} alt="Profesor"/></span
                    ><span className="subject">Espíritu santo</span
              ><span className="progress__bar">
                      <div className="bar"></div>
                      <p className="percentage">0%</p></span
              ><span> <p className="status2 standby">Pendiente</p></span
              ><span> <p className="status2 date">Febrero 08, 1980</p></span>
              </div>
              <div className="courseCardRow">
                    <span className="teacher"
                    ><img src={imgProfile} alt="Profesor"/></span
                    ><span className="subject">Cristología</span
              ><span className="progress__bar">
                      <div className="bar"></div>
                      <p className="percentage">0%</p></span
              ><span> <p className="status2 standby">Pendiente</p></span
              ><span> <p className="status2 date">Abril 12, 2019</p></span>
              </div>
              <div className="coursesCardsTitle">
                <span>Tus insignias</span>
              </div>
              <div className="bagdeBox">
                <div className="bagdeBox__img__wrap">
                  <img
                    src={iconBadgePuntuality}
                    alt="Puntualidad"
                  />
                </div>
                <div className="bagdeBox__title">Puntualidad</div>
                <p>
                  Has iniciado y culminado tus cursos en el tiempo sugerido.
                  Sigue adenlante con &eacute;ste buen h&aacute;bito.
                </p>
              </div>
              <div className="bagdeBox">
                <div className="bagdeBox__img__wrap">
                  <img
                    src={iconBadgeUse}
                    alt="Aprovechamiento"
                  />
                </div>
                <div className="bagdeBox__title">Aprovechamiento</div>
                <p>Has cumplido con la tareas que el profesor dicta.</p>
              </div>
              <div className="bagdeBox">
                <div className="bagdeBox__img__wrap">
                  <img
                    src={iconBadgeQualification}
                    alt="Calificación"
                  />
                </div>
                <div className="bagdeBox__title">Calificación</div>
                <p>Has alcanzando un buen puntaje en tus evaluaciones.</p>
              </div>
              <div className="bagdeBox">
                <div className="bagdeBox__img__wrap">
                  <img
                    src={iconBadgeDevotionals}
                    alt="Devocionales"
                  />
                </div>
                <div className="bagdeBox__title">Devocionales</div>
                <p>
                  Has cumplido con tus lecturas programadas dentro del
                  tiempo recomendado.
                </p>
              </div>
            </div>
          </dd>
        </dl>

        <div className="groupBtn">
          <a className="btn btnGris" href="#">Cargar más</a>
        </div>
      </div>
    </section>


  )
}

export default MyCourses
