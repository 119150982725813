import React from 'react'
import { useHistory } from 'react-router-dom'

import imgProfile from '../../../../../commons/assets/images/img-profile.jpg'
import imgProfile02 from '../../../../../commons/assets/images/img-profile-02.jpg'
import imgProfile03 from '../../../../../commons/assets/images/img-profile-03.jpg'

import './MyStudents.css'
import usePrefix from '../../../../../commons/hooks/usePrefix'
// import styles from '../../../student/Home/Subjects/Subjects.module.css'

const MyStudents = () => {
  const history = useHistory()
  const prefix = usePrefix()

  const handleRedirect = () => {
    history.push(`${prefix}/my-students/interaction`)
  }

  return (
    <section className="interaction">

      {/*FILTER*/}
      <div className="consoleClass">
        <div className="sectionSelect">
          <div className="gridClass">
            <h2 className="bigTitle center bold"><span>Mis alumnos</span></h2>
            <div className="fieldset">
              <span className="select darkSquare">
                <select className="spacing">
                  {/*<option value="Teología" selected>Teología</option>*/}
                  <option value="Teología">Teología</option>
                  <option value="Hermenéutica">Hermenéutica</option>
                  <option value="Homelítica">Homelítica</option>
                  <option value="La bíblia">La bíblia</option>
                </select>
              </span>
              <span className="select orangeSquare">
                <select className="orange">
                  <option value="2016">Clase 2020</option>
                  <option value="2017">Clase 2021</option>
                  {/*<option value="2018" selected>Clase 2022</option>*/}
                  <option value="2018">Clase 2022</option>
                  <option value="2019">Clase 2023</option>
                  <option value="2020">Clase 2024</option>
                </select>
            </span>
            </div>
          </div>
        </div>
      </div>

      <div className="profileGrid threeCol">
        <div className="profileCol">
          <div className="imgCont"><img src={imgProfile} alt=""/></div>
          <p className="name">Ana Fuentes</p>
          <div className="progress__bar">
            <div className="bar"><span className="nocompleted" style={{ width: '75%' }}></span></div>
            <p className="percentage">Avance de curso<span>75%</span></p>
          </div>
          <div className="fieldset center">
            <button className="btn purple"
                    type="button"
                    onClick={handleRedirect}
            >
              Evaluar
            </button>
          </div>
          <div className="fieldset center">
            <div className="ico-email"></div>
          </div>
        </div>
        <div className="profileCol">
          <div className="imgCont"><img src={imgProfile02} alt=""/></div>
          <p className="name">Ana Fuentes</p>
          <div className="progress__bar">
            <div className="bar"><span className="completed" style={{ width: '100%' }}></span></div>
            <p className="percentage">Avance de curso<span>100%</span></p>
          </div>
          <div className="fieldset center">
            <button className="btn purple" type="submit">Evaluar</button>
          </div>
          <div className="fieldset center">
            <div className="ico-email"></div>
          </div>
        </div>
        <div className="profileCol">
          <div className="imgCont"><img src={imgProfile03} alt=""/></div>
          <p className="name">Ana Fuentes</p>
          <div className="progress__bar">
            <div className="bar"><span style={{ width: '50%' }}></span></div>
            <p className="percentage">Avance de curso<span>50%</span></p>
          </div>
          <div className="fieldset center">
            <button className="btn purple" type="submit">Evaluar</button>
          </div>
          <div className="fieldset center">
            <div className="ico-email"></div>
          </div>
        </div>
      </div>
      <div className="profileGrid threeCol">
        <div className="profileCol">
          <div className="imgCont"><img src={imgProfile} alt=""/></div>
          <p className="name">Ana Fuentes</p>
          <div className="progress__bar">
            <div className="bar"><span className="nocompleted" style={{ width: '75%' }}></span></div>
            <p className="percentage">Avance de curso<span>75%</span></p>
          </div>
          <div className="fieldset center">
            <button className="btn purple" type="submit">Evaluar</button>
          </div>
          <div className="fieldset center">
            <div className="ico-email"></div>
          </div>
        </div>
        <div className="profileCol">
          <div className="imgCont"><img src={imgProfile02} alt=""/></div>
          <p className="name">Ana Fuentes</p>
          <div className="progress__bar">
            <div className="bar"><span className="completed" style={{ width: '100%' }}></span></div>
            <p className="percentage">Avance de curso<span>100%</span></p>
          </div>
          <div className="fieldset center">
            <button className="btn purple" type="submit">Evaluar</button>
          </div>
          <div className="fieldset center">
            <div className="ico-email"></div>
          </div>
        </div>
        <div className="profileCol">
          <div className="imgCont"><img src={imgProfile03} alt=""/></div>
          <p className="name">Ana Fuentes</p>
          <div className="progress__bar">
            <div className="bar"><span style={{ width: '50%' }}></span></div>
            <p className="percentage">Avance de curso<span>50%</span></p>
          </div>
          <div className="fieldset center">
            <button className="btn purple" type="submit">Evaluar</button>
          </div>
          <div className="fieldset center">
            <div className="ico-email"></div>
          </div>
        </div>
      </div>
      <div className="groupBtn"><a className="btn btnGris" href="#">Cargar más</a></div>
    </section>
  )
}

export default MyStudents