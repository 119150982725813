import React from 'react'

import MySlider from '../../../../commons/components/MySlider/MySlider'
import UserProfile from '../../../../commons/components/UserProfile/UserProfile'
import InviteFriend from '../../../../commons/components/InviteFriend/InviteFriend'
import MyTeachers from './MyTeachers/MyTeachers'
import Footer from '../../../../commons/components/Footer/Footer'

import styles from './Teachers.module.css'

const Teachers = () => {

  return (
    <div className={styles.mainContainer}>
      <MySlider/>
      <UserProfile roleId={3}/>
      <MyTeachers/>
      <InviteFriend/>
      <Footer/>
    </div>
  )

}

export default Teachers
