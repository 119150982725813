import React from 'react'

import './Configuration.css'

const Configuration = () => {

  return (
    <>
      <h4>MY CONSOLE :: CONFIGURATION</h4>
    </>
  )
}

export default Configuration
