import React from 'react'
// import { useSelector } from 'react-redux'
// import { selectAuth } from '../redux/selectors/auth.selectors'

import { ADMIN_ROLE, STUDENT_ROLE, TEACHER_ROLE } from '../router/constants'

const baseRoute = '/protected'
/*export const prefixes = {
  ADMIN: `${baseRoute}/administrator`,
  STUDENT: `${baseRoute}/student`,
  TEACHER: `${baseRoute}/teacher`
}*/

const usePrefix = () => {
  // const auth = useSelector(selectAuth)
  // const { user } = auth
  const user = JSON.parse(sessionStorage.getItem('_u'))
  // console.log('usePrefix: user: 🌺🌺🌺🌺🌺', user)

  switch (+user?.role.id) {
    case TEACHER_ROLE:
      return `${baseRoute}/teacher`
    case STUDENT_ROLE:
      return `${baseRoute}/student`
    case ADMIN_ROLE:
      return `${baseRoute}/administrator`
    // default:
  }
}

export default usePrefix
