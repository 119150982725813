import React from 'react'
import { NavLink } from 'react-router-dom'

import usePrefix from '../../hooks/usePrefix'
// import { ADMIN_ROLE, STUDENT_ROLE, TEACHER_ROLE } from '../../router/constants'

import logoNam from '../../assets/images/logo-nam.png'
import styles from './Footer.module.css'
// import styles from './StudentTeacherProfile.module.css'

const Footer = () => {
  const prefix = usePrefix()
  // console.log('Footer: prefix: 💫💫💫💫💫 ', prefix)

  return (
    <footer>
      <div className={styles.container}>
        <div className={styles.footerMenu}>
          <h2>Invita a un amigo</h2>
          <nav>
            <ul>
              <li>
                {/*<a href="#">Mi cuenta</a>*/}
                <NavLink to={`${prefix}/my-account`}>
                  Mi cuenta
                </NavLink>
              </li>
              <li>
                {/*<a href="#">Reglamento</a>*/}
                <NavLink to={`${prefix}/regulation`}>
                  Reglamento
                </NavLink>
              </li>
              <li>
                {/*<a href="#">Soporte</a>*/}
                <NavLink to={`${prefix}/support`}>
                  Soporte
                </NavLink>
              </li>
              <li>
                {/*<a href="#">Aviso de Privacidad</a>*/}
                <NavLink to={`${prefix}/privacy`}>
                  Aviso de Privacidad
                </NavLink>
              </li>
            </ul>
          </nav>
        </div>
        <div className={styles.footerLogo}>
          <div className={styles.groupLogos}>
            <div className={styles.imgCont}>
              <div className={styles.logoNamibh}></div>
            </div>
            <div className={styles.imgCont}><img src={logoNam} alt="North American Ministries"/></div>
          </div>
          <p>(C) 2022 North American Missions</p>
        </div>
      </div>
    </footer>
  )
}

export default Footer
