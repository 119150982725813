import React, { useEffect, useState } from 'react'
import { NavLink, Route, Switch, useHistory, useLocation } from 'react-router-dom'

import usePrefix from '../../../../commons/hooks/usePrefix'
import imgProfile from '../../../../commons/assets/images/img-profile.jpg'

import './Console.css'

const Console = ({ children, parentPath }) => {
  const prefix = usePrefix()
  const history = useHistory()
  const location = useLocation()

  const [user, setUser] = useState(JSON.parse(sessionStorage.getItem('_u')))

  useEffect(() => {
    if (location.pathname === parentPath) {
      history.push(`${parentPath}/${children[0].path}`)
    }
  }, [location.pathname])

  return (
    <>
      <div className="headerTopAdmin">
        <div className="logoNamibh"></div>
      </div>
      <div className="mainContainer">
        <section className="adminConsole">

          <div className="leftAdminConsole">
            {/*CONSOLE PROFILE*/}
            <div className="consoleProfile">
              <div className="imgProfile"><img src={imgProfile}/></div>
              <h2>{user.name} {user.paternal}</h2>
            </div>

            {/*CONSOLE MENU*/}
            <ul className="consoleMenu">
              <li><NavLink to={`${prefix}/my-console/profile`} activeClassName="active"> Mi perfil </NavLink></li>
              <li><NavLink to={`${prefix}/my-console/users`}> Usuarios </NavLink></li>
              <li><NavLink to={`${prefix}/my-console/enrollment`}> Matrícula </NavLink></li>
              <li><NavLink to={`${prefix}/my-console/courses`}> Cursos </NavLink></li>
              <li><NavLink to={`${prefix}/my-console/e-subjects`}> Materias Extra</NavLink></li>
              <li><NavLink to={`${prefix}/my-console/c-subjects`}> Materias </NavLink></li>
              <li><NavLink to={`${prefix}/my-console/teachers`}> Profesores </NavLink></li>
              <li><NavLink to={`${prefix}/my-console/students`}> Alumnos </NavLink></li>
              <li><NavLink to={`${prefix}/my-console/devotionals`}> Devocionales </NavLink></li>
              <li className=" "><NavLink to={`${prefix}/my-console/payments`}> Pagos </NavLink></li>
              <li className=" "><NavLink to={`${prefix}/my-console/configuration`}> Configuración </NavLink></li>
              <li className=" "><NavLink to={`${prefix}/my-console/desktop`}> Escritorio </NavLink></li>
            </ul>
          </div>

          {/*TODO: CONTENIDO DINAMICO*/}
          <div className="mainAdminConsole">
            <Switch>
              {
                children.map((route, idx) => {
                  const path = `${parentPath}/${route.path}`
                  return <Route key={route.path} path={path} component={route.component} exact/>
                })
              }
            </Switch>
          </div>
        </section>
      </div>
    </>
  )
}

export default Console
