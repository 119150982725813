import React from 'react'
import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'

import _store from './commons/redux/_store'

import './commons/assets/bootstrap/bootstrap'

import AppRouter from './commons/router'

import './commons/assets/css/styles.css'

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  // <React.StrictMode>
  <Provider store={_store}>
    <AppRouter/>
  </Provider>
  // </React.StrictMode>
)
