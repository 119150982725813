import React from 'react'
import styles from './Extracurricular.module.css'
// import usePrefix from '../../hooks/usePrefix'
import imgCourseExample from '../../assets/images/img-subject-example-1.jpg'
import bgFormacion from '../../assets/images/bg-formacion.jpg'

const Extracurricular = () => {

  return (
    <section style={{ backgroundImage: `url(${bgFormacion})` }} className={styles.gridCourses}>
      <div className={`${styles.headerWithElement} ${styles.lessMargin}`}>
        <h2 className={`${styles.sectionHeader} ${styles.yellow}`}>Formación Extracurricular</h2>
        <div className={styles.element}><a href="#" className={`${styles.addLink} ${styles.white}`}>Agregar Curso</a></div>
      </div>
      <div className={styles.gridCoursesCont}>
        <div className={`${styles.gridCourseElement} ${styles.light}`}>
          <a href="#" className={styles.imgCont}>
            <img src={imgCourseExample} alt="Hermenéutica"/>
            <div className={styles.video}></div>
          </a>
          <div className={`${styles.title} ${styles.noFlex}`}>
            <p>Ministerio Juvenil</p><span className={styles.typeCourse}>Seminario</span>
          </div>
          <div className="btn btnTrans">Iniciar</div>
        </div>
        <div className={`${styles.gridCourseElement} ${styles.light}`}>
          <a href="#" className={styles.imgCont}>
            <img src={imgCourseExample} alt="Hermenéutica"/>
          </a>
          <div className={`${styles.title} ${styles.noFlex}`}>
            <p>Ministerio Infantil</p><span className={styles.typeCourse}>Curso de Verano</span>
          </div>
          <div className="btn btnTrans">Iniciar</div>
        </div>
        <div className={`${styles.gridCourseElement} ${styles.light}`}>
          <a href="#" className={styles.imgCont}>
            <img src={imgCourseExample} alt="Hermenéutica"/>
          </a>
          <div className={`${styles.title} ${styles.noFlex}`}>
            <p>Misiones &amp; Evangelismo</p><span className={styles.typeCourse}>Seminario</span>
          </div>
          <div className="btn btnTrans">Iniciar</div>
        </div>
      </div>
    </section>
  )
}

export default Extracurricular
