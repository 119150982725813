import React from 'react'
import { buildStyles, CircularProgressbarWithChildren } from 'react-circular-progressbar'

import 'react-circular-progressbar/dist/styles.css'
import styles from './StudentInProgress.module.css'

const StudentInProgress = () => {

  return (
    <section className={styles.courseGraphs}>
      <h2 className="bigTitle center">Alumnos en curso</h2>
      <h3 className="sectionHeader center red">Porcentaje de avance</h3>
      <div className={styles.courseGraphsWrap}>
        <div className={`${styles.graphElement} ${styles.yellow}`}>
          <CircularProgressbarWithChildren value={66}
                                           styles={buildStyles({
                                             // textColor: 'red',
                                             pathColor: '#eaa441'
                                             // trailColor: 'gold'
                                           })}
                                           strokeWidth={10}
          >
            <div className={styles.graphGroup}>
              <div className={styles.graphInfo}>
                <div className={styles.graphInfoText}>
                  <p>60</p><span>Básico</span>
                </div>
              </div>
            </div>
          </CircularProgressbarWithChildren>
          <div className={styles.graphTerm}>30 Alumnos</div>
        </div>
        <div className={styles.conector}></div>
        <div className={`${styles.graphElement} ${styles.yellow}`}>
          <CircularProgressbarWithChildren value={25}
                                           styles={buildStyles({
                                             // textColor: 'red',
                                             pathColor: '#e27c34'
                                             // trailColor: 'gold'
                                           })}
                                           strokeWidth={10}
          >
            <div className={styles.graphGroup}>
              <div className={styles.graphInfo}>
                <div className={styles.graphInfoText}>
                  <p>25</p><span>Avanzado</span>
                </div>
              </div>
            </div>
          </CircularProgressbarWithChildren>
          <div className={styles.graphTerm}>30 Alumnos</div>
        </div>
        <div className={styles.conector}></div>
        <div className={`${styles.graphElement} ${styles.yellow}`}>
          <CircularProgressbarWithChildren value={45}
                                           styles={buildStyles({
                                             // textColor: 'red',
                                             pathColor: '#c83e28'
                                             // trailColor: 'gold'
                                           })}
                                           strokeWidth={10}
          >
            <div className={styles.graphGroup}>
              <div className={styles.graphInfo}>
                <div className={styles.graphInfoText}>
                  <p>45</p><span>Especialidad</span>
                </div>
              </div>
            </div>
          </CircularProgressbarWithChildren>
          <div className={styles.graphTerm}>30 Alumnos</div>
        </div>
      </div>
      <div className={styles.courseGraphsQuantity}>
        <ul>
          <li className={styles.man}>Hombres</li>
          <li className={styles.woman}>Mujeres</li>
          <li className={styles.pay}>Pagado</li>
          <li className={styles.debit}>Adeudo</li>
        </ul>
        <div className={styles.courseGraphsQuantityLevels}>
          <div className={styles.col}>
            <dl>
              <dt>Curso 2020</dt>
              <dd>
                <span style={{ width: 'calc(65% - 8px)' }} className={styles.man}></span>
                <span style={{ width: 'calc(35% - 8px)' }} className={styles.woman}></span>
              </dd>
            </dl>
            <dl>
              <dt>Curso 2021</dt>
              <dd>
                <span style={{ width: 'calc(25% - 8px)' }} className={styles.man}></span>
                <span style={{ width: 'calc(75% - 8px)' }} className={styles.woman}></span>
              </dd>
            </dl>
            <dl>
              <dt>Curso 2022</dt>
              <dd>
                <span style={{ width: 'calc(40% - 8px)' }} className={styles.man}></span>
                <span style={{ width: 'calc(60% - 8px)' }} className={styles.woman}></span>
              </dd>
            </dl>
          </div>
          <div className={styles.col}>
            <dl>
              <dt>Curso 2020</dt>
              <dd>
                <span style={{ width: 'calc(65% - 8px)' }} className={styles.pay}></span>
                <span style={{ width: 'calc(35% - 8px)' }} className={styles.debit}></span>
              </dd>
            </dl>
            <dl>
              <dt>Curso 2021</dt>
              <dd>
                <span style={{ width: 'calc(25% - 8px)' }} className={styles.pay}></span>
                <span style={{ width: 'calc(75% - 8px)' }} className={styles.debit}></span>
              </dd>
            </dl>
            <dl>
              <dt>Curso 2022</dt>
              <dd>
                <span style={{ width: 'calc(40% - 8px)' }} className={styles.pay}></span>
                <span style={{ width: 'calc(60% - 8px)' }} className={styles.debit}></span>
              </dd>
            </dl>
          </div>
        </div>
      </div>
    </section>

  )
}

export default StudentInProgress
