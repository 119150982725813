import React from 'react'

import CourseForm from '../CourseForm/CourseForm'

import './CourseContainer.css'

const CourseContainer = ({keyId = 0}) => {

  return (
    <CourseForm/>
  )

}

export default CourseContainer