import React from 'react'

import UserContainer from '../commons/UserContainer/UserContainer'

import defaultAvatar from '../../../../../../commons/assets/images/default-avatar.jpeg'
// import imgProfile from '../../../../../../commons/assets/images/img-profile.jpg'
import './UsersGrid.css'

const UsersGrid = ({ keyOp, items, setReload }) => {
  return (
    <dl className="gridUsers">
      {
        items.map(item => (
          <React.Fragment key={`tg_${item.id}`}>
            <dt>
              <div className="userCard">
                <div className="imgProfile"><img src={defaultAvatar} alt=""/></div>
                <p className="name">{item.name} {item.paternal}</p>
                <p className="role">Administración</p>
              </div>
            </dt>
            <dd>
              <UserContainer keyId={`ctg_${item.id}`}
                             keyOp={keyOp}
                             item={item}
                             setReload={setReload}
              />
            </dd>
          </React.Fragment>
        ))
      }
    </dl>
  )
}

export default UsersGrid