import React, { useEffect, useState } from 'react'

import DevotionalsGrid from '../Devotionals/DevotionalsGrid/DevotionalsGrid'
import DevotionalsList from '../Devotionals/DevotionalsList/DevotionalsList'
import DevotionalForm from './commons/DevotionalForm/DevotionalForm'

import $ from 'jquery'

import './Devotionals.css'

const Devotionals = () => {
  const [gridView, setGridView] = useState(true)

  useEffect(() => {
    $('.gridTabCourses').gridtab({
      grid: 3,
      tabPadding: 0,
      borderWidth: 0,
      contentPadding: 0,
      responsive: [{
        breakpoint: 520,
        settings: {
          grid: 2
        }
      }]
    })

    $('.respTabsProfile').responsiveTabs({
      startCollapsed: false,
      active: 0
    })

    $('.accRow .accSelector').on('click', function () {
      $(this).toggleClass('open')
      $(this).parent().find('.accWrap').slideToggle()
    })
  }, [gridView])

  return (
    <>
      <div className="consoleSection consoleHeader">
        <h2 className="sectionHeader withElements"><span>Devocionales</span>
          <ul className="view">
            {/*<li>
              <a className="search" href="#" onClick={() => {
                $('.newSearch').slideToggle()
              }}>
              </Link>
            </li>*/}
            <li>
              <a className="create" href="#" onClick={() => {
                $('.newRequest').slideToggle()
              }}>
              </a>
            </li>
            <li>
              <a className={gridView ? 'viewList' : 'viewGrid'} href="#" onClick={
                () => setGridView(!gridView)
              }>
              </a>
            </li>
          </ul>
        </h2>
      </div>

      {/*FILTROS*/}
      {/*<div className="consoleClass">
        <div className="sectionSelect">
          <div className="gridClass">
            <div className="fieldset">
              <span className="select select-color square">
                <select className="color-filter">
                  <option>Matrícula</option>
                  <option>Clase 2019</option>
                  <option>Clase 2020</option>
                  <option>Clase 2021</option>
                </select>
              </span>
            </div>

            <div className="fieldset">
              <span className="select select-color-gray square">
                <select className="color-gray-filter">
                  <option>Estado</option>
                  <option>Avance de curso</option>
                  <option>Estatus de inscripción</option>
                  <option>Mas recientes</option>
                  <option>Mas antiguos</option>
                  <option>Alfabéticamente (A..Z)</option>
                </select>
              </span>
            </div>
          </div>
        </div>
      </div>*/}

      {/*BUSQUEDAS*/}
      {/*<div className="newSearch" style={{display: 'none'}}>
        <DevotionalSearch/>
      </div>*/}

      {/*NUEVO ALUMNO*/}
      <div className="newRequest" style={{ display: 'none' }}>
        <DevotionalForm/>
      </div>

      <div className="consoleSection consoleUsers">
        <div className="consoleUsersList">

          {gridView
            ? <DevotionalsGrid/>
            : <DevotionalsList/>
          }

        </div>
      </div>


    </>
  )

}

export default Devotionals
