import React from 'react'
import { Redirect, Route } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { prefixes, STUDENT_ROLE, TEACHER_ROLE } from '../constants'
import { selectAuth } from '../../redux/selectors/auth.selectors'
import Home from '../../../containers/protected/administrator/Home/Home'
import Console from '../../../containers/protected/administrator/Console/Console'
import AdministratorProfile from '../../../containers/protected/administrator/Console/AdministratorProfile/AdministratorProfile'
import Users from '../../../containers/protected/administrator/Console/Users/Users'
import Enrollment from '../../../containers/protected/administrator/Console/Enrollment/Enrollment'
import Courses from '../../../containers/protected/administrator/Console/Courses/Courses'
import ExtraSubjects from '../../../containers/protected/administrator/Console/ExtraSubjects/ExtraSubjects'
import Subjects from '../../../containers/protected/administrator/Console/Subjects/Subjects'
import Teachers from '../../../containers/protected/administrator/Console/Teachers/Teachers'
import Students from '../../../containers/protected/administrator/Console/Students/Students'
import Devotionals from '../../../containers/protected/administrator/Console/Devotionals/Devotionals'
import Payments from '../../../containers/protected/administrator/Console/Payments/Payments'
import Configuration from '../../../containers/protected/administrator/Console/Configuration/Configuration'
import Desktop from '../../../containers/protected/administrator/Console/Desktop/Desktop'

const prefix = prefixes.ADMIN

export const AdministratorRoutes = [
  {
    component: Home,
    path: `${prefix}/home`
  },
  /*{
    component: DevotionalHistory,
    path: `${prefix}/devotionals/:devotionalId?`
  },*/
  {
    component: Console,
    path: `${prefix}/my-console`,
    children: [
      {
        path: `profile`,
        component: AdministratorProfile
      },
      {
        path: `users`,
        component: Users
      },
      {
        path: `enrollment`,
        component: Enrollment
      },
      {
        path: `courses`,
        component: Courses
      },
      {
        path: `e-subjects`,
        component: ExtraSubjects
      },
      {
        path: `c-subjects`,
        component: Subjects
      },
      {
        path: `teachers`,
        component: Teachers
      },
      {
        path: `students`,
        component: Students
      },
      {
        path: `devotionals`,
        component: Devotionals
      },
      {
        path: `payments`,
        component: Payments
      },
      {
        path: `configuration`,
        component: Configuration
      },
      {
        path: `desktop`,
        component: Desktop
      }
    ]
  }
]

const AdministratorPrivateRoute = ({ user, children, component: Component, ...rest }) => {
  console.log('AdministratorPrivateRoute: roleId: ', user.role.id)
  const auth = useSelector(selectAuth)
  const { dataLoaded } = auth

  if (dataLoaded) {
    return <Route {...rest}
                  render={(props) => (
                    user && (+user?.role.id !== STUDENT_ROLE && +user?.role.id !== TEACHER_ROLE)
                      ? <Component {...props} children={children} parentPath={rest.path} location={{ ...rest.location }}/>
                      : <Redirect to="/login"/>
                  )}/>
  }
  return null
}

export default AdministratorPrivateRoute
