import React from 'react'

import bgSemanal from '../../assets/images/bg-semanal.jpg'
import imgProfile02 from '../../assets/images/img-profile-02.jpg'

import styles from './MyDevotional.module.css'

const MyDevotional = () => {

  return (
    <section style={{ backgroundImage: `url(${bgSemanal})` }} className={styles.devWeek}>
      <h2 className={`${styles.sectionHeader} ${styles.red}`}>Devoción Semanal</h2>
      <p className={styles.bigTitle}>5 características de un líder efectivo.</p>
      <div className={styles.person}>
        <img src={imgProfile02} alt="Rick Bowling"/>
        <p>Rick Bowling</p>
      </div>
      <div className={styles.text}>
        <p>
          El liderazgo efectivo es simplemente aquél que logra resultados.
        </p>
        <p>Aquél que se ve y no del cual se habla.</p>
        <p>
          Un líder efectivo, pone por encima de su deseo por obtener
          reconocimiento, la necesidad de triunfar y alcanzar el éxito tanto
          para sí mismo, como para los demás.
        </p>
      </div>
      <div className="btn">Leer</div>
    </section>
  )
}

export default MyDevotional
