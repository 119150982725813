import React from 'react'

import UserForm from '../UserForm/UserForm'

import './UserContainer.css'

const UserContainer = ({ keyId = 0, keyOp, item, setReload }) => {

  return (
    <UserForm keyOp={keyOp}
              item={item}
              setReload={setReload}
    />
  )

}

export default UserContainer