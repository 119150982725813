import React from 'react'

import TeacherContainer from '../commons/TeacherContainer/TeacherContainer'

import defaultAvatar from '../../../../../../commons/assets/images/default-avatar.jpeg'
// import imgProfile from '../../../../../../commons/assets/images/img-profile.jpg'

import './TeachersGrid.css'

const TeachersGrid = ({ keyOp, items }) => {
  return (
    <dl className="gridTeachers">
      {
        items.map(item => (
          <React.Fragment key={`tg_${item.id}`}>
            <dt>
              <div className="userCard">
                <div className="imgProfile"><img src={defaultAvatar} alt=""/></div>
                <p className="name">{item.name} {item.paternal}</p>
                <p className="role">Administración</p>
              </div>
            </dt>
            <dd>
              <TeacherContainer keyId={`ctg_${item.id}`}
                                keyOp={keyOp}
                                item={item}/>
            </dd>
          </React.Fragment>
        ))
      }
    </dl>
  )
}

export default TeachersGrid