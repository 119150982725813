import React, { useEffect, useState } from 'react'

import EnrollmentsList from './EnrollmentsList/EnrollmentsList'
import { getEnrollments } from '../../../../../commons/redux/actions/enrollment.actions'
import { useDispatch, useSelector } from 'react-redux'

import './Enrollment.css'

import $ from 'jquery'
import { selectEnrollment } from '../../../../../commons/redux/selectors/enrollment.selectors'

const Enrollment = () => {

  const dispatch = useDispatch()
  const { enrollments } = useSelector(selectEnrollment)
  console.log(enrollments)

  const [gridView, setGridView] = useState(true)

  const toEnableGrid = () => {
    $('.gridTabCourses').gridtab({
      grid: 3,
      tabPadding: 0,
      borderWidth: 0,
      contentPadding: 0,
      responsive: [{
        breakpoint: 520,
        settings: {
          grid: 2
        }
      }]
    })

    $('.respTabsProfile').responsiveTabs({
      startCollapsed: false,
      active: 0
    })

    $('.accRow .accSelectorm').on('click', function () {
      $(this).toggleClass('open')
      $(this).parent().find('.accWrap').slideToggle()
    })
  }

  useEffect(() => {
    const retrieveEnrollments = async () => {
      await dispatch(getEnrollments())
    }

    retrieveEnrollments().then(_ => {
      console.log('All Enrollments are loaded...')
      toEnableGrid()
    })
  }, [])

  return (
    <>
      <div className="consoleSection consoleHeader">
        <h2 className="sectionHeader withElements"><span>Matricula</span>
          <ul className="view">
            {/*<li>
              <a className="search" href="#" onClick={() => {
                $('.newSearch').slideToggle()
              }}>
              </Link>
            </li>*/}
            <li>
              <a className="create" href="#" onClick={() => {
                $('.newRequest').slideToggle()
              }}>
              </a>
            </li>
            <li>
              <a className={gridView ? 'viewList' : 'viewGrid'} href="#" onClick={
                () => setGridView(!gridView)
              }>
              </a>
            </li>
          </ul>
        </h2>
      </div>

      {/*FILTROS*/}
      {/*<div className="consoleClass">
        <div className="sectionSelect">
          <div className="gridClass">
            <div className="fieldset">
              <span className="select select-color square">
                <select className="color-filter">
                  <option>Matrícula</option>
                  <option>Clase 2019</option>
                  <option>Clase 2020</option>
                  <option>Clase 2021</option>
                </select>
              </span>
            </div>

            <div className="fieldset">
              <span className="select select-color-gray square">
                <select className="color-gray-filter">
                  <option>Estado</option>
                  <option>Avance de curso</option>
                  <option>Estatus de inscripción</option>
                  <option>Mas recientes</option>
                  <option>Mas antiguos</option>
                  <option>Alfabéticamente (A..Z)</option>
                </select>
              </span>
            </div>
          </div>
        </div>
      </div>*/}

      {/*BUSQUEDAS*/}
      {/*<div className="newSearch" style={{display: 'none'}}>
        <EnrollmentSearch/>
      </div>*/}

      {/*NUEVA MATERIA*/}
      <div className="newRequest" style={{ display: 'none' }}>
        {/*<EnrollmentForm keyOp="add" teachers={teachers}/>*/}
      </div>

      <div className="consoleSection consoleUsers">
        <div className="consoleUsersList">

          {enrollments && enrollments.length > 0 && (
            <>
              <div style={{ display: `${gridView ? 'block' : 'none'}` }}>
                <EnrollmentsList keyOp="upd"
                                 items={enrollments}/>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  )
}

export default Enrollment
