import { fetchTeachers, saveTeacher, updateTeacher } from '../services/teacher.services'
import Swal from 'sweetalert2'

import { LOADER_OFF, LOADER_ON } from './auth.actions'

export const GET_ALL_TEACHERS_INIT = 'GET_ALL_TEACHERS_INIT'
export const GET_ALL_TEACHERS_SUCCESS = 'GET_ALL_TEACHERS_SUCCESS'
export const GET_ALL_TEACHERS_ERROR = 'GET_ALL_TEACHERS_ERROR'
export const NEW_TEACHER_INIT = 'NEW_TEACHER_INIT'
export const NEW_TEACHER_SUCCESS = 'NEW_TEACHER_SUCCESS'
export const NEW_TEACHER_ERROR = 'NEW_TEACHER_ERROR'
export const GET_TEACHER_INIT = 'GET_TEACHER_INIT'
export const GET_TEACHER_SUCCESS = 'GET_TEACHER_SUCCESS'
export const GET_TEACHER_ERROR = 'GET_TEACHER_ERROR'
export const UPDATE_TEACHER_INIT = 'UPDATE_TEACHER_INIT'
export const UPDATE_TEACHER_SUCCESS = 'UPDATE_TEACHER_SUCCESS'
export const UPDATE_TEACHER_ERROR = 'UPDATE_TEACHER_ERROR'
export const DELETE_TEACHER_INIT = 'DELETE_TEACHER_INIT'
export const DELETE_TEACHER_SUCCESS = 'DELETE_TEACHER_SUCCESS'
export const DELETE_TEACHER_ERROR = 'DELETE_TEACHER_ERROR'

export function getTeachers() {
  return async (dispatch) => {
    try {
      dispatch({ type: LOADER_ON })
      dispatch({ type: GET_ALL_TEACHERS_INIT })
      const result = await fetchTeachers()
      // console.log(result)
      if (result.data) {
        dispatch({ type: LOADER_OFF })
        dispatch({ type: GET_ALL_TEACHERS_SUCCESS, data: [...result.data.data] })
      } else {
        dispatch({ type: LOADER_OFF })
        dispatch({ type: GET_ALL_TEACHERS_ERROR })
        await Swal.fire(
          'Advertencia',
          'Ha ocurrido algo inesperado, contacte a soporte',
          'warning'
        )
      }
    } catch (e) {
      dispatch({ type: LOADER_OFF })
      dispatch({ type: GET_ALL_TEACHERS_ERROR })

      /*const sessionActive =
        e.response.data.detalles[0] ===
        'El usuario cuenta con una sesion activa, puedes intentar nuevamente despúes de 10 minutos.'
      if (sessionActive) {
        Swal.fire({
          title: 'Error',
          text: e.response.data.detalles[0],
          confirmButtonText: 'Aceptar'
        }).then(async (result) => {
          if (result.isConfirmed) {
            dispatch({ type: GET_ALL_TEACHERS_ERROR })
          }
        })
      } else {
        await Swal.fire('Error', e.response.data.detalles[0], 'warning')
      }*/
    }
  }
}

export function newTeacher(values) {
  return async (dispatch) => {
    try {
      dispatch({ type: LOADER_ON })
      dispatch({ type: NEW_TEACHER_INIT })
      const result = await saveTeacher(values)
      // console.log(result)
      dispatch({ type: NEW_TEACHER_SUCCESS, data: { ...result.data } })
      dispatch({ type: LOADER_OFF })
      await Swal.fire('Éxito', 'Operación se realizó con éxito', 'success')
    } catch (e) {
      console.log('newTeacher: error: ', e)
      dispatch({ type: NEW_TEACHER_ERROR })
      dispatch({ type: LOADER_OFF })
      await Swal.fire('Error', 'Ocurrió algo inesperado, contacte a soporte', 'error')
    }
  }
}

export function editTeacher(values) {
  return async (dispatch) => {
    try {
      dispatch({ type: LOADER_ON })
      dispatch({ type: UPDATE_TEACHER_INIT })
      const result = await updateTeacher(values)
      // console.log(result)
      dispatch({ type: UPDATE_TEACHER_SUCCESS, data: { ...result.data } })
      dispatch({ type: LOADER_OFF })
      await Swal.fire('Éxito', 'Operación se realizó con éxito', 'success')
    } catch (e) {
      console.log('editTeacher: error: ', e)
      dispatch({ type: UPDATE_TEACHER_ERROR })
      dispatch({ type: LOADER_OFF })
      await Swal.fire('Error', 'Ocurrió algo inesperado, contacte a soporte', 'error')
    }
  }
}

/*export function getTeacher(teacherId) {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_TEACHER_INIT })
      const result = await fetchTeacher(teacherId)
      // console.log(result)
      dispatch({ type: GET_TEACHER_SUCCESS, data: { ...result.data } })
    } catch (e) {
      console.log('retrieveTeacher: error: ', e)
      dispatch({ type: GET_TEACHER_ERROR })
      await Swal.fire('Error', e.response.data.detalles[0], 'warning')
    }
  }
}

export function editTeacher(values) {
  return async (dispatch) => {
    try {
      dispatch({ type: UPDATE_TEACHER_INIT })
      const result = await updateTeacher(values)
      // console.log(result)
      dispatch({ type: UPDATE_TEACHER_SUCCESS, data: { ...result.data } })
    } catch (e) {
      console.log('editTeacher: error: ', e)
      dispatch({ type: UPDATE_TEACHER_ERROR })
      await Swal.fire('Error', e.response.data.detalles[0], 'warning')
    }
  }
}

export function removeTeacher(teacherId) {
  return async (dispatch) => {
    try {
      dispatch({ type: DELETE_TEACHER_INIT })
      const result = await deleteTeacher(teacherId)
      // console.log(result)
      dispatch({ type: DELETE_TEACHER_SUCCESS, data: { ...result.data } })
    } catch (e) {
      console.log('removeTeacher: error: ', e)
      dispatch({ type: DELETE_TEACHER_ERROR })
      await Swal.fire('Error', e.response.data.detalles[0], 'warning')
    }
  }
}*/
