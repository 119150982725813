import { fetchSubjects, fetchSubjectsFromStudent, saveSubject, updateSubject } from '../services/subject.services'
import Swal from 'sweetalert2'

import { LOADER_OFF, LOADER_ON } from './auth.actions'
export const GET_ALL_SUBJECTS_INIT = 'GET_ALL_SUBJECTS_INIT'
export const GET_ALL_SUBJECTS_SUCCESS = 'GET_ALL_SUBJECTS_SUCCESS'
export const GET_ALL_SUBJECTS_ERROR = 'GET_ALL_SUBJECTS_ERROR'
export const GET_ALL_SUBJECTS_FROM_STUDENT_INIT = 'GET_ALL_SUBJECTS_FROM_STUDENT_INIT'
export const GET_ALL_SUBJECTS_FROM_STUDENT_SUCCESS = 'GET_ALL_SUBJECTS_FROM_STUDENT_SUCCESS'
export const GET_ALL_SUBJECTS_FROM_STUDENT_ERROR = 'GET_ALL_SUBJECTS_FROM_STUDENT_ERROR'
export const NEW_SUBJECT_INIT = 'NEW_SUBJECT_INIT'
export const NEW_SUBJECT_SUCCESS = 'NEW_SUBJECT_SUCCESS'
export const NEW_SUBJECT_ERROR = 'NEW_SUBJECT_ERROR'
export const GET_SUBJECT_INIT = 'GET_SUBJECT_INIT'
export const GET_SUBJECT_SUCCESS = 'GET_SUBJECT_SUCCESS'
export const GET_SUBJECT_ERROR = 'GET_SUBJECT_ERROR'
export const UPDATE_SUBJECT_INIT = 'UPDATE_SUBJECT_INIT'
export const UPDATE_SUBJECT_SUCCESS = 'UPDATE_SUBJECT_SUCCESS'
export const UPDATE_SUBJECT_ERROR = 'UPDATE_SUBJECT_ERROR'
export const DELETE_SUBJECT_INIT = 'DELETE_SUBJECT_INIT'
export const DELETE_SUBJECT_SUCCESS = 'DELETE_SUBJECT_SUCCESS'
export const DELETE_SUBJECT_ERROR = 'DELETE_SUBJECT_ERROR'

export function getSubjects() {
  return async (dispatch) => {
    try {
      dispatch({ type: LOADER_ON })
      dispatch({ type: GET_ALL_SUBJECTS_INIT })
      const result = await fetchSubjects()
      console.log(result)
      if (result.data?.data) {
        dispatch({ type: LOADER_OFF })
        dispatch({ type: GET_ALL_SUBJECTS_SUCCESS, data: [...result.data.data] })
      } else {
        dispatch({ type: LOADER_OFF })
        dispatch({ type: GET_ALL_SUBJECTS_ERROR })
        await Swal.fire(
          'Advertencia',
          'Ha ocurrido algo inesperado, contacte a soporte',
          'warning'
        )
      }
    } catch (e) {
      dispatch({ type: LOADER_OFF })
      dispatch({ type: GET_ALL_SUBJECTS_ERROR })

      /*const sessionActive =
        e.response.data.detalles[0] ===
        'El usuario cuenta con una sesion activa, puedes intentar nuevamente despúes de 10 minutos.'
      if (sessionActive) {
        Swal.fire({
          title: 'Error',
          text: e.response.data.detalles[0],
          confirmButtonText: 'Aceptar'
        }).then(async (result) => {
          if (result.isConfirmed) {
            dispatch({ type: GET_ALL_SUBJECTS_ERROR })
          }
        })
      } else {
        await Swal.fire('Error', e.response.data.detalles[0], 'warning')
      }*/
    }
  }
}

export function getSubjectsFromStudent(id) {
  return async (dispatch) => {
    try {
      dispatch({ type: LOADER_ON })
      dispatch({ type:  GET_ALL_SUBJECTS_FROM_STUDENT_INIT })
      const result = await fetchSubjectsFromStudent(id)
      console.log(result)
      dispatch({ type: GET_ALL_SUBJECTS_FROM_STUDENT_SUCCESS, data: [ ...result.data.data ] })
      dispatch({ type: LOADER_OFF })
      // await Swal.fire('Éxito', 'Operación se realizó con éxito', 'success')
    } catch (e) {
      console.log('getSubjectsFromStudent: error: ', e)
      dispatch({ type: GET_ALL_SUBJECTS_FROM_STUDENT_ERROR })
      dispatch({ type: LOADER_OFF })
      await Swal.fire('Error', 'Ocurrió algo inesperado, contacte a soporte', 'error')
    }
  }
}

export function newSubject(values) {
  return async (dispatch) => {
    try {
      dispatch({ type: LOADER_ON })
      dispatch({ type: NEW_SUBJECT_INIT })
      const result = await saveSubject(values)
      // console.log(result)
      dispatch({ type: NEW_SUBJECT_SUCCESS, data: { ...result.data } })
      dispatch({ type: LOADER_OFF })
      await Swal.fire('Éxito', 'Operación se realizó con éxito', 'success')
    } catch (e) {
      console.log('newSubject: error: ', e)
      dispatch({ type: NEW_SUBJECT_ERROR })
      dispatch({ type: LOADER_OFF })
      await Swal.fire('Error', 'Ocurrió algo inesperado, contacte a soporte', 'error')
    }
  }
}

export function editSubject(values) {
  return async (dispatch) => {
    try {
      dispatch({ type: LOADER_ON })
      dispatch({ type: UPDATE_SUBJECT_INIT })
      const result = await updateSubject(values)
      // console.log(result)
      dispatch({ type: UPDATE_SUBJECT_SUCCESS, data: { ...result.data } })
      dispatch({ type: LOADER_OFF })
      await Swal.fire('Éxito', 'Operación se realizó con éxito', 'success')
    } catch (e) {
      console.log('editSubject: error: ', e)
      dispatch({ type: UPDATE_SUBJECT_ERROR })
      dispatch({ type: LOADER_OFF })
      await Swal.fire('Error', 'Ocurrió algo inesperado, contacte a soporte', 'error')
    }
  }
}

/*export function getSubject(subjectId) {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_SUBJECT_INIT })
      const result = await fetchSubject(subjectId)
      // console.log(result)
      dispatch({ type: GET_SUBJECT_SUCCESS, data: { ...result.data } })
    } catch (e) {
      console.log('retrieveSubject: error: ', e)
      dispatch({ type: GET_SUBJECT_ERROR })
      await Swal.fire('Error', e.response.data.detalles[0], 'warning')
    }
  }
}

export function editSubject(values) {
  return async (dispatch) => {
    try {
      dispatch({ type: UPDATE_SUBJECT_INIT })
      const result = await updateSubject(values)
      // console.log(result)
      dispatch({ type: UPDATE_SUBJECT_SUCCESS, data: { ...result.data } })
    } catch (e) {
      console.log('editSubject: error: ', e)
      dispatch({ type: UPDATE_SUBJECT_ERROR })
      await Swal.fire('Error', e.response.data.detalles[0], 'warning')
    }
  }
}

export function removeSubject(subjectId) {
  return async (dispatch) => {
    try {
      dispatch({ type: DELETE_SUBJECT_INIT })
      const result = await deleteSubject(subjectId)
      // console.log(result)
      dispatch({ type: DELETE_SUBJECT_SUCCESS, data: { ...result.data } })
    } catch (e) {
      console.log('removeSubject: error: ', e)
      dispatch({ type: DELETE_SUBJECT_ERROR })
      await Swal.fire('Error', e.response.data.detalles[0], 'warning')
    }
  }
}*/
