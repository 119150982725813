import React from 'react'

import bgDevotional1 from '../../../commons/assets/images/bg-devotional-1.png'
import bgDevotional2 from '../../../commons/assets/images/bg-devotional-2.png'
import bgDevotional3 from '../../../commons/assets/images/bg-devotional-3.png'
import bgDevotional4 from '../../../commons/assets/images/bg-devotional-4.png'
import bgDevotional5 from '../../../commons/assets/images/bg-devotional-5.png'
import bgDevotional6 from '../../../commons/assets/images/bg-devotional-6.png'
import iconComplete from '../../../commons/assets/images/icon-complete.svg'
import iconExpired from '../../../commons/assets/images/icon-expired.svg'


import './DevotionalHistory.css'

const DevotionalHistory = () => {

  return (
    <section>
      <div className="studentConsole">
        <div className="mainStudentConsole">
          <div className="consoleSection">
            <div className="gridTabFilter">
              <div className="tabFilter">
                <form className="filterInfo" action="">
                  <div className="fieldset">
                    <label>Categoría:</label>
                    <div className="inputWrap">
                      <span className="select rounded">
                        <select>
                          <option>Todos</option>
                          <option>Categoría 1</option>
                          <option>Categoría 2</option>
                          <option>Categoría 3</option>
                        </select>
                      </span>
                    </div>
                  </div>
                  <div className="fieldset">
                    <label>Autor:</label>
                    <div className="inputWrap">
                      <span className="select rounded">
                        <select>
                          <option>Todos</option>
                          <option>Autor 1</option>
                          <option>Autor 2</option>
                          <option>Autor 3</option>
                        </select>
                      </span>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="gridTabStudents">
              <div className="studentCard square">
                <div className="imgProfile">
                  <img src={bgDevotional1}/>
                </div>
                <div className="name">
                  Litot Europa<span className="icon"><img src={iconComplete}/></span>
                </div>
                <div className="btn standard">Leer</div>
              </div>
              <div className="studentCard square">
                <div className="imgProfile">
                  <img src={bgDevotional2}/>
                </div>
                <div className="name">
                  Lor separat existentie<span className="icon"><img src={iconComplete}/></span>
                </div>
                <div className="btn standard">Leer</div>
              </div>
              <div className="studentCard square">
                <div className="imgProfile">
                  <img src={bgDevotional3}/>
                </div>
                <div className="name">
                  At solmen va esser<span className="icon"><img src={iconExpired}/></span>
                </div>
                <div className="btn standard">Leer</div>
              </div>
            </div>
            <div className="gridTabStudents">
              <div className="studentCard square">
                <div className="imgProfile">
                  <img src={bgDevotional4}/>
                </div>
                <div className="name">
                  Litot Europa<span className="icon"
                ><img src={iconComplete}
                /></span>
                </div>
                <div className="btn standard">Leer</div>
              </div>
              <div className="studentCard square">
                <div className="imgProfile">
                  <img src={bgDevotional5}/>
                </div>
                <div className="name">
                  Lor separat existentie<span className="icon"
                ><img src={iconComplete}
                /></span>
                </div>
                <div className="btn standard">Leer</div>
              </div>
              <div className="studentCard square">
                <div className="imgProfile">
                  <img src={bgDevotional6}/>
                </div>
                <div className="name">
                  At solmen va esser<span className="icon"
                ><img src={iconComplete}
                /></span>
                </div>
                <div className="btn standard">Leer</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="groupBtn">
        <a className="btn btnGris" href="#">Cargar más</a>
      </div>
    </section>
  )
}

export default DevotionalHistory
