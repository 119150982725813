import {
  GET_ALL_SUBJECTS_ERROR,
  GET_ALL_SUBJECTS_FROM_STUDENT_ERROR,
  GET_ALL_SUBJECTS_FROM_STUDENT_INIT,
  GET_ALL_SUBJECTS_FROM_STUDENT_SUCCESS,
  GET_ALL_SUBJECTS_INIT,
  GET_ALL_SUBJECTS_SUCCESS,
  NEW_SUBJECT_ERROR,
  NEW_SUBJECT_INIT,
  NEW_SUBJECT_SUCCESS,
  UPDATE_SUBJECT_ERROR,
  UPDATE_SUBJECT_INIT,
  UPDATE_SUBJECT_SUCCESS
} from '../actions/subject.actions'

const initialState = {
  loading: false,
  error: null,
  newSubject: null,
  subject: null
}

export default (state = initialState, action) => {
  let descendingOrderedSubjects = []
  switch (action.type) {
    case GET_ALL_SUBJECTS_INIT:
      return { ...state, loading: true, subjects: [] }
    case GET_ALL_SUBJECTS_SUCCESS:
      console.log('GET_ALL_SUBJECTS_SUCCESS: data: ', action.data)
      descendingOrderedSubjects = [...action.data].sort((a, b) => b.id - a.id)
      return { ...state, subjects: descendingOrderedSubjects, loading: false }
    case GET_ALL_SUBJECTS_ERROR:
      return { ...state, loading: false, error: action.error }

    case GET_ALL_SUBJECTS_FROM_STUDENT_INIT:
      return { ...state, loading: true, subjects: [] }
    case GET_ALL_SUBJECTS_FROM_STUDENT_SUCCESS:
      descendingOrderedSubjects = [...action.data].sort((a, b) => b.id - a.id)
      return { ...state, subjects: descendingOrderedSubjects, loading: false }
    case GET_ALL_SUBJECTS_FROM_STUDENT_ERROR:
      return { ...state, loading: false, error: action.error }

    case NEW_SUBJECT_INIT:
      return { ...state, loading: true, subject: {} }
    case NEW_SUBJECT_SUCCESS:
      descendingOrderedSubjects = [...state.subjects, { ...action.data }].sort((a, b) => b.id - a.id)
      return { ...state, subject: { ...action.data }, subjects: descendingOrderedSubjects, loading: false }
    case NEW_SUBJECT_ERROR:
      return { ...state, loading: false, subject: {}, error: action.error }
    case UPDATE_SUBJECT_INIT:
      return { ...state, loading: true }
    case UPDATE_SUBJECT_SUCCESS:
      const filterSubjects = state.subjects.filter(subject => subject.id != action.data.id)
      descendingOrderedSubjects = [...filterSubjects, { ...action.data }].sort((a, b) => b.id - a.id)
      return { ...state, loading: false, subjects: descendingOrderedSubjects }
    case UPDATE_SUBJECT_ERROR:
      return { ...state, loading: false, error: action.error }
    default:
      return state
  }
}
