import {
  GET_ALL_USERS_ERROR,
  GET_ALL_USERS_INIT,
  GET_ALL_USERS_SUCCESS,
  NEW_USER_ERROR,
  NEW_USER_INIT,
  NEW_USER_SUCCESS,
  UPDATE_USER_ERROR,
  UPDATE_USER_INIT,
  UPDATE_USER_SUCCESS
} from '../actions/user.actions'

const initialState = {
  loading: false,
  reload: false,
  error: null,
  newUser: null,
  user: null
}

export default (state = initialState, action) => {
  let descendingOrderedUsers = []
  switch (action.type) {
    case GET_ALL_USERS_INIT:
      return { ...state, loading: true, users: [] }
    case GET_ALL_USERS_SUCCESS:
      descendingOrderedUsers = [...action.data].sort((a, b) => b.id - a.id)
      return { ...state, users: descendingOrderedUsers, loading: false}
    case GET_ALL_USERS_ERROR:
      return { ...state, loading: false, error: action.error }
    case NEW_USER_INIT:
      return { ...state, loading: true, user: {} }
    case NEW_USER_SUCCESS:
      descendingOrderedUsers = [...state.users, { ...action.data }].sort((a, b) => b.id - a.id)
      return { ...state, user: { ...action.data }, users: descendingOrderedUsers, loading: false }
    case NEW_USER_ERROR:
      return { ...state, loading: false, user: {}, error: action.error }
    case UPDATE_USER_INIT:
      return { ...state, loading: true }
    case UPDATE_USER_SUCCESS:
      const filterUsers = state.users.filter(user => user.id != action.data.id)
      descendingOrderedUsers = [...filterUsers, { ...action.data }].sort((a, b) => b.id - a.id)
      return { ...state, loading: false, users: descendingOrderedUsers }
    case UPDATE_USER_ERROR:
      return { ...state, loading: false, error: action.error }
    default:
      return state
  }
}
