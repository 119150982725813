import React from 'react'

import StudentContainer from '../commons/StudentContainer/StudentContainer'

// import imgProfile from '../../../../../../commons/assets/images/img-profile.jpg'
// import imgProfile02 from '../../../../../../commons/assets/images/img-profile-02.jpg'
// import imgProfile03 from '../../../../../../commons/assets/images/img-profile-03.jpg'
import defaultAvatar from '../../../../../../commons/assets/images/default-avatar.jpeg'

import './StudentsList.css'

const StudentsList = ({ keyOp, items }) => {

  return (
    <div className="listStudents">
      {
        items.map(item => (
          <React.Fragment key={`sl_${item.id}`}>


            {/*ESTUDIANTE 1*/}
            <div className="accRow">
              <div className="accSelector">
                <div className="personalData">
                  <div className="imgProfile"><img src={defaultAvatar} alt=""/></div>
                  <p className="name">{item.name} {item.paternal}</p>
                </div>
                <p className="role">Administración</p>
              </div>

              <div className="accWrap">
                <StudentContainer keyId={`csl_${item.id}`}
                                  keyOp={keyOp}
                                  item={item}/>
              </div>
            </div>

            {/*ESTUDIANTE 1
            <div className="accRow">
              <div className="accSelector">
                <div className="personalData">
                  <div className="imgProfile"><img src={defaultAvatar} alt=""/></div>
                  <p className="name">Ana Fuentes</p>
                </div>
                <p className="role">Administración</p>
              </div>

              <div className="accWrap">
                <StudentContainer keyId="1"/>
              </div>
            </div>

            <div className="accRow">
              <div className="accSelector">
                <div className="personalData">
                  <div className="imgProfile"><img src={imgProfile02} alt=""/></div>
                  <p className="name">Miguel G.</p>
                </div>
                <p className="role">Sistemas</p>
              </div>
              <div className="accWrap">
                <StudentContainer keyId="2"/>
              </div>
            </div>

            <div className="accRow">
              <div className="accSelector">
                <div className="personalData">
                  <div className="imgProfile"><img src={imgProfile03} alt=""/></div>
                  <p className="name">Azael H.</p>
                </div>
                <p className="role">Sistemas</p>
              </div>
              <div className="accWrap">
                <StudentContainer keyId="3"/>
              </div>
            </div>

            <div className="accRow">
              <div className="accSelector">
                <div className="personalData">
                  <div className="imgProfile"><img src={imgProfile} alt=""/></div>
                  <p className="name">Diana P.</p>
                </div>
                <p className="role">Sistemas</p>
              </div>
              <div className="accWrap">
                <StudentContainer keyId="4"/>
              </div>
            </div>

            <div className="accRow">
              <div className="accSelector">
                <div className="personalData">
                  <div className="imgProfile"><img src={imgProfile} alt=""/></div>
                  <p className="name">Jaime H.</p>
                </div>
                <p className="role">Administración</p>
              </div>
              <div className="accWrap">
                <StudentContainer keyId="5"/>
              </div>
            </div>

            <div className="accRow">
              <div className="accSelector">
                <div className="personalData">
                  <div className="imgProfile"><img src={imgProfile02} alt=""/></div>
                  <p className="name">Miguel G.</p>
                </div>
                <p className="role">Sistemas</p>
              </div>
              <div className="accWrap">
                <StudentContainer keyId="6"/>
              </div>
            </div>

            <div className="accRow">
              <div className="accSelector">
                <div className="personalData">
                  <div className="imgProfile"><img src={imgProfile03} alt=""/></div>
                  <p className="name">Azael H.</p>
                </div>
                <p className="role">Sistemas</p>
              </div>
              <div className="accWrap">
                <StudentContainer keyId="7"/>
              </div>
            </div>

            <div className="accRow">
              <div className="accSelector">
                <div className="personalData">
                  <div className="imgProfile"><img src={imgProfile} alt=""/></div>
                  <p className="name">Diana P.</p>
                </div>
                <p className="role">Sistemas</p>
              </div>
              <div className="accWrap">
                <StudentContainer keyId="8"/>
              </div>
            </div>*/}
          </React.Fragment>
        ))
      }
    </div>
  )
}

export default StudentsList