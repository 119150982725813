import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import Steps from './Lecture/Steps/Steps'
import ClassInfo from './Lecture/ClassInfo/ClassInfo'
import ClassAdvance from './Lecture/ClassAdvance/ClassAdvance'
import CourseAdvance from './Lecture/CourseAdvance/CourseAdvance'
import Congrats from '../../../../../../commons/components/Congrats/Congrats'
import Footer from '../../../../../../commons/components/Footer/Footer'

import { selectSubjects } from '../../../../../../commons/redux/selectors/subject.selectors'

import styles from './MySubject.module.css'

const MySubject = (props) => {
  const params = useParams()
  const [subject, setSubject] = useState(null)
  const { subjectId } = params
  const subjects = useSelector(selectSubjects)

  useEffect(() => {
    const toFilterSubject = () => {
      const s = subjects && subjects.filter(subject => +subject.subject.id === +subjectId)
      setSubject(s[0])
    }

    toFilterSubject()
  }, [])

  return (
    <div className={styles.mainContainer}>
      {subject && (
        <>
          <Steps subjectName={subject.subject.name}
                 lessons={subject.subject.lessons}
                 teacher={subject.subject.teachers[0]}/>
          <ClassInfo syllabus={subject.subject.syllabus}/>
          <ClassAdvance/>
          <CourseAdvance/>
          <Congrats/>
          <Footer/>
        </>
      )}
    </div>
  )
}

export default MySubject
