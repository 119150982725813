import React, { useEffect, useState } from 'react'
import { Link, NavLink } from 'react-router-dom'

import usePrefix from '../../hooks/usePrefix'
import { ADMIN_ROLE, STUDENT_ROLE, TEACHER_ROLE } from '../../router/constants'

import logoNamibh from '../../assets/images/logo-namibh.svg'
import imgProfile03 from '../../assets/images/img-profile-03.jpg'

import $ from 'jquery'

import './Header.css'
import { useSession } from '../../hooks/useSession'
// import styles from './Header.module.css'

const Header = () => {
  const prefix = usePrefix()

  const session = useSession()

  const [user, setUser] = useState(JSON.parse(sessionStorage.getItem('_u')))

  const getNavItems = () => {
    switch (+user?.role.id) {
      case STUDENT_ROLE:
        return (
          <>
            <li>
              <NavLink to={`${prefix}/home`}>
                Escritorio
              </NavLink>
            </li>
            <li>
              <NavLink className="" to={`${prefix}/my-teachers`}>
                Mis profesores
              </NavLink>
            </li>
            <li>
              <NavLink className="" to={`${prefix}/my-courses`}>
                Mis cursos
              </NavLink>
            </li>
            <li>
              <NavLink className="" to={`${prefix}/my-devs`}>
                Mis devocionales
              </NavLink>
            </li>
          </>
        )
      case TEACHER_ROLE:
        return (
          <>
            <li>
              <NavLink to={`${prefix}/home`}>
                Escritorio
              </NavLink>
            </li>
            <li>
              <NavLink className="" to={`${prefix}/my-students`}>
                Mis alumnos
              </NavLink>
            </li>
            <li>
              <NavLink className="" to={`${prefix}/my-courses`}>
                Mis cursos
              </NavLink>
            </li>
            <li>
              <NavLink className="" to={`${prefix}/my-devs`}>
                Mis devocionales
              </NavLink>
            </li>
          </>
        )
      case ADMIN_ROLE:
      default:
        return (
          <>
            <li>
              <NavLink to={`${prefix}/home`}>
                Escritorio
              </NavLink>
            </li>
            <li>
              <NavLink className="" to={`${prefix}/my-console`}>
                Consola
              </NavLink>
            </li>
          </>
        )
    }
  }

  useEffect(() => {
    $('.hamburguer').on('click', function () {
      $(this).toggleClass('open')
      $(this).parent().find('.mainMenu').toggleClass('show')
    })
  }, [])

  return (
    <header>
      <div className="container">
        <a href="#" className="logoNamibh">
          <img src={logoNamibh} width="115" height="40" alt="Instituto Bíblico Hispano"/>
        </a>
        <nav className="mainMenu">
          <ul>
            {getNavItems()}
            <li className="userLink">
              <Link to="#"
                    onClick={() => {
                      // dispatch(doLogout())
                      console.log('LOGOUT')
                    }}
              >
                Cerrar sesión
              </Link>
            </li>
            <li className="userLink">
              <Link to="#"
                    onClick={() => {
                      // dispatch(doLogout())
                      console.log('OTRA')
                    }}
              >
                Otra
              </Link>
            </li>
          </ul>
        </nav>
        <div className="profile">
          <p>{user.name}<span className="role">Administrador</span></p>
          <div className="imgCont"><img src={imgProfile03}/>
            <div className="notNumber"><span>2</span></div>
            <div className="panel">
              <ul>
                <li>
                  <Link to="#"
                        onClick={async () => {
                          // dispatch(doLogout())
                          console.log('LOGOUT')
                          await session.logout()
                        }}
                  >
                    Cerrar sesión
                  </Link>
                </li>
                {/*<li>
                  <Link to="#"
                        onClick={() => {
                          // dispatch(doLogout())
                          console.log('OTRA')
                        }}
                  >
                    Otra
                  </Link>
                </li>*/}
              </ul>
            </div>
          </div>
        </div>
        <div className="hamburguer"><span>&nbsp;</span></div>
      </div>
    </header>
  )
}

export default Header
