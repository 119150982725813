import axiosClient from '../../network/apiClient'

import { USER_ENDPOINT } from '../../const'

export const fetchUsers = async () => {
  const token = sessionStorage.getItem('_k')
  const url = USER_ENDPOINT.USERS
  const method = 'get'
  const encryptKeys = null
  const headers = {
    // Authorization: `Bearer ${token}`
  }

  const params = {}
  // console.log(params)

  return await axiosClient({
    method,
    url,
    data: params,
    headers,
    encryptKeys
  })
}

export const saveUser = async (values) => {
  const url = USER_ENDPOINT.USERS
  const method = 'post'
  const encryptKeys = null
  const headers = {}

  const params = {
    ...values
  }
  // console.log(params)

  return await axiosClient({
    method,
    url,
    data: params,
    headers,
    encryptKeys
  })
}

export const updateUser = async (item) => {
  console.log(item)
  const url = `${USER_ENDPOINT.USERS}/${item.id}`
  const method = 'put'
  const encryptKeys = null
  const headers = {}

  const params = {
    ...item
  }
  // console.log(params)

  return await axiosClient({
    method,
    url,
    data: params,
    headers,
    encryptKeys
  })
}


/*
export const fetchUser = async (userId) => {
  const url = `${USER_ENDPOINT.GET_USERS}/${userId}`
  const method = 'get'
  const encryptKeys = null
  const headers = {}

  const params = {}
  // console.log(params)

  return await axiosClient({
    method,
    url,
    data: params,
    headers,
    encryptKeys
  })
}

export const deleteUser = async (userId) => {
  const url = '/usuarios/actualizar-estatus'
  const method = 'put'
  const encryptKeys = null
  const headers = {}

  const params = {
    id: userId
  }
  // console.log(params)

  return await axiosClient({
    method,
    url,
    data: params,
    headers,
    encryptKeys
  })
}
*/
