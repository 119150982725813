import { fetchPeriods, fetchPeriodsFromStudent, savePeriod, updatePeriod } from '../services/period.services'
import Swal from 'sweetalert2'

import { LOADER_OFF, LOADER_ON } from './auth.actions'
export const GET_ALL_PERIODS_INIT = 'GET_ALL_PERIODS_INIT'
export const GET_ALL_PERIODS_SUCCESS = 'GET_ALL_PERIODS_SUCCESS'
export const GET_ALL_PERIODS_ERROR = 'GET_ALL_PERIODS_ERROR'
export const GET_ALL_PERIODS_FROM_STUDENT_INIT = 'GET_ALL_PERIODS_FROM_STUDENT_INIT'
export const GET_ALL_PERIODS_FROM_STUDENT_SUCCESS = 'GET_ALL_PERIODS_FROM_STUDENT_SUCCESS'
export const GET_ALL_PERIODS_FROM_STUDENT_ERROR = 'GET_ALL_PERIODS_FROM_STUDENT_ERROR'
export const NEW_PERIOD_INIT = 'NEW_PERIOD_INIT'
export const NEW_PERIOD_SUCCESS = 'NEW_PERIOD_SUCCESS'
export const NEW_PERIOD_ERROR = 'NEW_PERIOD_ERROR'
export const GET_PERIOD_INIT = 'GET_PERIOD_INIT'
export const GET_PERIOD_SUCCESS = 'GET_PERIOD_SUCCESS'
export const GET_PERIOD_ERROR = 'GET_PERIOD_ERROR'
export const UPDATE_PERIOD_INIT = 'UPDATE_PERIOD_INIT'
export const UPDATE_PERIOD_SUCCESS = 'UPDATE_PERIOD_SUCCESS'
export const UPDATE_PERIOD_ERROR = 'UPDATE_PERIOD_ERROR'
export const DELETE_PERIOD_INIT = 'DELETE_PERIOD_INIT'
export const DELETE_PERIOD_SUCCESS = 'DELETE_PERIOD_SUCCESS'
export const DELETE_PERIOD_ERROR = 'DELETE_PERIOD_ERROR'

export function getPeriods() {
  return async (dispatch) => {
    try {
      dispatch({ type: LOADER_ON })
      dispatch({ type: GET_ALL_PERIODS_INIT })
      const result = await fetchPeriods()
      console.log(result)
      if (result.data) {
        dispatch({ type: LOADER_OFF })
        dispatch({ type: GET_ALL_PERIODS_SUCCESS, data: [...result.data.data] })
      } else {
        dispatch({ type: LOADER_OFF })
        dispatch({ type: GET_ALL_PERIODS_ERROR })
        await Swal.fire(
          'Advertencia',
          'Ha ocurrido algo inesperado, contacte a soporte',
          'warning'
        )
      }
    } catch (e) {
      dispatch({ type: LOADER_OFF })
      dispatch({ type: GET_ALL_PERIODS_ERROR })
    }
  }
}

export function newPeriod(values) {
  return async (dispatch) => {
    try {
      dispatch({ type: LOADER_ON })
      dispatch({ type: NEW_PERIOD_INIT })
      const result = await savePeriod(values)
      // console.log(result)
      dispatch({ type: NEW_PERIOD_SUCCESS, data: { ...result.data } })
      dispatch({ type: LOADER_OFF })
      await Swal.fire('Éxito', 'Operación se realizó con éxito', 'success')
    } catch (e) {
      console.log('newPeriod: error: ', e)
      dispatch({ type: NEW_PERIOD_ERROR })
      dispatch({ type: LOADER_OFF })
      await Swal.fire('Error', 'Ocurrió algo inesperado, contacte a soporte', 'error')
    }
  }
}

export function editPeriod(values) {
  return async (dispatch) => {
    try {
      dispatch({ type: LOADER_ON })
      dispatch({ type: UPDATE_PERIOD_INIT })
      const result = await updatePeriod(values)
      // console.log(result)
      dispatch({ type: UPDATE_PERIOD_SUCCESS, data: { ...result.data } })
      dispatch({ type: LOADER_OFF })
      await Swal.fire('Éxito', 'Operación se realizó con éxito', 'success')
    } catch (e) {
      console.log('editPeriod: error: ', e)
      dispatch({ type: UPDATE_PERIOD_ERROR })
      dispatch({ type: LOADER_OFF })
      await Swal.fire('Error', 'Ocurrió algo inesperado, contacte a soporte', 'error')
    }
  }
}

/*export function getPeriod(periodId) {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_PERIOD_INIT })
      const result = await fetchPeriod(periodId)
      // console.log(result)
      dispatch({ type: GET_PERIOD_SUCCESS, data: { ...result.data } })
    } catch (e) {
      console.log('retrievePeriod: error: ', e)
      dispatch({ type: GET_PERIOD_ERROR })
      await Swal.fire('Error', e.response.data.detalles[0], 'warning')
    }
  }
}

export function editPeriod(values) {
  return async (dispatch) => {
    try {
      dispatch({ type: UPDATE_PERIOD_INIT })
      const result = await updatePeriod(values)
      // console.log(result)
      dispatch({ type: UPDATE_PERIOD_SUCCESS, data: { ...result.data } })
    } catch (e) {
      console.log('editPeriod: error: ', e)
      dispatch({ type: UPDATE_PERIOD_ERROR })
      await Swal.fire('Error', e.response.data.detalles[0], 'warning')
    }
  }
}

export function removePeriod(periodId) {
  return async (dispatch) => {
    try {
      dispatch({ type: DELETE_PERIOD_INIT })
      const result = await deletePeriod(periodId)
      // console.log(result)
      dispatch({ type: DELETE_PERIOD_SUCCESS, data: { ...result.data } })
    } catch (e) {
      console.log('removePeriod: error: ', e)
      dispatch({ type: DELETE_PERIOD_ERROR })
      await Swal.fire('Error', e.response.data.detalles[0], 'warning')
    }
  }
}*/
