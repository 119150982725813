import React from 'react'
import { useDispatch } from 'react-redux'
import { ErrorMessage, Field, Form, Formik } from 'formik'

import { editUser, newUser } from '../../../../../../../commons/redux/actions/user.actions'

import $ from 'jquery'

import './UserForm.css'

const UserForm = ({ keyOp, item = null, setReload }) => {
  const dispatch = useDispatch()

  const regExpEMail = new RegExp('(?:[a-z0-9!#$%&\'*+/=?^_`{|}~-]+(?:\\.[a-z0-9!#$%&\'*+/=?^_`{|}~-]+)*)@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9])\\])')
  const regExpPwd = new RegExp('(?:[a-z0-9!#$%&*+=]){6,12}')
  const regExpAge = new RegExp('(?:[0-9])')

  const handleSubmit = async (values) => {
    console.log('handleSubmit: values: ', values)
    if (keyOp === 'add') {
      const addUser = async () => {
        // delete values.id
        $('.newRequest').slideToggle()
        await dispatch(newUser(values))
      }

      addUser().then(_ => {
        console.log('User added...')
        setReload(true)
      })
    } else if (keyOp === 'upd') {
      const updUser = async () => {
        await dispatch(editUser(values))

        /*if (grid) {
          const isActive = window.$('.is-active')
          isActive.removeClass('is-active')
          isActive.next().slideToggle()
        } else {

        }*/
      }

      updUser().then(_ => {
        console.log('User updated...')
        // setReload(true)
      })
    }
  }

  return (
    <>
      {/*
        {
            "name":"Otro",
            "paternal":"García",
            "maternal":"Martínez",
            "gender": "M",
            "age": 20,
            "phone": "5530555475",
            "image" : "https://img.freepik.com/vector-premium/perfil-avatar-hombre-icono-redondo_24640-14044.jpg",
            "username": "sinder_2005@hotmail.com",
            "password": "1234567890"
        }
      */}
      <Formik
        initialValues={{
          id: item ? item.id : 0,
          name: item ? item.name : '',
          paternal: item ? item.paternal : '',
          maternal: item ? item.maternal : '',
          gender: 'X',
          age: item ? item.age : 0,
          phone: '5550505050',
          image: 'https://img.freepik.com/vector-premium/perfil-avatar-hombre-icono-redondo_24640-14044.jpg',
          username: item ? item.username : '',
          password: item ? item.password : '',
          status: item ? item.status : 1
        }}
        validate={(myValues) => {
          console.log(myValues)
          let myErrors = {}
          console.log(myErrors)
          if (!myValues.name) {
            myErrors.name = 'Debes ingresar el nombre'
          }
          if (!myValues.paternal) {
            myErrors.paternal = 'Debes ingresar apellido paterno'
          }
          if (!myValues.maternal) {
            myErrors.maternal = 'Debes ingresar apellido materno'
          }
          if (!myValues.age) {
            myErrors.age = 'Debes ingresar edad'
          } else if (!regExpAge.test(myValues.age)) {
            myErrors.age = 'Debes ingresar una edad válida'
          }
          if (!myValues.username) {
            myErrors.username = 'Debes ingresar una cuenta e-mail'
          } else if (!regExpEMail.test(myValues.username)) {
            myErrors.username = 'Debes ingresar una cuenta e-mail válida'
          }
          if (myValues.password && !myValues.password) {
            myErrors.password = 'Debes ingresar una contraseña'
          } else if (myValues.password && !regExpPwd.test(myValues.password)) {
            myErrors.password = 'Debes ingresar una cuenta contraseña válida'
          }
          return myErrors
        }}
        onSubmit={async (myValues, { resetForm }) => {
          console.log('myValues: ', myValues)
          if (keyOp === 'add')
            resetForm()
          await handleSubmit(myValues)
        }}
      >
        {({ errors }) => (
          <Form className="userInfo large">
            <Field type="hidden" id="id" name="id" value="0"/>
            <div className="fieldset">
              <label>Cuenta</label>
              <div className="inputWrap">
                <Field className="rounded"
                       type="text"
                       id="username"
                       name="username"
                       placeholder=""
                />
                <ErrorMessage name="username" component={() => (<label className="error">{errors.username}</label>)}/>
              </div>
            </div>
            {keyOp === 'add' && (
              <div className="fieldset">
                <label>Contraseña</label>
                <div className="inputWrap">
                  <Field className="rounded"
                         type="text"
                         id="password"
                         name="password"
                         placeholder=""
                  />
                  <ErrorMessage name="password" component={() => (<label className="error">{errors.password}</label>)}/>
                </div>
              </div>
            )}
            <div className="fieldset">
              <label>Nombre(s)</label>
              <div className="inputWrap">
                <Field className="rounded"
                       type="text"
                       id="name"
                       name="name"
                       placeholder=""
                />
                <ErrorMessage name="name" component={() => (<label className="error">{errors.name}</label>)}/>
              </div>
            </div>
            <div className="fieldset">
              <label>Apellido Paterno</label>
              <div className="inputWrap">
                <Field className="rounded"
                       type="text"
                       id="paternal"
                       name="paternal"
                       placeholder=""
                />
                <ErrorMessage name="paternal" component={() => (<label className="error">{errors.paternal}</label>)}/>
              </div>
            </div>
            <div className="fieldset">
              <label>Apellido Materno</label>
              <div className="inputWrap">
                <Field className="rounded"
                       type="text"
                       id="maternal"
                       name="maternal"
                       placeholder=""
                />
                <ErrorMessage name="maternal" component={() => (<label className="error">{errors.maternal}</label>)}/>
              </div>
            </div>
            <div className="fieldset">
              <label>Edad</label>
              <div className="inputWrap">
                <Field className="rounded"
                       type="number"
                       step="1"
                       id="age"
                       name="age"
                       placeholder=""
                />
                <ErrorMessage name="age" component={() => (<label className="error">{errors.age}</label>)}/>
              </div>
            </div>

            {/*<div className="fieldset">
              <label>Edad</label>
              <div className="inputWrap">
                <input className="rounded" type="text"/>
              </div>
            </div>
            <div className="fieldset photoWrap">
              <label>Correo Electrónico</label>
              <div className="inputWrap">
                <input className="rounded" type="email"/>
              </div>
            </div>
            <div className="fieldset">
              <label>Fecha de nacimiento</label>
              <div className="inputWrap">
                <input className="rounded" type="text"/>
              </div>
            </div>
            <div className="fieldset">
              <label>Lugar de nacimiento</label>
              <div className="inputWrap">
                <input className="rounded" type="text"/>
              </div>
            </div>
            <div className="fieldset">
              <label>Sexo</label>
              <div className="inputWrap">
              <span className="select rounded">
                <select>
                  <option>Hombre</option>
                  <option>Mujer</option>
                </select>
              </span>
              </div>
            </div>
            <div className="fieldset">
              <label>Estado civil</label>
              <div className="inputWrap">
              <span className="select rounded">
                <select>
                  <option>Soltero</option>
                  <option>Divorciado</option>
                  <option>Viudo</option>
                  <option>Separado</option>
                  <option>Unión libre</option>
                </select>
              </span>
              </div>
            </div>
            <div className="fieldset photoWrap">
              <label>Nivel de estudios</label>
              <div className="inputWrap">
              <span className="select rounded">
                <select>
                  <option>Sin estudios</option>
                  <option>Primaria</option>
                  <option>Secundaria</option>
                  <option>Medio superior</option>
                  <option>Universidad</option>
                  <option>Maestría</option>
                  <option>Doctorado</option>
                </select>
              </span>
              </div>
            </div>
            <div className="fieldset">
              <label>Twitter</label>
              <div className="inputWrap">
                <input className="rounded" type="text"/>
              </div>
            </div>
            <div className="fieldset">
              <label>LinkedIn</label>
              <div className="inputWrap">
                <input className="rounded" type="text"/>
              </div>
            </div>
            <div className="fieldset">
              <label>Facebook</label>
              <div className="inputWrap">
                <input className="rounded" type="text"/>
              </div>
            </div>
            <div className="fieldset photoWrap">
              <label>Instagram</label>
              <div className="inputWrap">
                <input className="rounded" type="text"/>
              </div>
            </div>
            <div className="fieldset">
              <label>Iglesia</label>
              <div className="inputWrap">
                <input className="rounded" type="text"/>
              </div>
            </div>
            <div className="fieldset">
              <label>Pastor</label>
              <div className="inputWrap">
                <input className="rounded" type="text"/>
              </div>
            </div>
            <div className="fieldset">
              <label>Cargo/Titulo</label>
              <div className="inputWrap">
                <input className="rounded" type="text"/>
              </div>
            </div>
            <div className="fieldset">
              <label>Ciudad de residencia</label>
              <div className="inputWrap">
                <input className="rounded" type="text"/>
              </div>
            </div>
            <div className="fieldset">
              <label>Nacionalidad</label>
              <div className="inputWrap">
                <input className="rounded" type="text"/>
              </div>
            </div>
            <div className="fieldset photoWrap">
              <label>¿Ejerces algún ministerio?</label>
              <div className="inputWrap">
                <input className="rounded" type="text"/>
              </div>
            </div>
            <div className="fieldset textarea">
              <label className="complete">Redacta de forma breve y concisa tu testimonio</label>
              <textarea className="white"
                        placeholder="Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo"></textarea>
            </div>
            <div className="fieldset textarea">
              <label className="complete">¿Porqué desea estudiar en el Instituto Bíblico Hispano?</label>
              <textarea className="white"
                        placeholder="Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo"></textarea>
            </div>
            <div className="fieldset textarea">
              <label className="complete">¿Considera que la Biblia (66 libros) es la única e infalible regla de Fey
                conducta para la vida cristiana? ¿Por qué?</label>
              <textarea className="white"
                        placeholder="Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo"></textarea>
            </div>
            <div className="fieldset textarea">
              <label className="complete">Nombre, tipo de relación, teléfono y correo electrónico de una referencia
                personal.</label>
              <textarea className="white"
                        placeholder="Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo"></textarea>
            </div>
            <div className="fieldset textarea">
              <label className="complete">Nombre, tipo de relación, teléfono y correo electrónico de otra referencia
                personal.</label>
              <textarea className="white"
                        placeholder="Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo"></textarea>
            </div>
            <div className="fieldset textarea photoWrap">
              <label className="complete">CV</label>
              <textarea className="white"
                        placeholder="Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo"></textarea>
            </div>
            <h2 className="sectionHeader withElements plusMargin"><span>Referencias</span></h2>
            <div className="fieldset textarea">
              <input className="rounded" type="text"/>
            </div>
            <div className="fieldset textarea">
              <input className="rounded" type="text"/>
            </div>
            <div className="fieldset">
              <div className="inputWrapCheckbox">
                <span className="control"><input className="rounded" type="checkbox"/></span>
                <span className="checkbox">Entiendo que el envío de este formulario no garantiza mi ingreso a la institución y que mi información será tratada de forma personal y privada. Así mismo, autorizo que la Dirección de este Instituto se ponga en contacto con mis referencias ministeriales y personales para el seguimiento de esta solicitud.</span>
                <div className="error">Lorem ipsum dolor sit amet</div>
              </div>
            </div>*/}
            <div className="fieldset right">
              {keyOp === 'add' && (
                <a className="close" href="#" onClick={() => {
                  $('.newRequest').slideToggle()
                }}>Cerrar</a>
              )}
              <button className="btn purple" type="submit">Guardar</button>
            </div>
          </Form>
        )}
      </Formik>
    </>
  )
}

export default UserForm