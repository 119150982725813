import {
  GET_ALL_TEACHERS_ERROR,
  GET_ALL_TEACHERS_INIT,
  GET_ALL_TEACHERS_SUCCESS,
  NEW_TEACHER_INIT,
  NEW_TEACHER_SUCCESS,
  NEW_TEACHER_ERROR,
  UPDATE_TEACHER_INIT,
  UPDATE_TEACHER_SUCCESS,
  UPDATE_TEACHER_ERROR, newTeacher
} from '../actions/teacher.actions'

const initialState = {
  loading: false,
  error: null,
  newTeacher: null,
  teacher: null
}

export default (state = initialState, action) => {
  let descendingOrderedTeachers = []
  switch (action.type) {
    case GET_ALL_TEACHERS_INIT:
      return { ...state, loading: true, teachers: [] }
    case GET_ALL_TEACHERS_SUCCESS:
      descendingOrderedTeachers = [...action.data].sort((a, b) => b.id - a.id)
      return { ...state, teachers: descendingOrderedTeachers, loading: false }
    case GET_ALL_TEACHERS_ERROR:
      return { ...state, loading: false, error: action.error }
    case NEW_TEACHER_INIT:
      return { ...state, loading: true, teacher: {} }
    case NEW_TEACHER_SUCCESS:
      descendingOrderedTeachers = [...state.teachers, { ...action.data }].sort((a, b) => b.id - a.id)
      return { ...state, teacher: { ...action.data }, teachers: descendingOrderedTeachers, loading: false }
    case NEW_TEACHER_ERROR:
      return { ...state, loading: false, teacher: {}, error: action.error }
    case UPDATE_TEACHER_INIT:
      return { ...state, loading: true }
    case UPDATE_TEACHER_SUCCESS:
      const filterTeachers = state.teachers.filter(teacher => teacher.id != action.data.id)
      descendingOrderedTeachers = [...filterTeachers, { ...action.data }].sort((a, b) => b.id - a.id)
      return { ...state, loading: false, teachers: descendingOrderedTeachers }
    case UPDATE_TEACHER_ERROR:
      return { ...state, loading: false, error: action.error }
    default:
      return state
  }
}
