import React from 'react'

import CourseContainer from '../commons/CourseContainer/CourseContainer'

import imgCourseExample1 from '../../../../../../commons/assets/images/img-course-example-1.png'
import imgCourseExample2 from '../../../../../../commons/assets/images/img-course-example-2.png'
import imgCourseExample3 from '../../../../../../commons/assets/images/img-course-example-3.png'

import './CoursesGrid.css'

const CoursesGrid = () => {

  return (
    <dl className="gridTabCourses">

      {/*CURSO 1*/}
      <dt>
        <div className="userCard square">
          <div className="imgProfile">
            <img src={imgCourseExample1}/>
          </div>
          <p className="name">Básico</p>
          <p className="subject">15 Materias</p>
        </div>
      </dt>
      <dd>
        <CourseContainer keyId="1"/>
      </dd>

      {/*CURSO 2*/}
      <dt>
        <div className="userCard square">
          <div className="imgProfile">
            <img src={imgCourseExample2} alt=""/>
          </div>
          <p className="name">Básico</p>
          <p className="subject">15 Materias</p>
        </div>
      </dt>
      <dd>
        <CourseContainer keyId="2"/>
      </dd>

      {/*CURSO 3*/}
      <dt>
        <div className="userCard square">
          <div className="imgProfile">
            <img src={imgCourseExample3} alt=""/>
          </div>
          <p className="name">Básico</p>
          <p className="subject">15 Materias</p>
        </div>
      </dt>
      <dd>
        <CourseContainer keyId="3"/>
      </dd>

      {/*CURSO 4*/}
      <dt>
        <div className="userCard square">
          <div className="imgProfile">
            <img src={imgCourseExample1} alt=""/>
          </div>
          <p className="name">Básico</p>
          <p className="subject">15 Materias</p>
        </div>
      </dt>
      <dd>
        <CourseContainer keyId="4"/>
      </dd>

      {/*CURSO 5*/}
      <dt>
        <div className="userCard square">
          <div className="imgProfile">
            <img src={imgCourseExample2} alt=""/>
          </div>
          <p className="name">Básico</p>
          <p className="subject">15 Materias</p>
        </div>
      </dt>
      <dd>
        <CourseContainer keyId="5"/>
      </dd>

      {/*CURSO 6*/}
      <dt>
        <div className="userCard square">
          <div className="imgProfile">
            <img src={imgCourseExample3} alt=""/>
          </div>
          <p className="name">Básico</p>
          <p className="subject">15 Materias</p>
        </div>
      </dt>
      <dd>
        <CourseContainer keyId="6"/>
      </dd>

      {/*CURSO 7*/}
      <dt>
        <div className="userCard square">
          <div className="imgProfile">
            <img src={imgCourseExample1} alt=""/>
          </div>
          <p className="name">Básico</p>
          <p className="subject">15 Materias</p>
        </div>
      </dt>
      <dd>
        <CourseContainer keyId="7"/>
      </dd>

      {/*CURSO 8*/}
      <dt>
        <div className="userCard square">
          <div className="imgProfile">
            <img src={imgCourseExample2} alt=""/>
          </div>
          <p className="name">Básico</p>
          <p className="subject">15 Materias</p>
        </div>
      </dt>
      <dd>
        <CourseContainer keyId="8"/>
      </dd>

    </dl>
  )
}

export default CoursesGrid