import React, { useEffect } from 'react'
import { NavLink } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import usePrefix from '../../../../../commons/hooks/usePrefix'
import { getSubjectsFromStudent } from '../../../../../commons/redux/actions/subject.actions'
import { selectAuthUser } from '../../../../../commons/redux/selectors/auth.selectors'
import { selectSubjects } from '../../../../../commons/redux/selectors/subject.selectors'

import imgCourseExample from '../../../../../commons/assets/images/img-subject-example-1.jpg'
import imgProfile02 from '../../../../../commons/assets/images/img-profile-02.jpg'

import styles from './Subjects.module.css'

const Subjects = () => {
  const prefix = usePrefix()
  const dispatch = useDispatch()
  const userAuthSel = useSelector(selectAuthUser)

  const subjects = useSelector(selectSubjects)

  useEffect(() => {
    if (userAuthSel) {
      const retrieveSubjectsFromStudent = async () => {
        await dispatch(getSubjectsFromStudent(userAuthSel.id))
      }

      retrieveSubjectsFromStudent().then(_ => console.log('All Subjects are loaded...'))
    }
  }, [])

  return (
    <>
      <section className={styles.gridCourses}>
        <div className={styles.gridCoursesCont}>
          {subjects && subjects.map((s, idx) => {
              const subject = s.subject
              console.log(subject)
              const teacher = subject.teachers[0]
              return (
                <div key={`s_${subject.id}`}
                     className={styles.gridCourseElement}>
                  <NavLink className={styles.imgCont} to={`${prefix}/my-courses/my-subjects/${subject.id}`}>
                    <img src={subject.thumbnail} alt={`${subject.name}`}/>
                    <div className={styles.video}></div>
                  </NavLink>
                  <div className={styles.title}>
                    <p>{subject.name}</p>
                    <div className={styles.number}><span>1</span></div>
                  </div>
                  <div className={styles.person}>
                    <img src={imgProfile02} alt={`${teacher.name} ${teacher.paternal}`}/>
                    <p>{teacher.name} {teacher.paternal}</p>
                  </div>
                  <div className={styles.progress}>
                    <div className={styles.bar}><span style={{ width: '50%' }}></span></div>
                    <p className={styles.percentage}>50%</p>
                  </div>
                </div>
              )
            }
          )}
        </div>
        <div className="groupBtn">
          <a href="#" className="btn btnGris">Cargar más</a>
        </div>
      </section>
    </>
  )
}

export default Subjects
