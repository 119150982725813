import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import CourseForm from '../Courses/commons/CourseForm/CourseForm'
import CoursesGrid from './CoursesGrid/CoursesGrid'
import CoursesList from './CoursesList/CoursesList'
import { getPeriods } from '../../../../../commons/redux/actions/period.actions'
import { selectPeriod } from '../../../../../commons/redux/selectors/period.selectors'
import { getCourses } from '../../../../../commons/redux/actions/course.actions'

import $ from 'jquery'

import './Courses.css'

const Courses = () => {
  const dispatch = useDispatch()

  const [gridView, setGridView] = useState(true)
  // const { courses } = useSelector(selectCourse)
  const { periods } = useSelector(selectPeriod)
  console.log(periods)

  const toEnableGrid = () => {
    $('.gridTabCourses').gridtab({
      grid: 3,
      tabPadding: 0,
      borderWidth: 0,
      contentPadding: 0,
      responsive: [{
        breakpoint: 520,
        settings: {
          grid: 2
        }
      }]
    })

    $('.listUsers').responsiveTabs({
      startCollapsed: false,
      active: 0
    })

    $('.accRow .accSelector').on('click', function () {
      $(this).toggleClass('open')
      $(this).parent().find('.accWrap').slideToggle()
    })
  }

  useEffect(() => {
    const retrievePeriods = async () => {
      await dispatch(getPeriods())
    }

    retrievePeriods().then(_ => console.log('All Periods are loaded...'))
  }, [])

  useEffect(() => {
    const retrieveCourses = async () => {
      await dispatch(getCourses())
    }

    retrieveCourses().then(_ => {
      console.log('All Courses are loaded...')
      toEnableGrid()
    })
  }, [])

  return (
    <>
      <div className="consoleSection consoleHeader">
        <h2 className="sectionHeader withElements"><span>Cursos</span>
          <ul className="view">
            {/*<li>
              <a className="search" href="#" onClick={() => {
                $('.newSearch').slideToggle()
              }}>
              </Link>
            </li>*/}
            <li>
              <a className="create" href="#" onClick={() => {
                $('.newRequest').slideToggle()
              }}>
              </a>
            </li>
            <li>
              <a className={gridView ? 'viewList' : 'viewGrid'} href="#" onClick={
                () => setGridView(!gridView)
              }>
              </a>
            </li>
          </ul>
        </h2>
      </div>

      {/*FILTROS*/}
      {/*<div className="consoleClass">
        <div className="sectionSelect">
          <div className="gridClass">
            <div className="fieldset">
              <span className="select select-color square">
                <select className="color-filter">
                  <option>Matrícula</option>
                  <option>Clase 2019</option>
                  <option>Clase 2020</option>
                  <option>Clase 2021</option>
                </select>
              </span>
            </div>

            <div className="fieldset">
              <span className="select select-color-gray square">
                <select className="color-gray-filter">
                  <option>Estado</option>
                  <option>Avance de curso</option>
                  <option>Estatus de inscripción</option>
                  <option>Mas recientes</option>
                  <option>Mas antiguos</option>
                  <option>Alfabéticamente (A..Z)</option>
                </select>
              </span>
            </div>
          </div>
        </div>
      </div>*/}

      {/*BUSQUEDAS*/}
      {/*<div className="newSearch" style={{display: 'none'}}>
        <CourseSearch/>
      </div>*/}

      {/*NUEVO ALUMNO*/}
      <div className="newRequest" style={{ display: 'none' }}>
        <CourseForm keyOp="upd"
                    periods={periods}/>
      </div>

      <div className="consoleSection consoleUsers">
        <div className="consoleUsersList">

          {gridView
            ? <CoursesGrid/>
            : <CoursesList/>
          }

          {/*{courses && courses.length > 0 && (
            <>
              <div style={{ display: `${gridView ? 'block' : 'none'}` }}>
                <CoursesGrid keyOp="upd"
                              periods={periods}
                              items={courses}/>
              </div>
              <div style={{ display: `${!gridView ? 'block' : 'none'}` }}>
                <CoursesList keyOp="upd"
                              periods={periods}
                              items={courses}/>
              </div>
            </>
          )}*/}

        </div>
      </div>


    </>
  )
}

export default Courses
