import React from 'react'

import { VideoPlayer } from '../../../../../../../../commons/components/VideoPlayer/VideoPlayer'

import styles from './VideoClass.module.css'

const VideoClass = ({ currenLesson, lesson, nextLesson }) => {
  /*console.log(lesson)
  const videos = [
    'http://147.182.249.27/uploads/mujer.mp4',
    'http://147.182.249.27/uploads/viento.mp4',
    'http://147.182.249.27/uploads/metropoli.mp4'
  ]*/

  return (
    <section className={styles.courseVideoImg}>
      <VideoPlayer controls={true}
                   // loop
                   style={{ 'cursor': 'pointer' }}
                   playing={true}
                   autoPlay
                   // muted
                   // url={videos[currenLesson]}
                   url={lesson.video}
                   onReady={_ => console.log('OnReady...')}
                   onPlay={_ => console.log('OnPlay')}
                   onProgress={_ => console.log('OnProgress')}
                   onEnded={_ => nextLesson(currenLesson + 1)}
      />
      {/*<video autoPlay loop muted className={styles.video}>
        <source src="video/video.mp4" type="video/mp4"/>
      </video>*/}
    </section>
  )
}

export default VideoClass
