import {
  GET_ALL_ENROLLMENTS_ERROR,
  GET_ALL_ENROLLMENTS_FROM_STUDENT_ERROR,
  GET_ALL_ENROLLMENTS_FROM_STUDENT_INIT,
  GET_ALL_ENROLLMENTS_FROM_STUDENT_SUCCESS,
  GET_ALL_ENROLLMENTS_INIT,
  GET_ALL_ENROLLMENTS_SUCCESS,
  NEW_ENROLLMENT_ERROR,
  NEW_ENROLLMENT_INIT,
  NEW_ENROLLMENT_SUCCESS,
  UPDATE_ENROLLMENT_ERROR,
  UPDATE_ENROLLMENT_INIT,
  UPDATE_ENROLLMENT_SUCCESS
} from '../actions/enrollment.actions'

const initialState = {
  loading: false,
  error: null,
  newEnrollment: null,
  enrollment: null
}

export default (state = initialState, action) => {
  let descendingOrderedEnrollments = []
  switch (action.type) {
    case GET_ALL_ENROLLMENTS_INIT:
      return { ...state, loading: true, enrollments: [] }
    case GET_ALL_ENROLLMENTS_SUCCESS:
      descendingOrderedEnrollments = [...action.data].sort((a, b) => b.id - a.id)
      return { ...state, enrollments: descendingOrderedEnrollments, loading: false }
    case GET_ALL_ENROLLMENTS_ERROR:
      return { ...state, loading: false, error: action.error }

    case GET_ALL_ENROLLMENTS_FROM_STUDENT_INIT:
      return { ...state, loading: true, enrollments: [] }
    case GET_ALL_ENROLLMENTS_FROM_STUDENT_SUCCESS:
      descendingOrderedEnrollments = [...action.data].sort((a, b) => b.id - a.id)
      return { ...state, enrollments: descendingOrderedEnrollments, loading: false }
    case GET_ALL_ENROLLMENTS_FROM_STUDENT_ERROR:
      return { ...state, loading: false, error: action.error }

    case NEW_ENROLLMENT_INIT:
      return { ...state, loading: true, enrollment: {} }
    case NEW_ENROLLMENT_SUCCESS:
      descendingOrderedEnrollments = [...state.enrollments, { ...action.data }].sort((a, b) => b.id - a.id)
      return { ...state, enrollment: { ...action.data }, enrollments: descendingOrderedEnrollments, loading: false }
    case NEW_ENROLLMENT_ERROR:
      return { ...state, loading: false, enrollment: {}, error: action.error }
    case UPDATE_ENROLLMENT_INIT:
      return { ...state, loading: true }
    case UPDATE_ENROLLMENT_SUCCESS:
      const filterEnrollments = state.enrollments.filter(enrollment => enrollment.id != action.data.id)
      descendingOrderedEnrollments = [...filterEnrollments, { ...action.data }].sort((a, b) => b.id - a.id)
      return { ...state, loading: false, enrollments: descendingOrderedEnrollments }
    case UPDATE_ENROLLMENT_ERROR:
      return { ...state, loading: false, error: action.error }
    default:
      return state
  }
}
