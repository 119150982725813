import env from '../network/env'

export const PROXY_ENDPOINT = '/oauth2/v1/token'
export const KEYS_ENDPOINT = '/llaves/generar'
// export const RESOURCES_URL = `${env.RESOURCES_URL}`
export const API_URL = `${env.API_URL}`

export const USER_ENDPOINT = {
  // SESSION: '/scim/v2/token',
  // SESSION: `${RESOURCES_URL}/login`,
  SESSION: `${API_URL}/login`,
  // LOGIN: '',
  // LOGOUT: '',
  // PROFILE: '',
  // REFRESH_TOKEN: '/autenticacion',
  // FORGOT_PASSWORD: '/autenticacion',
  // RESET_PASSWORD: '/autenticacion',
  // CHANGE_PASSWORD: '/autenticacion',
  // NEW_USER: '/usuarios/registrar',
  // GET_USERS: '/usuarios',
  USERS: `${API_URL}/users`
}

export const TEACHER_ENDPOINT = {
  TEACHERS: `${API_URL}/teachers`
}

export const STUDENT_ENDPOINT = {
  STUDENTS: `${API_URL}/students`
}

export const SUBJECT_ENDPOINT = {
  SUBJECTS: `${API_URL}/subjects`
}

export const COURSE_ENDPOINT = {
  COURSES: `${API_URL}/courses`
}

export const PERIOD_ENDPOINT = {
  PERIODS: `${API_URL}/periods`
}

export const ENROLLMENT_ENDPOINT = {
  ENROLLMENTS: `${API_URL}/enrollments`
}
