import React from 'react'
import { NavLink } from 'react-router-dom'

import bgSemanal from '../../../../../commons/assets/images/bg-semanal.jpg'
import imgProfile02 from '../../../../../commons/assets/images/img-profile-02.jpg'

import styles from './Devotional.module.css'

const Devotional = ({ roleId = 1 }) => {

  return (
    <section style={{ backgroundImage: `url(${bgSemanal})` }} className={styles.devWeek}>
      {roleId && roleId === 1 && (
        <div className={styles.headerWithElement}>
          <h2 className="bigTitle">Devocionales</h2>
          <div className="element">
            <NavLink className={styles.addLink} to={`/new-devotional`}>
              Agregar Devocional
            </NavLink>
          </div>
        </div>
      )}
      <h2 className="sectionHeader lessMargin red">Devoción Semanal</h2>
      <p className={styles.bigTitle}>5 características de un líder efectivo.</p>
      <div className={styles.dataCourses}>
        <div className={styles.person}><img src={imgProfile02} alt="Rick Bowling"/>
          <p>Rick Bowling</p>
        </div>
        <p className={styles.concluded}>30 concluidos</p>
        <p className={styles.notConcluded}>70 por concluir</p>
      </div>
      <table width="100%" border="0" cellSpacing="0" cellPadding="0" className={styles.dataCoursesTable}>
        <thead>
        <tr>
          <th className={styles.title}>Pasados</th>
          <th className={styles.readed}><span>Leído</span></th>
          <th className={styles.expired}><span>Vencido</span></th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td>1. Litot Europa</td>
          <td className={styles.centered}>2</td>
          <td className={styles.centered}>88</td>
        </tr>
        <tr>
          <td>1. Litot Europa</td>
          <td className={styles.centered}>2</td>
          <td className={styles.centered}>88</td>
        </tr>
        <tr>
          <td>1. Litot Europa</td>
          <td className={styles.centered}>2</td>
          <td className={styles.centered}>88</td>
        </tr>
        <tr>
          <td>1. Litot Europa</td>
          <td className={styles.centered}>2</td>
          <td className={styles.centered}>88</td>
        </tr>
        <tr>
          <td>1. Litot Europa</td>
          <td className={styles.centered}>2</td>
          <td className={styles.centered}>88</td>
        </tr>
        <tr>
          <td>1. Litot Europa</td>
          <td className={styles.centered}>2</td>
          <td className={styles.centered}>88</td>
        </tr>
        </tbody>
      </table>
    </section>
  )
}

export default Devotional
