import React from 'react'

import imgProfile02 from '../../../../../../../../commons/assets/images/img-profile-02.jpg'

import styles from './SubjectInfo.module.css'

const SubjectInfo = ({ subjectName, lesson, teacher }) => {
  return (
    <section className={styles.subjectContainer}>
      <div className={styles.subjectName}>
        <h1>{subjectName}</h1>
        <h4>{lesson.name}</h4>
      </div>
      <div className={styles.person}>
        <img src={imgProfile02} alt={`${teacher.name} ${teacher.paternal}`}/>
        <p>{teacher.name} {teacher.paternal}</p>
      </div>
    </section>
  )
}

export default SubjectInfo
