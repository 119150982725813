import React from 'react'

import './StudentPayment.css'

const StudentPayment = () => {

  return (
    <div className="gridUsers">
      <div className="subjectCardsTitle">
        <h2 className="sectionHeader withElements"><span>Inscripción</span></h2>
      </div>
      <div className="subjectCard">
        <span className="inscriptionb">Curso básico</span>
        <span><p className="status2 pay">Pagado</p></span>
      </div>
      <div className="subjectCardsTitle">
        <h2 className="sectionHeader withElements"><span>Materias</span></h2>
      </div>
      <div className="subjectCard">
        <span className="subject">Homelítica</span>
        <span className="paymentandcredit">$100 USD</span>
        <span className="paymentandcredit">30 CR</span>
        <span><p className="status2 pay">Pagado</p></span>
        <span><p className="status2 approved">Aprobado</p></span>
      </div>
      <div className="subjectCard">
        <span className="subject">Teología</span>
        <span className="paymentandcredit">$80 USD</span>
        <span className="paymentandcredit">10 CR</span>
        <span><p className="status2 pay">Pagado</p></span>
        <span><p className="status2 debit">En curso</p></span>
      </div>
      <div className="subjectCard">
        <span className="subject">Hermenéutica</span>
        <span className="paymentandcredit">$120 USD</span>
        <span className="paymentandcredit">35 CR</span>
        <span><p className="status2 pay">Pagado</p></span>
        <span><p className="status2 standby">Pendiente</p></span>
      </div>
      <div className="subjectCard">
        <span className="subject">Antiguo testamento</span>
        <span className="paymentandcredit">$120 USD</span>
        <span className="paymentandcredit">12 CR</span>
        <span><p className="status2 pay">Pagado</p></span>
        <span><p className="status2 standby">Pendiente</p></span>
      </div>
      <div className="subjectCard">
        <span className="subject">Prefecía bíblica</span>
        <span className="paymentandcredit">$45 USD</span>
        <span className="paymentandcredit">5 CR</span>
        <span><p className="status2 approved">Pagar</p></span>
        <span><p className="status2 standby">Pendiente</p></span>
      </div>
      <div className="fieldset right">
        <button className="btn purple" type="submit">Guardar</button>
      </div>
    </div>
  )
}

export default StudentPayment