import React, { useState } from 'react'
import { ErrorMessage } from 'formik'
import axios from 'axios'

import imgProfile from '../../../../../../../commons/assets/images/img-profile.jpg'
import env from '../../../../../../../commons/network/env'

import './FileItem.css'

const FileItem = ({ label, name, setValue, errors }) => {

  const [file, setFile] = useState({})
  const [uploading, setUploading] = useState(false)

  const handleRemoveFileUploaded = (filename) => {
    console.log('handleRemoveFileUploaded: Remove file named: ', filename)
    /*axios
      .delete(`http://localhost:8085/upload?name=${filename}`)
      .then((res) => handleRemoveFileUploaded(filename))
      .catch((err) => console.error(err))*/
  }

  const handleFileUpload = (event) => {
    const file = event.target.files[0]
    if (!file) return
    setUploading(true)
    setValue(file.name)
    setFile(file)

    // UPLOAD FILE
    const formData = new FormData()
    formData.append('newFile', file, file.name)

    /*setTimeout(async () => {
      setUploading(false)
      setFile(file)
    }, 2000)*/

    const url = `${env.RESOURCES_URL}/image/upload`
    axios.post(url, formData)
      .then((response) => {
        console.log('handleFileUpload: response: 🐙🐙🐙🐙🐙 ', response)
        setUploading(false)
        setFile(file)
        setValue(response.data.data.url)
      })
      .catch((err) => {
        setUploading(false)
        console.error(err)
        handleRemoveFileUploaded(file.name)
      })
  }

  return (
    <>
      <div className="fieldset">
        <label>{label}</label>
        <div className="groupWrap">
          <div className="fakeImageInput rounded">
            <li className="file-item">
              <img src={imgProfile} alt=""/>
              <span>{file?.name}</span>
              <div className="actions">
                <i className={`${uploading ? 'fa fa-refresh fa-spin' : 'fa fa-times-circle'}`}
                   onClick={() => handleRemoveFileUploaded(file.name)}
                >
                </i>
              </div>
            </li>
          </div>
          <ErrorMessage name={name} component={() => (<label className="error">{errors}</label>)}/>
        </div>
      </div>
      <div className="fieldset">
        <label>&nbsp;</label>
        <div className="inputWrapUpload">
          <input type="file"
                 id={name}
                 name={name}
                 onChange={handleFileUpload}/>
          <button>
            <i className="fa fa-plus"></i> Cargar archivo...
          </button>
        </div>
      </div>
    </>
  )
}

export default FileItem
