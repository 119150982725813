// import Cookie from 'js-cookie'

// import { LOGIN_INIT } from '../redux/actions/auth.actions'

export const decodeJwt = (jwt) => {
	const base64Url = jwt.split('.')[1]

	if (!base64Url) return null

	const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
	const jwtPayload = decodeURIComponent(
		atob(base64)
			.split('')
			.map(function (c) {
				return `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`
			})
			.join(''),
	)

	return jwtPayload
}

export const getExpireDate = (jwt) => {
	const decodedJwt = decodeJwt(jwt)

	const parsedDecodedJwt = JSON.parse(decodedJwt)
	const { exp } = parsedDecodedJwt
	const expireDate = new Date(exp * 1000)

	return expireDate
}

export const getJwtRemainingTimeToExpire = (jwt) => {
	const actualDate = Date.now()
	const expireDate = getExpireDate(jwt)
	return Math.floor((expireDate - actualDate) / 1000)
	// return remainingTimeInSeconds
}

/*export const storeJwtAndRenew = (jwt, renew) => {
  const jwtExpireDate = getExpireDate(jwt)
  const renewExpireDate = getExpireDate(renew)
  // Cookie.set('jwt', jwt, { expires: new Date(jwtExpireDate), secure: true })
  // Cookie.set('renew', renew, { expires: new Date(renewExpireDate), secure: true })
}*/

export const removeDataInStorage = () => {
	// dispatch({ type: LOGIN_INIT })
	localStorage.removeItem('_m')
	localStorage.removeItem('_p')
	localStorage.removeItem('_sa')
	// localStorage.removeItem('_fc')

	sessionStorage.removeItem('u')
	sessionStorage.removeItem('_unknown')
	sessionStorage.removeItem('_okta_auth')
	sessionStorage.removeItem('dataUser')
	// sessionStorage.removeItem('_k')
	// sessionStorage.removeItem('_e')
	// Cookie.remove('jwt')
	// Cookie.remove('renew')
}
