import { fetchUsers, saveUser, updateUser } from '../services/user.services'
import Swal from 'sweetalert2'

import { LOADER_OFF, LOADER_ON } from './auth.actions'
export const GET_ALL_USERS_INIT = 'GET_ALL_USERS_INIT'
export const GET_ALL_USERS_SUCCESS = 'GET_ALL_USERS_SUCCESS'
export const GET_ALL_USERS_ERROR = 'GET_ALL_USERS_ERROR'
export const NEW_USER_INIT = 'NEW_USER_INIT'
export const NEW_USER_SUCCESS = 'NEW_USER_SUCCESS'
export const NEW_USER_ERROR = 'NEW_USER_ERROR'
export const GET_USER_INIT = 'GET_USER_INIT'
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS'
export const GET_USER_ERROR = 'GET_USER_ERROR'
export const UPDATE_USER_INIT = 'UPDATE_USER_INIT'
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS'
export const UPDATE_USER_ERROR = 'UPDATE_USER_ERROR'
export const DELETE_USER_INIT = 'DELETE_USER_INIT'
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS'
export const DELETE_USER_ERROR = 'DELETE_USER_ERROR'

export function getUsers() {
  return async (dispatch) => {
    try {
      dispatch({ type: LOADER_ON })
      dispatch({ type: GET_ALL_USERS_INIT })
      const result = await fetchUsers()
      console.log(result)
      if (result.data?.data) {
        dispatch({ type: LOADER_OFF })
        dispatch({ type: GET_ALL_USERS_SUCCESS, data: [...result.data?.data] })
      } else {
        dispatch({ type: LOADER_OFF })
        dispatch({ type: GET_ALL_USERS_ERROR })
        await Swal.fire(
          'Advertencia',
          'Ha ocurrido algo inesperado, contacte a soporte',
          'warning'
        )
      }
    } catch (e) {
      dispatch({ type: LOADER_OFF })
      dispatch({ type: GET_ALL_USERS_ERROR })

      /*const sessionActive =
        e.response.data.detalles[0] ===
        'El usuario cuenta con una sesion activa, puedes intentar nuevamente despúes de 10 minutos.'
      if (sessionActive) {
        Swal.fire({
          title: 'Error',
          text: e.response.data.detalles[0],
          confirmButtonText: 'Aceptar'
        }).then(async (result) => {
          if (result.isConfirmed) {
            dispatch({ type: GET_ALL_USERS_ERROR })
          }
        })
      } else {
        await Swal.fire('Error', e.response.data.detalles[0], 'warning')
      }*/
    }
  }
}

export function newUser(values) {
  return async (dispatch) => {
    try {
      dispatch({ type: LOADER_ON })
      dispatch({ type: NEW_USER_INIT })
      const result = await saveUser(values)
      // console.log(result)
      dispatch({ type: NEW_USER_SUCCESS, data: { ...result.data.data } })
      dispatch({ type: LOADER_OFF })
      await Swal.fire('Éxito', 'Operación se realizó con éxito', 'success')
    } catch (e) {
      dispatch({ type: NEW_USER_ERROR })
      dispatch({ type: LOADER_OFF })
      console.log('newUser: error: ', e.response.data)
      // await Swal.fire('Error', 'Ocurrió algo inesperado, contacte a soporte', 'error')
      await Swal.fire('Error', e.response.data.metadata.message, 'error')
    }
  }
}

export function editUser(values) {
  return async (dispatch) => {
    try {
      dispatch({ type: LOADER_ON })
      dispatch({ type: UPDATE_USER_INIT })
      const result = await updateUser(values)
      // console.log(result)
      dispatch({ type: UPDATE_USER_SUCCESS, data: { ...result.data.data } })
      dispatch({ type: LOADER_OFF })
      await Swal.fire('Éxito', 'Operación se realizó con éxito', 'success')
    } catch (e) {
      dispatch({ type: UPDATE_USER_ERROR })
      dispatch({ type: LOADER_OFF })
      console.log('editUser: error: ', e.response.data)
      // await Swal.fire('Error', 'Ocurrió algo inesperado, contacte a soporte', 'error')
      await Swal.fire('Error', e.response.data.metadata.message, 'error')
    }
  }
}

/*export function getUser(userId) {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_USER_INIT })
      const result = await fetchUser(userId)
      // console.log(result)
      dispatch({ type: GET_USER_SUCCESS, data: { ...result.data } })
    } catch (e) {
      console.log('retrieveUser: error: ', e)
      dispatch({ type: GET_USER_ERROR })
      await Swal.fire('Error', e.response.data.detalles[0], 'warning')
    }
  }
}

export function editUser(values) {
  return async (dispatch) => {
    try {
      dispatch({ type: UPDATE_USER_INIT })
      const result = await updateUser(values)
      // console.log(result)
      dispatch({ type: UPDATE_USER_SUCCESS, data: { ...result.data } })
    } catch (e) {
      console.log('editUser: error: ', e)
      dispatch({ type: UPDATE_USER_ERROR })
      await Swal.fire('Error', e.response.data.detalles[0], 'warning')
    }
  }
}

export function removeUser(userId) {
  return async (dispatch) => {
    try {
      dispatch({ type: DELETE_USER_INIT })
      const result = await deleteUser(userId)
      // console.log(result)
      dispatch({ type: DELETE_USER_SUCCESS, data: { ...result.data } })
    } catch (e) {
      console.log('removeUser: error: ', e)
      dispatch({ type: DELETE_USER_ERROR })
      await Swal.fire('Error', e.response.data.detalles[0], 'warning')
    }
  }
}*/
