import React, { useEffect } from 'react'

import imgProfile02 from '../../../../../commons/assets/images/img-profile-02.jpg'
import imgProfile03 from '../../../../../commons/assets/images/img-profile-03.jpg'
import iconBadgeFacebook from '../../../../../commons/assets/images/icon-badge-facebook.svg'
import iconBadgeTwitter from '../../../../../commons/assets/images/icon-badge-twitter.svg'
import iconBadgeInstagram from '../../../../../commons/assets/images/icon-badge-instagram.svg'

import $ from 'jquery'

import './MyTeachers.css'

const MyTeachers = () => {

  useEffect(() => {
    $('.gridTabCourses').gridtab({
      grid: 3,
      tabPadding: 0,
      borderWidth: 0,
      contentPadding: 0,
      responsive: [{
        breakpoint: 520,
        settings: {
          grid: 2
        }
      }]
    })
  }, [])

  return (
    <section className="adminConsole">
      <div className="mainStudentConsole">
        <div className="consoleSection consoleUsers">
          <div className="consoleUsersList">
            <dl className="gridTabCourses">
              <dt>
                <div className="profileGrid threeCol">
                  <div className="profileCol">
                    <div className="imgContTeacher">
                      <img src={imgProfile03}/>
                    </div>
                    <p className="name">Jaime Hernández</p>
                    <p className="role">Teología</p>
                  </div>
                </div>
              </dt>
              <dd>
                <div className="teacherInfo">
                  <div className="imgProfile">
                    <img src={imgProfile03}/>
                  </div>
                  <div className="teacherInfoText">
                    <p className="name">Jaime Hernández</p>
                    <p className="church">PASTOR FWB ELKHART, IN</p>
                    <p className="iconRSS">
                      <img className="imgRSS" src={iconBadgeFacebook}/>
                      <img className="imgRSS" src={iconBadgeTwitter}/>
                      <img className="imgRSS" src={iconBadgeInstagram}/>
                    </p>
                    <p className="paragraph">
                      <span>
                        Lorem ipsum dolor sit amet, consectetuer adipiscing
                        elit. Aenean commodo ligula eget dolor. Aenean
                        massa. Cum sociis natoque penatibus et magnis dis
                        parturient montes, nascetur ridiculus mus.
                      </span>
                    </p>
                    <p className="paragraph">
                      <span>
                        Donec quam felis, ultricies nec, pellentesque eu,
                        pretium quis, sem. Nulla consequat massa quis enim.
                        Donec pede justo, fringilla vel, aliquet nec,
                        vulputate eget, arcu. In enim justo, rhoncus ut,
                        imperdiet a, venenatis vitae, justo. Nullam dictum
                        felis eu pede mollis pretium. Integer tincidunt.
                        Cras dapibus. Vivamus elementum semper nisi. Aenean
                        vulputate eleifend
                      </span>
                    </p>
                  </div>
                </div>
              </dd>
              <dt>
                <div className="profileGrid threeCol">
                  <div className="profileCol">
                    <div className="imgContTeacher">
                      <img src={imgProfile02}/>
                    </div>
                    <p className="name">Alfredo Botello</p>
                    <p className="role">Homilética</p>
                  </div>
                </div>
              </dt>
              <dd>
                <div className="teacherInfo">
                  <div className="imgProfile">
                    <img src={imgProfile02}/>
                  </div>
                  <div className="teacherInfoText">
                    <p className="name">Alfredo Botello</p>
                    <p className="church">PASTOR FWB ELKHART, IN</p>
                    <p className="iconRSS">
                      <img className="imgRSS" src={iconBadgeFacebook}/>
                      <img className="imgRSS" src={iconBadgeTwitter}/>
                      <img className="imgRSS" src={iconBadgeInstagram}/>
                    </p>
                    <p className="paragraph">
                      <span>
                        Lorem ipsum dolor sit amet, consectetuer adipiscing
                        elit. Aenean commodo ligula eget dolor. Aenean
                        massa. Cum sociis natoque penatibus et magnis dis
                        parturient montes, nascetur ridiculus mus.
                      </span>
                    </p>
                    <p className="paragraph">
                      <span>
                        Donec quam felis, ultricies nec, pellentesque eu,
                        pretium quis, sem. Nulla consequat massa quis enim.
                        Donec pede justo, fringilla vel, aliquet nec,
                        vulputate eget, arcu. In enim justo, rhoncus ut,
                        imperdiet a, venenatis vitae, justo. Nullam dictum
                        felis eu pede mollis pretium. Integer tincidunt.
                        Cras dapibus. Vivamus elementum semper nisi. Aenean
                        vulputate eleifend
                      </span>
                    </p>
                  </div>
                </div>
              </dd>
              <dt>
                <div className="profileGrid threeCol">
                  <div className="profileCol">
                    <div className="imgContTeacher">
                      <img src={imgProfile03}/>
                    </div>
                    <p className="name">Rufo García</p>
                    <p className="role">Antiguo Testamento</p>
                  </div>
                </div>
              </dt>
              <dd>
                <div className="teacherInfo">
                  <div className="imgProfile">
                    <img src={imgProfile02}/>
                  </div>
                  <div className="teacherInfoText">
                    <p className="name">Rufo García</p>
                    <p className="church">PASTOR FWB ELKHART, IN</p>
                    <p className="iconRSS">
                      <img className="imgRSS" src={iconBadgeFacebook}/>
                      <img className="imgRSS" src={iconBadgeTwitter}/>
                      <img className="imgRSS" src={iconBadgeInstagram}/>
                    </p>
                    <p className="paragraph">
                      <span>
                        Lorem ipsum dolor sit amet, consectetuer adipiscing
                        elit. Aenean commodo ligula eget dolor. Aenean
                        massa. Cum sociis natoque penatibus et magnis dis
                        parturient montes, nascetur ridiculus mus.
                      </span>
                    </p>
                    <p className="paragraph">
                      <span>
                        Donec quam felis, ultricies nec, pellentesque eu,
                        pretium quis, sem. Nulla consequat massa quis enim.
                        Donec pede justo, fringilla vel, aliquet nec,
                        vulputate eget, arcu. In enim justo, rhoncus ut,
                        imperdiet a, venenatis vitae, justo. Nullam dictum
                        felis eu pede mollis pretium. Integer tincidunt.
                        Cras dapibus. Vivamus elementum semper nisi. Aenean
                        vulputate eleifend
                      </span>
                    </p>
                  </div>
                </div>
              </dd>
              <dt>
                <div className="profileGrid threeCol">
                  <div className="profileCol">
                    <div className="imgContTeacher">
                      <img src={imgProfile03}/>
                    </div>
                    <p className="name">Jaime Hernández</p>
                    <p className="role">Teología</p>
                  </div>
                </div>
              </dt>
              <dd>
                <div className="teacherInfo">
                  <div className="imgProfile">
                    <img src={imgProfile03}/>
                  </div>
                  <div className="teacherInfoText">
                    <p className="name">Jaime Hernández</p>
                    <p className="church">PASTOR FWB ELKHART, IN</p>
                    <p className="iconRSS">
                      <img className="imgRSS" src={iconBadgeFacebook}/>
                      <img className="imgRSS" src={iconBadgeTwitter}/>
                      <img className="imgRSS" src={iconBadgeInstagram}/>
                    </p>
                    <p className="paragraph">
                      <span>
                        Lorem ipsum dolor sit amet, consectetuer adipiscing
                        elit. Aenean commodo ligula eget dolor. Aenean
                        massa. Cum sociis natoque penatibus et magnis dis
                        parturient montes, nascetur ridiculus mus.
                      </span>
                    </p>
                    <p className="paragraph">
                      <span>
                        Donec quam felis, ultricies nec, pellentesque eu,
                        pretium quis, sem. Nulla consequat massa quis enim.
                        Donec pede justo, fringilla vel, aliquet nec,
                        vulputate eget, arcu. In enim justo, rhoncus ut,
                        imperdiet a, venenatis vitae, justo. Nullam dictum
                        felis eu pede mollis pretium. Integer tincidunt.
                        Cras dapibus. Vivamus elementum semper nisi. Aenean
                        vulputate eleifend
                      </span>
                    </p>
                  </div>
                </div>
              </dd>
              <dt>
                <div className="profileGrid threeCol">
                  <div className="profileCol">
                    <div className="imgContTeacher">
                      <img src={imgProfile02}/>
                    </div>
                    <p className="name">Alfredo Botello</p>
                    <p className="role">Homilética</p>
                  </div>
                </div>
              </dt>
              <dd>
                <div className="teacherInfo">
                  <div className="imgProfile">
                    <img src={imgProfile02}/>
                  </div>
                  <div className="teacherInfoText">
                    <p className="name">Alfredo Botello</p>
                    <p className="church">PASTOR FWB ELKHART, IN</p>
                    <p className="iconRSS">
                      <img className="imgRSS" src={iconBadgeFacebook}/>
                      <img className="imgRSS" src={iconBadgeTwitter}/>
                      <img className="imgRSS" src={iconBadgeInstagram}/>
                    </p>
                    <p className="paragraph">
                      <span>
                        Lorem ipsum dolor sit amet, consectetuer adipiscing
                        elit. Aenean commodo ligula eget dolor. Aenean
                        massa. Cum sociis natoque penatibus et magnis dis
                        parturient montes, nascetur ridiculus mus.
                      </span>
                    </p>
                    <p className="paragraph">
                      <span>
                        Donec quam felis, ultricies nec, pellentesque eu,
                        pretium quis, sem. Nulla consequat massa quis enim.
                        Donec pede justo, fringilla vel, aliquet nec,
                        vulputate eget, arcu. In enim justo, rhoncus ut,
                        imperdiet a, venenatis vitae, justo. Nullam dictum
                        felis eu pede mollis pretium. Integer tincidunt.
                        Cras dapibus. Vivamus elementum semper nisi. Aenean
                        vulputate eleifend
                      </span>
                    </p>
                  </div>
                </div>
              </dd>
              <dt>
                <div className="profileGrid threeCol">
                  <div className="profileCol">
                    <div className="imgContTeacher">
                      <img src={imgProfile03}/>
                    </div>
                    <p className="name">Rufo García</p>
                    <p className="role">Antiguo Testamento</p>
                  </div>
                </div>
              </dt>
              <dd>
                <div className="teacherInfo">
                  <div className="imgProfile">
                    <img src={imgProfile02}/>
                  </div>
                  <div className="teacherInfoText">
                    <p className="name">Rufo García</p>
                    <p className="church">PASTOR FWB ELKHART, IN</p>
                    <p className="iconRSS">
                      <img className="imgRSS" src={iconBadgeFacebook}/>
                      <img className="imgRSS" src={iconBadgeTwitter}/>
                      <img className="imgRSS" src={iconBadgeInstagram}/>
                    </p>
                    <p className="paragraph">
                      <span>
                        Lorem ipsum dolor sit amet, consectetuer adipiscing
                        elit. Aenean commodo ligula eget dolor. Aenean
                        massa. Cum sociis natoque penatibus et magnis dis
                        parturient montes, nascetur ridiculus mus.
                      </span>
                    </p>
                    <p className="paragraph">
                      <span>
                        Donec quam felis, ultricies nec, pellentesque eu,
                        pretium quis, sem. Nulla consequat massa quis enim.
                        Donec pede justo, fringilla vel, aliquet nec,
                        vulputate eget, arcu. In enim justo, rhoncus ut,
                        imperdiet a, venenatis vitae, justo. Nullam dictum
                        felis eu pede mollis pretium. Integer tincidunt.
                        Cras dapibus. Vivamus elementum semper nisi. Aenean
                        vulputate eleifend
                      </span>
                    </p>
                  </div>
                </div>
              </dd>
            </dl>
          </div>
        </div>
        <div className="groupBtn">
          <a href="#" className="btn btnGris">Cargar más</a>
        </div>
      </div>
    </section>
  )
}

export default MyTeachers
