import React from 'react'

import './StudentSearch.css'

const StudentSearch = () => {

  return (
    <form className="userInfo" action="src/containers/protected/administrator/Console/Students/commons/StudentSearch/StudentSearch">
      <div className="fieldset">
        <div className="inputWrap">
          <input className="rounded" placeholder="Buscar alumno" type="text"/>
        </div>
      </div>
      <div className="fieldset right">
        <a className="close" href="src/containers/protected/administrator/Console/Students/commons/StudentSearch/StudentSearch#">Cerrar</a>
        <button className="btn orange" type="submit">Ver Todos</button>
        <button className="btn purple" type="submit">Guardar</button>
      </div>
    </form>
  )
}

export default StudentSearch