import React from 'react'
import ReactPlayer from 'react-player'

import styles from './VideoPlayer.module.css'

export const VideoPlayer = (props) => {
  return (
    <div className={styles['player-wrapper']}>
      <ReactPlayer
        {...props}
        className={styles['react-player']}
        width="100%"
        height="100%"
      />
    </div>
  )
}
