import React from 'react'

import TeacherForm from '../TeacherForm/TeacherForm'

import './TeacherContainer.css'

const TeacherContainer = ({ keyId = 0, keyOp, item }) => {

  return (
    <TeacherForm keyOp={keyOp}
                 item={item}/>
  )

}

export default TeacherContainer