import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import StudentSearch from './commons/StudentSearch/StudentSearch'
import StudentForm from './commons/StudentForm/StudentForm'
import StudentsGrid from './StudentsGrid/StudentsGrid'
import StudentsList from './StudentsList/StudentsList'

import { selectStudent } from '../../../../../commons/redux/selectors/student.selectors'
import { getStudents } from '../../../../../commons/redux/actions/student.actions'

import $ from 'jquery'

import './Students.css'

const Students = () => {
  const dispatch = useDispatch()
  const { students } = useSelector(selectStudent)
  console.log(students)

  const [gridView, setGridView] = useState(true)

  const toEnableGrid = () => {
    $('.gridUsers').gridtab({
      grid: 4,
      tabPadding: 0,
      borderWidth: 0,
      contentPadding: 0,
      responsive: [
        {
          breakpoint: 760,
          settings: {
            grid: 3
          }
        },
        {
          breakpoint: 520,
          settings: {
            grid: 2
          }
        }
      ]
    })

    $('.respTabsProfile').responsiveTabs({
      startCollapsed: false,
      active: 0
    })

    $('.accRow .accSelector').on('click', function () {
      $(this).toggleClass('open')
      $(this).parent().find('.accWrap').slideToggle()
    })
  }

  useEffect(() => {
    const retrieveStudents = async () => {
      await dispatch(getStudents())
    }

    retrieveStudents().then(_ => {
      console.log('All Students are loaded...')
      toEnableGrid()
    })
  }, [])

  return (
    <>
      <div className="consoleSection consoleHeader">
        <h2 className="sectionHeader withElements"><span>Alumnos</span>
          <ul className="view">
            <li>
              <a className="search" href="#" onClick={() => {
                $('.newSearch').slideToggle()
              }}>
              </a>
            </li>
            <li>
              <a className="create" href="#" onClick={() => {
                $('.newRequest').slideToggle()
              }}>
              </a>
            </li>
            <li>
              <a className={gridView ? 'viewList' : 'viewGrid'} href="#" onClick={
                () => setGridView(!gridView)
              }>
              </a>
            </li>
          </ul>
        </h2>
      </div>

      {/*FILTROS*/}
      <div className="consoleClass">
        <div className="sectionSelect">
          <div className="gridClass">
            <div className="fieldset">
              <span className="select select-color square">
                <select className="color-filter">
                  <option>Matrícula</option>
                  <option>Clase 2019</option>
                  <option>Clase 2020</option>
                  <option>Clase 2021</option>
                </select>
              </span>
            </div>

            <div className="fieldset">
              <span className="select select-color-gray square">
                <select className="color-gray-filter">
                  <option>Estado</option>
                  <option>Avance de curso</option>
                  <option>Estatus de inscripción</option>
                  <option>Mas recientes</option>
                  <option>Mas antiguos</option>
                  <option>Alfabéticamente (A..Z)</option>
                </select>
              </span>
            </div>
          </div>
        </div>
      </div>

      {/*BUSQUEDAS*/}
      <div className="newSearch" style={{ display: 'none' }}>
        <StudentSearch/>
      </div>

      {/*NUEVO ALUMNO*/}
      <div className="newRequest" style={{ display: 'none' }}>
        <StudentForm keyOp="add"/>
      </div>

      <div className="consoleSection consoleUsers">
        <div className="consoleUsersList">

          {students && students.length > 0 && (
            <>
              <div style={{ display: `${gridView ? 'block' : 'none'}` }}>
                <StudentsGrid keyOp="upd"
                              items={students}/>
              </div>
              <div style={{ display: `${!gridView ? 'block' : 'none'}` }}>
                <StudentsList keyOp="upd"
                              items={students}/>
              </div>
            </>
          )}
        </div>
      </div>


    </>
  )
}

export default Students
