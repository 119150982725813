import React from 'react'

// import imgSubjectExample1 from '../../../../../../commons/assets/images/img-subject-example-1.jpg'
// import imgSubjectExample2 from '../../../../../../commons/assets/images/img-subject-example-2.jpg'
// import imgSubjectExample3 from '../../../../../../commons/assets/images/img-subject-example-3.jpg'
// import imgSubjectExample4 from '../../../../../../commons/assets/images/img-subject-example-4.jpg'
// import imgSubjectExample5 from '../../../../../../commons/assets/images/img-subject-example-5.jpg'
// import imgSubjectExample6 from '../../../../../../commons/assets/images/img-subject-example-6.jpg'

import SubjectContainer from '../commons/SubjectContainer/SubjectContainer'

import './SubjectsList.css'

const SubjectsList = ({ keyOp, teachers, items }) => {

  return (
    <div className="listUsers">
      {
        items.map(item => (
          <React.Fragment key={`sbl_${item.subject.id}`}>
            <div className="accRow">
              <div className="accSelector">
                <div className="personalData">
                  <div className="imgProfile"><img src={item.subject.thumbnail} alt=""/></div>
                  <p className="name">{item.subject.name}</p>
                </div>
                <p className="role">Curso Básico</p>
              </div>

              <div className="accWrap">
                <SubjectContainer keyOp={keyOp}
                                  teachers={teachers}
                                  item={item.subject}/>
              </div>
            </div>
          </React.Fragment>
        ))
      }

      {/*ESTUDIANTE 1
      <div className="accRow">
        <div className="accSelector">
          <div className="personalData">
            <div className="imgProfile"><img src={imgSubjectExample1} alt=""/></div>
            <p className="name">Hermenéutica</p>
          </div>
          <p className="role">Curso Básico</p>
        </div>

        <div className="accWrap">
          <EnrollmentContainer keyId="1"/>
        </div>
      </div>

      <div className="accRow">
        <div className="accSelector">
          <div className="personalData">
            <div className="imgProfile"><img src={imgSubjectExample2} alt=""/></div>
            <p className="name">Teología</p>
          </div>
          <p className="role">Curso Básico</p>
        </div>
        <div className="accWrap">
          <EnrollmentContainer keyId="2"/>
        </div>
      </div>

      <div className="accRow">
        <div className="accSelector">
          <div className="personalData">
            <div className="imgProfile"><img src={imgSubjectExample3} alt=""/></div>
            <p className="name">Antiguo Testamento</p>
          </div>
          <p className="role">Curso Básico</p>
        </div>
        <div className="accWrap">
          <EnrollmentContainer keyId="3"/>
        </div>
      </div>

      <div className="accRow">
        <div className="accSelector">
          <div className="personalData">
            <div className="imgProfile"><img src={imgSubjectExample4} alt=""/></div>
            <p className="name">Homilética</p>
          </div>
          <p className="role">Curso Básico</p>
        </div>
        <div className="accWrap">
          <EnrollmentContainer keyId="4"/>
        </div>
      </div>

      <div className="accRow">
        <div className="accSelector">
          <div className="personalData">
            <div className="imgProfile"><img src={imgSubjectExample5} alt=""/></div>
            <p className="name">Profecía Bíblica</p>
          </div>
          <p className="role">Curso Básico</p>
        </div>
        <div className="accWrap">
          <EnrollmentContainer keyId="5"/>
        </div>
      </div>

      <div className="accRow">
        <div className="accSelector">
          <div className="personalData">
            <div className="imgProfile"><img src={imgSubjectExample6} alt=""/></div>
            <p className="name">Evangelios Sinópticos</p>
          </div>
          <p className="role">Curso Básico</p>
        </div>
        <div className="accWrap">
          <EnrollmentContainer keyId="6"/>
        </div>
      </div>

      <div className="accRow">
        <div className="accSelector">
          <div className="personalData">
            <div className="imgProfile"><img src={imgSubjectExample1} alt=""/></div>
            <p className="name">Profecía Bíblica</p>
          </div>
          <p className="role">Curso Básico</p>
        </div>
        <div className="accWrap">
          <EnrollmentContainer keyId="7"/>
        </div>
      </div>

      <div className="accRow">
        <div className="accSelector">
          <div className="personalData">
            <div className="imgProfile"><img src={imgSubjectExample2} alt="Perfil Usuario"/></div>
            <p className="name">Homilética</p>
          </div>
          <p className="role">Curso Básico</p>
        </div>
        <div className="accWrap">
          <EnrollmentContainer keyId="8"/>
        </div>
      </div>*/}

    </div>
  )
}

export default SubjectsList