import React from 'react'

import bgInvite from '../../assets/images/bg-invite.jpg'

import styles from './InviteFriend.module.css'

const InviteFriend = () => {

  return (
    <section style={{ backgroundImage: `url(${bgInvite})` }} className={styles.inviteAFriend}>
      <h2 className={`${styles.sectionHeader} ${styles.white}`}>Invita a un amigo</h2>
      <form action="">
        <fieldset>
          <input type="text" placeholder="Nombre" className={styles.lightedFont}/>
          <label className="error">Lorem ipsum dolor</label>
        </fieldset>
        <fieldset>
          <input
            type="email"
            placeholder="Correo electrónico"
            className={styles.lightedFont}
          />
          <label className="error">Lorem ipsum dolor</label>
        </fieldset>
        <fieldset>
          <button type="submit" className="btn formButton">Enviar</button>
        </fieldset>
      </form>
    </section>

  )
}

export default InviteFriend
