import React from 'react'
import { Redirect, Route } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { prefixes, TEACHER_ROLE } from '../constants'
import { selectAuth } from '../../redux/selectors/auth.selectors'
import Home from '../../../containers/protected/teacher/Home/Home'
import Students from '../../../containers/protected/teacher/Students/Students'
import Course from '../../../containers/protected/teacher/Courses/Course'
import Devotionals from '../../../containers/protected/teacher/Devotionals/Devotionals'
import TeacherInteraction
  from '../../../containers/protected/teacher/Students/MyStudents/TeacherInteraction/TeacherInteraction'

const prefix = prefixes.TEACHER

export const TeacherRoutes = [
  {
    component: Home,
    path: `${prefix}/home`
  },
  {
    component: Students,
    path: `${prefix}/my-students`
  },
  {
    component: Course,
    path: `${prefix}/my-courses`
  },
  {
    component: Devotionals,
    path: `${prefix}/my-devs`
  },
  {
    component: TeacherInteraction,
    path: `${prefix}/my-students/interaction`
  }
]

const TeacherPrivateRoute = ({ user, children, component: Component, ...rest }) => {
  // console.log('TeacherPrivateRoute: user: ', user)
  // console.log('TeacherPrivateRoute: rest: ', { ...rest })
  const auth = useSelector(selectAuth)
  const { dataLoaded } = auth
  // console.log('TeacherPrivateRoute: dataLoaded: ', dataLoaded)

  if (dataLoaded) {
    return <Route
      {...rest}
      render={(props) => (
        user && +user?.role.id === TEACHER_ROLE
          ? <Component {...props} />
          : <Redirect to="/login"/>)}
    />
  }
  return null
}

export default TeacherPrivateRoute
