import React from 'react'
import { Redirect, Route } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { prefixes, STUDENT_ROLE } from '../constants'
import { selectAuth } from '../../redux/selectors/auth.selectors'
import Home from '../../../containers/protected/student/Home/Home'
import Teachers from '../../../containers/protected/student/Teachers/Teachers'
import Course from '../../../containers/protected/student/Courses/Course'
import Devotionals from '../../../containers/protected/student/Devotionals/Devotionals'
import MySubject from '../../../containers/protected/student/Courses/MyCourses/MySubject/MySubject'

const prefix = prefixes.STUDENT

export const StudentRoutes = [
  {
    component: Home,
    path: `${prefix}/home`
  },
  {
    component: Teachers,
    path: `${prefix}/my-teachers`
  },
  {
    component: Course,
    path: `${prefix}/my-courses`
  },
  {
    component: MySubject,
    path: `${prefix}/my-courses/my-subjects/:subjectId?`
  },
  {
    component: Devotionals,
    path: `${prefix}/my-devs`
  }
]

const StudentPrivateRoute = ({ user, children, component: Component, ...rest }) => {
  // console.log('StudentPrivateRoute: user: ', user)
  // console.log('StudentPrivateRoute: rest: ', { ...rest })
  const auth = useSelector(selectAuth)
  const { dataLoaded } = auth
  // console.log('StudentPrivateRoute: dataLoaded: ', dataLoaded)

  if (dataLoaded) {
    return <Route exact {...rest}
                  render={
                    props => (user && +user?.role.id === STUDENT_ROLE
                      ? <Component {...props} />
                      : <Redirect to="/login"/>)
                  }
    />
  }
  return null
}

export default StudentPrivateRoute
