import React from 'react'

import MySlider from '../../../../commons/components/MySlider/MySlider'
import UserProfile from '../../../../commons/components/UserProfile/UserProfile'
import StudentEnrollment from './StudentEnrollment/StudentEnrollment'
import StudentInProgress from './StudentInProgress/StudentInProgress'
import Devotional from './Devotional/Devotional'
import Interaction from './Interaction/Interaction'
import Extracurricular from '../../../../commons/components/Extracurricular/Extracurricular'
import Footer from '../../../../commons/components/Footer/Footer'

import styles from './Home.module.css'

const Home = () => {
  return (
    <div className={styles.mainContainer}>
      <MySlider/>
      <UserProfile/>
      <StudentEnrollment/>
      <StudentInProgress/>
      <Devotional/>
      <Interaction/>
      <Extracurricular/>
      <Footer/>
    </div>
  )
}

export default Home
