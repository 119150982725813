import React from 'react'

import imgProfile from '../../../../../commons/assets/images/img-profile.jpg'
import imgProfile02 from '../../../../../commons/assets/images/img-profile-02.jpg'
import imgProfile03 from '../../../../../commons/assets/images/img-profile-03.jpg'
import './StudentEnrollment.css'

const StudentEnrollment = () => {

  return (
    <section className="tabs">
      <ul className="nav nav-pills mb-4 tab-list" id="pills-tab" role="tablist">
        <li className="nav-item class-year" role="presentation">
          <button
            className="nav-link active"
            id="pills-home-tab"
            data-bs-toggle="pill"
            data-bs-target="#pills-home"
            type="button"
            role="tab"
            aria-controls="pills-home"
            aria-selected="true"
          >
            Clase 2020
          </button>
        </li>
        <li className="nav-item class-year" role="presentation">
          <button
            className="nav-link"
            id="pills-profile-tab"
            data-bs-toggle="pill"
            data-bs-target="#pills-profile"
            type="button"
            role="tab"
            aria-controls="pills-profile"
            aria-selected="false"
          >
            Clase 2021
          </button>
        </li>
        <li className="nav-item class-year" role="presentation">
          <button
            className="nav-link"
            id="pills-contact-tab"
            data-bs-toggle="pill"
            data-bs-target="#pills-contact"
            type="button"
            role="tab"
            aria-controls="pills-contact"
            aria-selected="false"
          >
            Clase 2022
          </button>
        </li>
      </ul>
      <div className="tab-content" id="pills-tabContent">
        <div
          className="tab-pane fade show active"
          id="pills-home"
          role="tabpanel"
          aria-labelledby="pills-home-tab"
          tabIndex="0"
        >
          <div className="profileGrid">
            <div className="profileCol">
              <div className="imgCont"><img src={imgProfile}/></div>
              <p className="name">Ana Fuentes</p>
              <div className="progress-bar">
                <div className="bar"><span style={{ width: '50%' }}/></div>
                <p className="percentage">Avance de curso<span>50%</span></p>
              </div>
              <p className="status pay">Pagado</p>
            </div>
            <div className="profileCol">
              <div className="imgCont"><img src={imgProfile}/></div>
              <p className="name">Ana Fuentes</p>
              <div className="progress-bar">
                <div className="bar"><span style={{ width: '50%' }}/></div>
                <p className="percentage">Avance de curso<span>50%</span></p>
              </div>
              <p className="status debit">Adeudo</p>
            </div>
            <div className="profileCol">
              <div className="imgCont"><img src={imgProfile}/></div>
              <p className="name">Ana Fuentes</p>
              <div className="progress-bar">
                <div className="bar"><span style={{ width: '50%' }}></span></div>
                <p className="percentage">Avance de curso<span>50%</span></p>
              </div>
              <p className="status unsubscribed">Baja</p>
            </div>
            <div className="profileCol">
              <div className="imgCont"><img src={imgProfile}/></div>
              <p className="name">Ana Fuentes</p>
              <div className="progress-bar">
                <div className="bar"><span style={{ width: '50%' }}/></div>
                <p className="percentage">Avance de curso<span>50%</span></p>
              </div>
              <p className="status suspended">Suspención</p>
            </div>
          </div>
          <div className="groupBtn"><a href="#" className="btn btnGris">Cargar más</a></div>
        </div>
        <div
          className="tab-pane fade"
          id="pills-profile"
          role="tabpanel"
          aria-labelledby="pills-profile-tab"
          tabIndex="0"
        >
          <div className="profileGrid">
            <div className="profileCol">
              <div className="imgCont"><img src={imgProfile02}/></div>
              <p className="name">Alfredo Botello</p>
              <div className="progress-bar">
                <div className="bar"><span style={{ width: '50%' }}/></div>
                <p className="percentage">Avance de curso<span>50%</span></p>
              </div>
              <p className="status pay">Pagado</p>
            </div>
            <div className="profileCol">
              <div className="imgCont"><img src={imgProfile02}/></div>
              <p className="name">Alfredo Botello</p>
              <div className="progress-bar">
                <div className="bar"><span style={{ width: '50%' }}/></div>
                <p className="percentage">Avance de curso<span>50%</span></p>
              </div>
              <p className="status debit">Adeudo</p>
            </div>
            <div className="profileCol">
              <div className="imgCont"><img src={imgProfile02}/></div>
              <p className="name">Alfredo Botello</p>
              <div className="progress-bar">
                <div className="bar"><span style={{ width: '50%' }}></span></div>
                <p className="percentage">Avance de curso<span>50%</span></p>
              </div>
              <p className="status unsubscribed">Baja</p>
            </div>
            <div className="profileCol">
              <div className="imgCont"><img src={imgProfile02}/></div>
              <p className="name">Alfredo Botello</p>
              <div className="progress-bar">
                <div className="bar"><span style={{ width: '50%' }}/></div>
                <p className="percentage">Avance de curso<span>50%</span></p>
              </div>
              <p className="status suspended">Suspención</p>
            </div>
          </div>
        </div>
        <div
          className="tab-pane fade"
          id="pills-contact"
          role="tabpanel"
          aria-labelledby="pills-contact-tab"
          tabIndex="0"
        >
          <div className="profileGrid">
            <div className="profileCol">
              <div className="imgCont"><img src={imgProfile03}/></div>
              <p className="name">Jaime Hernández</p>
              <div className="progress-bar">
                <div className="bar"><span style={{ width: '50%' }}/></div>
                <p className="percentage">Avance de curso<span>50%</span></p>
              </div>
              <p className="status pay">Pagado</p>
            </div>
            <div className="profileCol">
              <div className="imgCont"><img src={imgProfile03}/></div>
              <p className="name">Jaime Hernández</p>
              <div className="progress-bar">
                <div className="bar"><span style={{ width: '50%' }}/></div>
                <p className="percentage">Avance de curso<span>50%</span></p>
              </div>
              <p className="status debit">Adeudo</p>
            </div>
            <div className="profileCol">
              <div className="imgCont"><img src={imgProfile03}/></div>
              <p className="name">Jaime Hernández</p>
              <div className="progress-bar">
                <div className="bar"><span style={{ width: '50%' }}></span></div>
                <p className="percentage">Avance de curso<span>50%</span></p>
              </div>
              <p className="status unsubscribed">Baja</p>
            </div>
            <div className="profileCol">
              <div className="imgCont"><img src={imgProfile03}/></div>
              <p className="name">Jaime Hernández</p>
              <div className="progress-bar">
                <div className="bar"><span style={{ width: '50%' }}/></div>
                <p className="percentage">Avance de curso<span>50%</span></p>
              </div>
              <p className="status suspended">Suspención</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default StudentEnrollment
