import React from 'react'

import imgCourseExample1 from '../../../../../../commons/assets/images/img-subject-example-1.jpg'
import imgCourseExample2 from '../../../../../../commons/assets/images/img-subject-example-2.jpg'
import imgCourseExample3 from '../../../../../../commons/assets/images/img-subject-example-3.jpg'

import CourseContainer from '../commons/CourseContainer/CourseContainer'

import './CoursesList.css'

const CoursesList = () => {

  return (
    <div className="listUsers">

      {/*ESTUDIANTE 1*/}
      <div className="accRow">
        <div className="accSelector">
          <div className="personalData">
            <div className="imgProfile"><img src={imgCourseExample1} alt=""/></div>
            <p class="name">Básico</p>
          </div>
          <p class="subject">Administración</p>
        </div>

        <div className="accWrap">
          <CourseContainer keyId="1"/>
        </div>
      </div>

      <div className="accRow">
        <div className="accSelector">
          <div className="personalData">
            <div className="imgProfile"><img src={imgCourseExample2} alt=""/></div>
            <p class="name">Básico</p>
          </div>
          <p class="subject">Administración</p>
        </div>
        <div className="accWrap">
          <CourseContainer keyId="2"/>
        </div>
      </div>

      <div className="accRow">
        <div className="accSelector">
          <div className="personalData">
            <div className="imgProfile"><img src={imgCourseExample3} alt=""/></div>
            <p class="name">Básico</p>
          </div>
          <p class="subject">Administración</p>
        </div>
        <div className="accWrap">
          <CourseContainer keyId="3"/>
        </div>
      </div>

      <div className="accRow">
        <div className="accSelector">
          <div className="personalData">
            <div className="imgProfile"><img src={imgCourseExample1} alt=""/></div>
            <p class="name">Básico</p>
          </div>
          <p class="subject">Administración</p>
        </div>
        <div className="accWrap">
          <CourseContainer keyId="4"/>
        </div>
      </div>

      <div className="accRow">
        <div className="accSelector">
          <div className="personalData">
            <div className="imgProfile"><img src={imgCourseExample2} alt=""/></div>
            <p class="name">Básico</p>
          </div>
          <p class="subject">Administración</p>
        </div>
        <div className="accWrap">
          <CourseContainer keyId="5"/>
        </div>
      </div>

      <div className="accRow">
        <div className="accSelector">
          <div className="personalData">
            <div className="imgProfile"><img src={imgCourseExample3} alt=""/></div>
            <p class="name">Básico</p>
          </div>
          <p class="subject">Administración</p>
        </div>
        <div className="accWrap">
          <CourseContainer keyId="6"/>
        </div>
      </div>

      <div className="accRow">
        <div className="accSelector">
          <div className="personalData">
            <div className="imgProfile"><img src={imgCourseExample1} alt=""/></div>
            <p class="name">Básico</p>
          </div>
          <p class="subject">Administración</p>
        </div>
        <div className="accWrap">
          <CourseContainer keyId="7"/>
        </div>
      </div>

      <div className="accRow">
        <div className="accSelector">
          <div className="personalData">
            <div className="imgProfile"><img src={imgCourseExample2} alt="Perfil Usuario"/></div>
            <p class="name">Básico</p>
          </div>
          <p class="subject">Administración</p>
        </div>
        <div className="accWrap">
          <CourseContainer keyId="8"/>
        </div>
      </div>
    </div>
  )
}

export default CoursesList