import React, { useEffect } from 'react'
import { BrowserRouter, Redirect, Switch } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { Loader } from '../components/Loader/Loader'
import { checkIfUserIsLoggedIn } from '../redux/actions/auth.actions'
import { selectAuth } from '../redux/selectors/auth.selectors'
import AnonymousPrivateRoute, { AnonymousRoutes } from './anonymous'
import TeacherPrivateRoute, { TeacherRoutes } from './teacher'
import StudentPrivateRoute, { StudentRoutes } from './student'
import AdministratorPrivateRoute, { AdministratorRoutes } from './administrator'
import Header from '../components/Header/Header'
import Footer from '../components/Footer/Footer'

const AppRouter = () => {

  const dispatch = useDispatch()
  const { loading, dataLoaded, user } = useSelector(selectAuth)
  // console.log('AppRouter: loading: 🇲🇽🇲🇽🇲🇽🇲🇽🇲🇽', loading)

  const generateDefaultRedirect = () => {
    return <Redirect from="/" to="/login"/>
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const renderRouter = () => {
    return (
      <>
        {loading && <Loader/>}
        <main className="wrapper">
          <BrowserRouter>
            {user && <Header/>}
            <Switch>
              {AdministratorRoutes.map((r, i) => (
                <AdministratorPrivateRoute
                  key={`admin_route_${i}`}
                  user={user}
                  children={r.children}
                  path={r.path}
                  component={r.component}/>
              ))}
              {TeacherRoutes.map((r, i) => (
                <TeacherPrivateRoute
                  key={`teacher_route_${i}`}
                  user={user}
                  exact path={r.path}
                  component={r.component}/>
              ))}
              {StudentRoutes.map((r, i) => (
                <StudentPrivateRoute
                  key={`student_route_${i}`}
                  user={user}
                  exact path={r.path}
                  component={r.component}/>
              ))}
              {AnonymousRoutes.map((r, i) => (
                <AnonymousPrivateRoute
                  key={`anonymous_route_${i}`}
                  user={user}
                  exact path={r.path}
                  component={r.component}/>
              ))}
              {generateDefaultRedirect()}
            </Switch>
            {/*{user && <Footer/>}*/}
          </BrowserRouter>
        </main>
      </>
    )
  }

  useEffect(() => {
    dispatch(checkIfUserIsLoggedIn())
  }, [])

  return !dataLoaded ? <></> : renderRouter()
}

export default AppRouter
