import { combineReducers } from 'redux'

import AuthReducer from './auth.reducers'
import TeacherReducer from './teacher.reducers'
import StudentReducer from './student.reducers'
import UserReducer from './user.reducers'
import SubjectReducer from './subject.reducers'
import PeriodReducer from './period.reducers'
import EnrollmentReducer from './enrollment.reducers'

export default combineReducers({
  auth: AuthReducer,
  teacher: TeacherReducer,
  student: StudentReducer,
  user: UserReducer,
  subject: SubjectReducer,
  period: PeriodReducer,
  enrollment: EnrollmentReducer
})
