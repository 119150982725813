import React from 'react'

import TeacherContainer from '../commons/TeacherContainer/TeacherContainer'

import defaultAvatar from '../../../../../../commons/assets/images/default-avatar.jpeg'
// import imgProfile from '../../../../../../commons/assets/images/img-profile.jpg'

import './TeachersList.css'

const TeachersList = ({ keyOp, items }) => {
  return (
    <div className="listTeachers">
      {
        items.map(item => (
          <React.Fragment key={`tl_${item.id}`}>
            <div className="accRow">
              <div className="accSelector">
                <div className="personalData">
                  <div className="imgProfile"><img src={defaultAvatar} alt=""/></div>
                  <p className="name">{item.name} {item.paternal}</p>
                </div>
                <p className="role">Administración</p>
              </div>

              <div className="accWrap">
                <TeacherContainer keyId={`tl_${item.id}`}
                                  keyOp={keyOp}
                                  item={item}/>
              </div>
            </div>
          </React.Fragment>
        ))
      }
    </div>
  )
}

export default TeachersList