import { fetchCourses, fetchCoursesFromStudent, saveCourse, updateCourse } from '../services/course.services'
import Swal from 'sweetalert2'

import { LOADER_OFF, LOADER_ON } from './auth.actions'
export const GET_ALL_COURSES_INIT = 'GET_ALL_COURSES_INIT'
export const GET_ALL_COURSES_SUCCESS = 'GET_ALL_COURSES_SUCCESS'
export const GET_ALL_COURSES_ERROR = 'GET_ALL_COURSES_ERROR'
export const GET_ALL_COURSES_FROM_STUDENT_INIT = 'GET_ALL_COURSES_FROM_STUDENT_INIT'
export const GET_ALL_COURSES_FROM_STUDENT_SUCCESS = 'GET_ALL_COURSES_FROM_STUDENT_SUCCESS'
export const GET_ALL_COURSES_FROM_STUDENT_ERROR = 'GET_ALL_COURSES_FROM_STUDENT_ERROR'
export const NEW_COURSE_INIT = 'NEW_COURSE_INIT'
export const NEW_COURSE_SUCCESS = 'NEW_COURSE_SUCCESS'
export const NEW_COURSE_ERROR = 'NEW_COURSE_ERROR'
export const GET_COURSE_INIT = 'GET_COURSE_INIT'
export const GET_COURSE_SUCCESS = 'GET_COURSE_SUCCESS'
export const GET_COURSE_ERROR = 'GET_COURSE_ERROR'
export const UPDATE_COURSE_INIT = 'UPDATE_COURSE_INIT'
export const UPDATE_COURSE_SUCCESS = 'UPDATE_COURSE_SUCCESS'
export const UPDATE_COURSE_ERROR = 'UPDATE_COURSE_ERROR'
export const DELETE_COURSE_INIT = 'DELETE_COURSE_INIT'
export const DELETE_COURSE_SUCCESS = 'DELETE_COURSE_SUCCESS'
export const DELETE_COURSE_ERROR = 'DELETE_COURSE_ERROR'

export function getCourses() {
  return async (dispatch) => {
    try {
      dispatch({ type: LOADER_ON })
      dispatch({ type: GET_ALL_COURSES_INIT })
      const result = await fetchCourses()
      console.log(result)
      if (result.data) {
        dispatch({ type: LOADER_OFF })
        dispatch({ type: GET_ALL_COURSES_SUCCESS, data: [...result.data.data] })
      } else {
        dispatch({ type: LOADER_OFF })
        dispatch({ type: GET_ALL_COURSES_ERROR })
        await Swal.fire(
          'Advertencia',
          'Ha ocurrido algo inesperado, contacte a soporte',
          'warning'
        )
      }
    } catch (e) {
      dispatch({ type: LOADER_OFF })
      dispatch({ type: GET_ALL_COURSES_ERROR })

      /*const sessionActive =
        e.response.data.detalles[0] ===
        'El usuario cuenta con una sesion activa, puedes intentar nuevamente despúes de 10 minutos.'
      if (sessionActive) {
        Swal.fire({
          title: 'Error',
          text: e.response.data.detalles[0],
          confirmButtonText: 'Aceptar'
        }).then(async (result) => {
          if (result.isConfirmed) {
            dispatch({ type: GET_ALL_COURSES_ERROR })
          }
        })
      } else {
        await Swal.fire('Error', e.response.data.detalles[0], 'warning')
      }*/
    }
  }
}

export function getCoursesFromStudent(id) {
  return async (dispatch) => {
    try {
      dispatch({ type: LOADER_ON })
      dispatch({ type:  GET_ALL_COURSES_FROM_STUDENT_INIT })
      const result = await fetchCoursesFromStudent(id)
      console.log(result)
      dispatch({ type: GET_ALL_COURSES_FROM_STUDENT_SUCCESS, data: [ ...result.data.data ] })
      dispatch({ type: LOADER_OFF })
      // await Swal.fire('Éxito', 'Operación se realizó con éxito', 'success')
    } catch (e) {
      console.log('getCoursesFromStudent: error: ', e)
      dispatch({ type: GET_ALL_COURSES_FROM_STUDENT_ERROR })
      dispatch({ type: LOADER_OFF })
      await Swal.fire('Error', 'Ocurrió algo inesperado, contacte a soporte', 'error')
    }
  }
}

export function newCourse(values) {
  return async (dispatch) => {
    try {
      dispatch({ type: LOADER_ON })
      dispatch({ type: NEW_COURSE_INIT })
      const result = await saveCourse(values)
      // console.log(result)
      dispatch({ type: NEW_COURSE_SUCCESS, data: { ...result.data } })
      dispatch({ type: LOADER_OFF })
      await Swal.fire('Éxito', 'Operación se realizó con éxito', 'success')
    } catch (e) {
      console.log('newCourse: error: ', e)
      dispatch({ type: NEW_COURSE_ERROR })
      dispatch({ type: LOADER_OFF })
      await Swal.fire('Error', 'Ocurrió algo inesperado, contacte a soporte', 'error')
    }
  }
}

export function editCourse(values) {
  return async (dispatch) => {
    try {
      dispatch({ type: LOADER_ON })
      dispatch({ type: UPDATE_COURSE_INIT })
      const result = await updateCourse(values)
      // console.log(result)
      dispatch({ type: UPDATE_COURSE_SUCCESS, data: { ...result.data } })
      dispatch({ type: LOADER_OFF })
      await Swal.fire('Éxito', 'Operación se realizó con éxito', 'success')
    } catch (e) {
      console.log('editCourse: error: ', e)
      dispatch({ type: UPDATE_COURSE_ERROR })
      dispatch({ type: LOADER_OFF })
      await Swal.fire('Error', 'Ocurrió algo inesperado, contacte a soporte', 'error')
    }
  }
}

/*export function getCourse(courseId) {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_COURSE_INIT })
      const result = await fetchCourse(courseId)
      // console.log(result)
      dispatch({ type: GET_COURSE_SUCCESS, data: { ...result.data } })
    } catch (e) {
      console.log('retrieveCourse: error: ', e)
      dispatch({ type: GET_COURSE_ERROR })
      await Swal.fire('Error', e.response.data.detalles[0], 'warning')
    }
  }
}

export function editCourse(values) {
  return async (dispatch) => {
    try {
      dispatch({ type: UPDATE_COURSE_INIT })
      const result = await updateCourse(values)
      // console.log(result)
      dispatch({ type: UPDATE_COURSE_SUCCESS, data: { ...result.data } })
    } catch (e) {
      console.log('editCourse: error: ', e)
      dispatch({ type: UPDATE_COURSE_ERROR })
      await Swal.fire('Error', e.response.data.detalles[0], 'warning')
    }
  }
}

export function removeCourse(courseId) {
  return async (dispatch) => {
    try {
      dispatch({ type: DELETE_COURSE_INIT })
      const result = await deleteCourse(courseId)
      // console.log(result)
      dispatch({ type: DELETE_COURSE_SUCCESS, data: { ...result.data } })
    } catch (e) {
      console.log('removeCourse: error: ', e)
      dispatch({ type: DELETE_COURSE_ERROR })
      await Swal.fire('Error', e.response.data.detalles[0], 'warning')
    }
  }
}*/
