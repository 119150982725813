import React from 'react'

import styles from './ClassAdvance.module.css'

const ClassAdvance = () => {
  return (
    <section className={styles.coursePercent}>
      <div className={styles.percentBar}><span style={{ width: '50%' }}></span></div>
      <p className={styles.number}>50%</p>
    </section>
  )
}

export default ClassAdvance
