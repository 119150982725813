import React from 'react'

import imgProfile from '../../assets/images/img-profile.jpg'
import imgProfile03 from '../../assets/images/img-profile-03.jpg'

import styles from './StudentTeacherProfile.module.css'

const UserProfile = () => {
  return (
    <section className={styles.profileDataTeacher}>
      <div className={`${styles.profileDataCont} ${styles.left}`}>
        <div className={styles.profileDataInfo}>
          <div className={styles.imgProfile}><img src={imgProfile03} alt="Jaime Hernández"/></div>
          <p>Jaime Hernández</p><span>Profesor</span>
        </div>
      </div>
      <div className={`${styles.profileDataCont} ${styles.right}`}>
        <div className={styles.profileDataInfo}>
          <div className={styles.imgProfile}><img src={imgProfile} alt="Ana Fuentes"/></div>
          <p>Ana Fuentes</p><span>Alumno</span>
        </div>
      </div>
    </section>
  )
}

export default UserProfile
