import React from 'react'

import StudentContainer from '../commons/StudentContainer/StudentContainer'

// import imgProfile from '../../../../../../commons/assets/images/img-profile.jpg'
import defaultAvatar from '../../../../../../commons/assets/images/default-avatar.jpeg'

import './StudentsGrid.css'

const StudentsGrid = ({ keyOp, items }) => {

  return (
    <dl className="gridUsers">

      {
        items.map(item => (
          <React.Fragment key={`sg_${item.id}`}>
            <dt>
              <div className="userCard">
                <div className="imgProfile"><img src={defaultAvatar} alt=""/></div>
                <p className="name">{item.name} {item.paternal}</p>
                <div className="progress__bar">
                  <div className="bar"><span style={{width: '50%'}}></span></div>
                  <p className="percentage">Avance de curso<span>50%</span></p>
                </div>
                <p className="status pay">Pagado</p>
              </div>
            </dt>
            <dd>
              <StudentContainer keyId={`csg_${item.id}`}
                                keyOp={keyOp}
                                item={item}/>
            </dd>
          </React.Fragment>
        ))
      }

      {/*
      ESTUDIANTE 2
        <dt>
        <div className="userCard">
          <div className="imgProfile"><img src={imgProfile} alt=""/></div>
          <p className="name">Ana Fuentes</p>
          <div className="progress__bar">
            <div className="bar"><span style={{width: '50%'}}></span></div>
            <p className="percentage">Avance de curso<span>50%</span></p>
          </div>
          <p className="status pay">Pagado</p>
        </div>
      </dt>
      <dd>
        <StudentContainer keyId="1"/>
      </dd>

      ESTUDIANTE 2
      <dt>
        <div className="userCard">
          <div className="imgProfile"><img src={imgProfile} alt=""/></div>
          <p className="name">Ana Fuentes</p>
          <div className="progress__bar">
            <div className="bar"><span style={{width: '50%'}}></span></div>
            <p className="percentage">Avance de curso<span>50%</span></p>
          </div>
          <p className="status debit">Adeudo</p>
        </div>
      </dt>
      <dd>
        <StudentContainer keyId="2"/>
      </dd>

      ESTUDIANTE 3
      <dt>
        <div className="userCard">
          <div className="imgProfile"><img src={imgProfile} alt=""/></div>
          <p className="name">Ana Fuentes</p>
          <div className="progress__bar">
            <div className="bar"><span style={{width: '50%'}}></span></div>
            <p className="percentage">Avance de curso<span>50%</span></p>
          </div>
          <p className="status unsubscribed">Baja</p>
        </div>
      </dt>
      <dd>
        <StudentContainer keyId="3"/>
      </dd>

      ESTUDIANTE 4
      <dt>
        <div className="userCard">
          <div className="imgProfile"><img src={imgProfile} alt=""/></div>
          <p className="name">Ana Fuentes</p>
          <div className="progress__bar">
            <div className="bar"><span style={{width: '50%'}}></span></div>
            <p className="percentage">Avance de curso<span>50%</span></p>
          </div>
          <p className="status suspended">Suspendido</p>
        </div>
      </dt>
      <dd>
        <StudentContainer keyId="4"/>
      </dd>

      ESTUDIANTE 5
      <dt>
        <div className="userCard">
          <div className="imgProfile"><img src={imgProfile} alt=""/></div>
          <p className="name">Ana Fuentes</p>
          <div className="progress__bar">
            <div className="bar"><span style={{width: '50%'}}></span></div>
            <p className="percentage">Avance de curso<span>50%</span></p>
          </div>
          <p className="status pay">Pagado</p>
        </div>
      </dt>
      <dd>
        <StudentContainer keyId="5"/>
      </dd>

      ESTUDIANTE 6
      <dt>
        <div className="userCard">
          <div className="imgProfile"><img src={imgProfile} alt=""/></div>
          <p className="name">Ana Fuentes</p>
          <div className="progress__bar">
            <div className="bar"><span style={{width: '50%'}}></span></div>
            <p className="percentage">Avance de curso<span>50%</span></p>
          </div>
          <p className="status pay">Pagado</p>
        </div>
      </dt>
      <dd>
        <StudentContainer keyId="6"/>
      </dd>*/}

    </dl>
  )
}

export default StudentsGrid