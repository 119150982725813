import axiosClient from '../../network/apiClient'

import { PERIOD_ENDPOINT } from '../../const'

export const fetchPeriods = async () => {
  const token = sessionStorage.getItem('_k')
  const url = PERIOD_ENDPOINT.PERIODS
  const method = 'get'
  const encryptKeys = null
  const headers = {
    // Authorization: `Bearer ${token}`
  }

  const params = {}
  // console.log(params)

  return await axiosClient({
    method,
    url,
    data: params,
    headers,
    encryptKeys
  })
}

export const fetchPeriodsFromStudent = async (id) => {
  const token = sessionStorage.getItem('_k')
  const url = `${PERIOD_ENDPOINT.PERIODS}/${id}/periods`
  const method = 'get'
  const encryptKeys = null
  const headers = {
    Authorization: `Bearer ${token}`
  }

  const params = {}
  // console.log(params)

  return await axiosClient({
    method,
    url,
    data: params,
    headers,
    encryptKeys
  })
}

export const savePeriod = async (values) => {
  const url = PERIOD_ENDPOINT.PERIODS
  const method = 'post'
  const encryptKeys = null
  const headers = {}

  const params = {
    ...values
  }
  // console.log(params)

  return await axiosClient({
    method,
    url,
    data: params,
    headers,
    encryptKeys
  })
}

export const updatePeriod = async (item) => {
  console.log(item)
  const url = `${PERIOD_ENDPOINT.PERIODS}/${item.id}`
  const method = 'put'
  const encryptKeys = null
  const headers = {}

  const params = {
    ...item
  }
  // console.log(params)

  return await axiosClient({
    method,
    url,
    data: params,
    headers,
    encryptKeys
  })
}


/*
export const fetchPeriod = async (periodId) => {
  const url = `${PERIOD_ENDPOINT.GET_PERIODS}/${periodId}`
  const method = 'get'
  const encryptKeys = null
  const headers = {}

  const params = {}
  // console.log(params)

  return await axiosClient({
    method,
    url,
    data: params,
    headers,
    encryptKeys
  })
}

export const deletePeriod = async (periodId) => {
  const url = '/usuarios/actualizar-estatus'
  const method = 'put'
  const encryptKeys = null
  const headers = {}

  const params = {
    id: periodId
  }
  // console.log(params)

  return await axiosClient({
    method,
    url,
    data: params,
    headers,
    encryptKeys
  })
}
*/
