import React, { useEffect } from 'react'

import $ from 'jquery'

import './AcademicCourse.css'

const AcademicCourse = () => {

  useEffect(() => {
    $('.respTabs').responsiveTabs({
      startCollapsed: 'accordion'
    })
  }, [])

  return (
    <>
      {/*CALENDARIO ESCOLAR*/}
      <section className="schoolCalendar">
        <h2 className="sectionHeader orange">Calendario Escolar</h2>
        <div className="introText">
          <p className="name">Ana,</p>
          <p>
            Una educación en línea saludable comienza con mantener el ritmo de
            tus estudios.
          </p>
          <p>
            Te sugerimos el siguiente calendario para no postergar tu avance
            académico.
          </p>
        </div>
      </section>
      {/*<section className={styles.schoolCalendar}>
        <h2 className={`${styles.sectionHeader} ${styles.orange}`}>Calendario Escolar</h2>
        <div className={styles.introText}>
          <p className={styles.name}>Ana,</p>
          <p>
            Una educación en línea saludable comienza con mantener el ritmo de
            tus estudios.
          </p>
          <p>
            Te sugerimos el siguiente calendario para no postergar tu avance
            académico.
          </p>
        </div>
      </section>*/}

      {/*CURSOS*/}
      <section className="respTabs">
        <ul className="colored">
          <li><a href="#tab-1">Curso Básico</a></li>
          <li><a href="#tab-2">Curso Avanzado</a></li>
          <li><a href="#tab-3">Especialidad</a></li>
        </ul>
        <div id="tab-1" className="tabCont">
          <div className="courseCalendar">
            <div className="item completed">
              <p className="itemTitle">Homilética</p>
              <p className="date">Marzo 1, 2020</p>
            </div>
            <div className="item completed">
              <p className="itemTitle">Teología</p>
              <p className="date">Abril 1, 2020</p>
            </div>
            <div className="item active">
              <p className="itemTitle">Hermenéutica</p>
              <p className="date">Mayo 1, 2020</p>
            </div>
            <div className="item">
              <p className="itemTitle">Antiguo Testamento</p>
              <p className="date">Junio 1, 2020</p>
            </div>
            <div className="item">
              <p className="itemTitle">Profecía Bíblica</p>
              <p className="date">Julio 1, 2020</p>
            </div>
            <div className="item">
              <p className="itemTitle">Evangelios Sinópticos</p>
              <p className="date">Agosto 1, 2020</p>
            </div>
            <div className="item">
              <p className="itemTitle">Historia de la Iglesia</p>
              <p className="date">Septiembre 1, 2020</p>
            </div>
            <div className="item">
              <p className="itemTitle">Espíritu Santo</p>
              <p className="date">Octubre 1, 2020</p>
            </div>
            <div className="item">
              <p className="itemTitle">Cristología</p>
              <p className="date">Noviembre 1, 2020</p>
            </div>
            <div className="item">
              <p className="itemTitle">Pneumatología</p>
              <p className="date">Diciembre 1, 2020</p>
            </div>
            <div className="item">
              <p className="itemTitle">Soteriología</p>
              <p className="date">Enero 1, 2020</p>
            </div>
            <div className="item">
              <p className="itemTitle">Hamarteología</p>
              <p className="date">Febrero 1, 2020</p>
            </div>
          </div>
        </div>
        <div id="tab-2" className="tabCont">
          <div className="courseCalendar">
            <div className="item">
              <p className="itemTitle">Cristología</p>
              <p className="date">Noviembre 1, 2020</p>
            </div>
            <div className="item">
              <p className="itemTitle">Pneumatología</p>
              <p className="date">Diciembre 1, 2020</p>
            </div>
            <div className="item">
              <p className="itemTitle">Soteriología</p>
              <p className="date">Enero 1, 2020</p>
            </div>
            <div className="item">
              <p className="itemTitle">Hamarteología</p>
              <p className="date">Febrero 1, 2020</p>
            </div>
          </div>
        </div>
        <div id="tab-3" className="tabCont">
          <div className="courseCalendar">
            <div className="item">
              <p className="itemTitle">Cristología</p>
              <p className="date">Noviembre 1, 2020</p>
            </div>
            <div className="item">
              <p className="itemTitle">Pneumatología</p>
              <p className="date">Diciembre 1, 2020</p>
            </div>
          </div>
        </div>
      </section>
      {/*<section className={styles.respTabs}>
        <ul className="colored">
          <li><a href="#tab-1">Curso Básico</a></li>
          <li><a href="#tab-2">Curso Avanzado</a></li>
          <li><a href="#tab-3">Especialidad</a></li>
        </ul>
        <div id="tab-1" className={styles.tabCont}>
          <div className={styles.courseCalendar}>
            <div className={`${styles.item} ${styles.completed}`}>
              <p className={styles.itemTitle}>Homilética</p>
              <p className={styles.date}>Marzo 1, 2020</p>
            </div>
            <div className={`${styles.item} ${styles.completed}`}>
              <p className={styles.itemTitle}>Teología</p>
              <p className={styles.date}>Abril 1, 2020</p>
            </div>
            <div className={`${styles.item} ${styles.active}`}>
              <p className={styles.itemTitle}>Hermenéutica</p>
              <p className={styles.date}>Mayo 1, 2020</p>
            </div>
            <div className={`${styles.item}`}>
              <p className={styles.itemTitle}>Antiguo Testamento</p>
              <p className={styles.date}>Junio 1, 2020</p>
            </div>
            <div className={`${styles.item}`}>
              <p className={styles.itemTitle}>Profecía Bíblica</p>
              <p className={styles.date}>Julio 1, 2020</p>
            </div>
            <div className={`${styles.item}`}>
              <p className={styles.itemTitle}>Evangelios Sinópticos</p>
              <p className={styles.date}>Agosto 1, 2020</p>
            </div>
            <div className={`${styles.item}`}>
              <p className={styles.itemTitle}>Historia de la Iglesia</p>
              <p className={styles.date}>Septiembre 1, 2020</p>
            </div>
            <div className={`${styles.item}`}>
              <p className={styles.itemTitle}>Espíritu Santo</p>
              <p className={styles.date}>Octubre 1, 2020</p>
            </div>
            <div className={`${styles.item}`}>
              <p className={styles.itemTitle}>Cristología</p>
              <p className={styles.date}>Noviembre 1, 2020</p>
            </div>
            <div className={`${styles.item}`}>
              <p className={styles.itemTitle}>Pneumatología</p>
              <p className={styles.date}>Diciembre 1, 2020</p>
            </div>
            <div className={`${styles.item}`}>
              <p className={styles.itemTitle}>Soteriología</p>
              <p className={styles.date}>Enero 1, 2020</p>
            </div>
            <div className={`${styles.item}`}>
              <p className={styles.itemTitle}>Hamarteología</p>
              <p className={styles.date}>Febrero 1, 2020</p>
            </div>
          </div>
        </div>
        <div id="tab-2" className={styles.tabCont}>
          <div className={styles.courseCalendar}>
            <div className={`${styles.item}`}>
              <p className={styles.itemTitle}>Cristología</p>
              <p className={styles.date}>Noviembre 1, 2020</p>
            </div>
            <div className={`${styles.item}`}>
              <p className={styles.itemTitle}>Pneumatología</p>
              <p className={styles.date}>Diciembre 1, 2020</p>
            </div>
            <div className={`${styles.item}`}>
              <p className={styles.itemTitle}>Soteriología</p>
              <p className={styles.date}>Enero 1, 2020</p>
            </div>
            <div className={`${styles.item}`}>
              <p className={styles.itemTitle}>Hamarteología</p>
              <p className={styles.date}>Febrero 1, 2020</p>
            </div>
          </div>
        </div>
        <div id="tab-2" className={styles.tabCont}>
          <div className={styles.courseCalendar}>
            <div className={`${styles.item}`}>
              <p className={styles.itemTitle}>Cristología</p>
              <p className={styles.date}>Noviembre 1, 2020</p>
            </div>
            <div className={`${styles.item}`}>
              <p className={styles.itemTitle}>Soteriología</p>
              <p className={styles.date}>Enero 1, 2020</p>
            </div>
            <div className={`${styles.item}`}>
              <p className={styles.itemTitle}>Hamarteología</p>
              <p className={styles.date}>Febrero 1, 2020</p>
            </div>
          </div>
        </div>
      </section>*/}
    </>
  )
}

export default AcademicCourse
