import React from 'react'

import './DevotionalForm.css'

const DevotionalForm = () => {

  return (
    <form className="userInfo subjetcsInfo large" action="">
      <div className="fieldset">
        <label>Título</label>
        <div className="inputWrap">
          <input className="rounded" type="text" placeholder="Nombre de la materia"/>
          <span className="error">Lorem ipsum dolor sit amet</span>
        </div>
      </div>
      <div className="fieldset">
        <label>Portada</label>
        <div className="groupWrap">
          <div className="fakeImageInput rounded">
            <span className="title">teología_curso.jpg</span>
          </div>
        </div>
      </div>
      <div className="fieldset">
        <label>&nbsp;</label>
        <div className="inputWrap">
          <input type="file"/>
        </div>
      </div>
      <div className="fieldset">
        <label>Autor</label>
        <div className="inputWrap">
          <span className="select rounded">
            <select>
              <option>Jaime Hernández</option>
              <option>Eliseo Méndez</option>
              <option>Jorge Alcázar</option>
              <option>Ana Fuentes</option>
            </select>
          </span>
          <span className="error">Lorem ipsum dolor sit amet</span>
        </div>
      </div>
      <div className="fieldset">
        <div className="courseNotes">
          <fieldset></fieldset>
        </div>
      </div>
      <div className="fieldset">
        <label>Evaluación</label>
        <div className="inputWrapEval">
          <div>
            <span className="number">1</span>
            <input className="rounded" type="text" placeholder="Escribe pregunta"/>
            <span><a className="minus" href="#"></a></span>
          </div>
          <span className="error">Lorem ipsum dolor sit amet</span>
          <div>
            <span className="number">2</span>
            <input className="rounded" type="text" placeholder="Escribe pregunta"/>
            <span><a className="minus" href="#"></a></span>
          </div>
          <span className="error">Lorem ipsum dolor sit amet</span>
          <div>
            <span className="number">3</span>
            <input className="rounded" type="text" placeholder="Escribe pregunta"/>
            <span><a className="minus" href="#"></a></span>
          </div>
          <span className="error">Lorem ipsum dolor sit amet</span>
          <div>
            <span className="number">4</span>
            <input className="rounded" type="text" placeholder="Escribe pregunta"/>
            <span><a className="minus" href="#"></a></span>
          </div>
          <span className="error">Lorem ipsum dolor sit amet</span>
          <div>
            <span className="number">5</span>
            <input className="rounded" type="text" placeholder="Escribe pregunta"/>
            <span><a className="minus" href="#"></a></span>
          </div>
          <span className="error">Lorem ipsum dolor sit amet</span>

        </div>
      </div>
      <div className="fieldset">
        <label>&nbsp;</label>
        <div className="groupWrap"><a className="more" href="#"></a>
        </div>
      </div>
      <div className="fieldset right">
        <button className="btn purple" type="submit">Guardar</button>
      </div>
    </form>
  )
}

export default DevotionalForm