import React, { useEffect } from 'react'
// import { useDispatch, useSelector } from 'react-redux'
// import { getSubjectsFromStudent } from '../../../../../commons/redux/actions/subject.actions'
// import { selectAuthUser } from '../../../../../commons/redux/selectors/auth.selectors'
// import { selectSubjects } from '../../../../../commons/redux/selectors/subject.selectors'
import bgCourse01 from '../../../../../commons/assets/images/bg-course-1.png'
// import bgCourse02 from '../../../../../commons/assets/images/bg-course-2.png'
import bgCourse03 from '../../../../../commons/assets/images/bg-course-3.png'
// import imgCourseExample from '../../../../../commons/assets/images/img-subject-example-1.jpg'
import imgProfile from '../../../../../commons/assets/images/img-profile.jpg'
import imgProfile02 from '../../../../../commons/assets/images/img-profile-02.jpg'
import imgProfile03 from '../../../../../commons/assets/images/img-profile-03.jpg'

import $ from 'jquery'

import './Subjects.css'
import { useHistory } from 'react-router-dom'
import usePrefix from '../../../../../commons/hooks/usePrefix'

const Subjects = () => {
  const history = useHistory()
  const prefix = usePrefix()

  const handleRedirect = () => {
    history.push(`${prefix}/my-students/interaction`)
  }
  // const dispatch = useDispatch()

  // const userAuthSel = useSelector(selectAuthUser)
  // console.log(userAuthSel)

  // const subjects = useSelector(selectSubjects)
  // console.log('subjects: ', subjects)

  useEffect(() => {
    $('.gridTabCourses').gridtab({
      grid: 3,
      tabPadding: 0,
      borderWidth: 0,
      // minWidth: calc(30% + 0px),
      contentPadding: 0,
      responsive: [{
        breakpoint: 520,
        settings: {
          grid: 2
        }
      }]
    })

    $('.respTabsProfile').responsiveTabs({
      startCollapsed: false,
      active: 0
    })

    $('.accRow .accSelector').on('click', function () {
      $(this).toggleClass('open')
      $(this).parent().find('.accWrap').slideToggle()
    })
  }, [])

  /*useEffect(() => {
    if (userAuthSel) {
      const retrieveSubjectsFromStudent = async () => {
        await dispatch(getSubjectsFromStudent(userAuthSel.id))
      }

      retrieveSubjectsFromStudent().then(_ => console.log('All Subjects are loaded...'))
    }
  }, [])*/

  return (
    <>
      <section className="adminConsole">
        <div className="mainStudentConsole">
          <h2 className="bigTitle center bold"><span>Mis materias</span></h2>
          <div className="consoleSection consoleUsers">
            <div className="consoleUsersList">
              <dl className="gridTabCourses">
                <dt>
                  <div className="profileGrid threeCol">
                    <div className="profileCol">
                      <div className="imgContSubject"><img src={bgCourse01} alt=""/></div>
                      <p className="subjectName">Homelítica</p>
                      <p className="courseNameLeft">Curso básico</p>
                      <p className="class">Clase 2020</p>
                    </div>
                  </div>
                </dt>
                <dd>
                  <div className="profileGrid threeCol">
                    <div className="profileCol">
                      <div className="imgCont"><img src={imgProfile} alt=""/></div>
                      <p className="name">Ana Fuentes</p>
                      <div className="progress__bar">
                        <div className="bar"><span className="nocompleted" style={{ width: '75%' }}></span></div>
                        <p className="percentage">Avance de curso<span>75%</span></p>
                      </div>
                      <div className="fieldset center">
                        <button className="btn purple"
                                type="button"
                                onClick={handleRedirect}>
                          Evaluar
                        </button>
                      </div>
                      <div className="fieldset center">
                        <div className="ico-email"></div>
                      </div>
                    </div>
                    <div className="profileCol">
                      <div className="imgCont"><img src={imgProfile02} alt=""/></div>
                      <p className="name">Ana Fuentes</p>
                      <div className="progress__bar">
                        <div className="bar"><span className="completed" style={{ width: '100%' }}></span></div>
                        <p className="percentage">Avance de curso<span>100%</span></p>
                      </div>
                      <div className="fieldset center">
                        <button className="btn purple"
                                type="button"
                                onClick={handleRedirect}>
                          Evaluar
                        </button>
                      </div>
                      <div className="fieldset center">
                        <div className="ico-email"></div>
                      </div>
                    </div>
                    <div className="profileCol">
                      <div className="imgCont"><img src={imgProfile03} alt=""/></div>
                      <p className="name">Ana Fuentes</p>
                      <div className="progress__bar">
                        <div className="bar"><span style={{ width: '50%' }}></span></div>
                        <p className="percentage">Avance de curso<span>50%</span></p>
                      </div>
                      <div className="fieldset center">
                        <button className="btn purple"
                                type="button"
                                onClick={handleRedirect}>
                          Evaluar
                        </button>
                      </div>
                      <div className="fieldset center">
                        <div className="ico-email"></div>
                      </div>
                    </div>
                  </div>
                  <div className="groupBtn"><a className="btn btnGris" href="profesor-01-mis-alumnos.html">Cargar
                    más</a></div>
                </dd>
                <dt>
                  <div className="profileGrid threeCol">
                    <div className="profileCol">
                      <div className="imgContSubject"><img src={bgCourse01} alt=""/></div>
                      <p className="subjectName">Teología</p>
                      <p className="courseNameLeft">Curso avanzado</p>
                      <p className="class">Clase 2020</p>
                    </div>
                  </div>
                </dt>
                <dd>
                  <div className="profileGrid threeCol">
                    <div className="profileCol">
                      <div className="imgCont"><img src={imgProfile} alt=""/></div>
                      <p className="name">Ana Fuentes</p>
                      <div className="progress__bar">
                        <div className="bar"><span className="nocompleted" style={{ width: '75%' }}></span></div>
                        <p className="percentage">Avance de curso<span>75%</span></p>
                      </div>
                      <div className="fieldset center">
                        <button className="btn purple"
                                type="button"
                                onClick={handleRedirect}>
                          Evaluar
                        </button>
                      </div>
                      <div className="fieldset center">
                        <div className="ico-email"></div>
                      </div>
                    </div>
                    <div className="profileCol">
                      <div className="imgCont"><img src={imgProfile02} alt=""/></div>
                      <p className="name">Ana Fuentes</p>
                      <div className="progress__bar">
                        <div className="bar"><span className="completed" style={{ width: '100%' }}></span></div>
                        <p className="percentage">Avance de curso<span>100%</span></p>
                      </div>
                      <div className="fieldset center">
                        <button className="btn purple"
                                type="button"
                                onClick={handleRedirect}>
                          Evaluar
                        </button>
                      </div>
                      <div className="fieldset center">
                        <div className="ico-email"></div>
                      </div>
                    </div>
                    <div className="profileCol">
                      <div className="imgCont"><img src={imgProfile03} alt=""/></div>
                      <p className="name">Ana Fuentes</p>
                      <div className="progress__bar">
                        <div className="bar"><span style={{ width: '50%' }}></span></div>
                        <p className="percentage">Avance de curso<span>50%</span></p>
                      </div>
                      <div className="fieldset center">
                        <button className="btn purple"
                                type="button"
                                onClick={handleRedirect}>
                          Evaluar
                        </button>
                      </div>
                      <div className="fieldset center">
                        <div className="ico-email"></div>
                      </div>
                    </div>
                  </div>
                  <div className="groupBtn"><a className="btn btnGris" href="profesor-01-mis-alumnos.html">Cargar
                    más</a></div>
                </dd>
                <dt>
                  <div className="profileGrid threeCol">
                    <div className="profileCol">
                      <div className="imgContSubject"><img src={bgCourse03} alt=""/></div>
                      <p className="subjectName">Hermenéutica</p>
                      <p className="courseNameLeft">Curso especialidad</p>
                      <p className="class">Clase 2021</p>
                    </div>
                  </div>
                </dt>
                <dd>
                  <div className="profileGrid threeCol">
                    <div className="profileCol">
                      <div className="imgCont"><img src={imgProfile} alt=""/></div>
                      <p className="name">Ana Fuentes</p>
                      <div className="progress__bar">
                        <div className="bar"><span className="nocompleted" style={{ width: '75%' }}></span></div>
                        <p className="percentage">Avance de curso<span>75%</span></p>
                      </div>
                      <div className="fieldset center">
                        <button className="btn purple"
                                type="button"
                                onClick={handleRedirect}>
                          Evaluar
                        </button>
                      </div>
                      <div className="fieldset center">
                        <div className="ico-email"></div>
                      </div>
                    </div>
                    <div className="profileCol">
                      <div className="imgCont"><img src={imgProfile02} alt=""/></div>
                      <p className="name">Ana Fuentes</p>
                      <div className="progress__bar">
                        <div className="bar"><span className="completed" style={{ width: '100%' }}></span></div>
                        <p className="percentage">Avance de curso<span>100%</span></p>
                      </div>
                      <div className="fieldset center">
                        <button className="btn purple"
                                type="button"
                                onClick={handleRedirect}>
                          Evaluar
                        </button>
                      </div>
                      <div className="fieldset center">
                        <div className="ico-email"></div>
                      </div>
                    </div>
                    <div className="profileCol">
                      <div className="imgCont"><img src={imgProfile03} alt=""/></div>
                      <p className="name">Ana Fuentes</p>
                      <div className="progress__bar">
                        <div className="bar"><span style={{ width: '50%' }}></span></div>
                        <p className="percentage">Avance de curso<span>50%</span></p>
                      </div>
                      <div className="fieldset center">
                        <button className="btn purple"
                                type="button"
                                onClick={handleRedirect}>
                          Evaluar
                        </button>
                      </div>
                      <div className="fieldset center">
                        <div className="ico-email"></div>
                      </div>
                    </div>
                  </div>
                  <div className="groupBtn"><a className="btn btnGris" href="profesor-01-mis-alumnos.html">Cargar
                    más</a></div>
                </dd>
              </dl>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Subjects
