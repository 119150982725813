import React from 'react'

import './Desktop.css'

const Desktop = () => {

  return (
    <>
      <h4>MY CONSOLE :: DESKTOP</h4>
    </>
  )
}

export default Desktop
