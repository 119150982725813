import React from 'react'

import MySlider from '../../../../commons/components/MySlider/MySlider'
import UserProfile from '../../../../commons/components/UserProfile/UserProfile'
import MyCourses from './MyCourses/MyCourses'
import InviteFriend from '../../../../commons/components/InviteFriend/InviteFriend'
import Footer from '../../../../commons/components/Footer/Footer'

import styles from './Course.module.css'

const Course = () => {

  return (
    <div className={styles.mainContainer}>
      <MySlider/>
      <UserProfile roleId={3}/>
      <MyCourses/>
      <InviteFriend/>
      <Footer/>
    </div>
  )

}

export default Course
