import { fetchStudents, saveStudent, updateStudent } from '../services/student.services'
import Swal from 'sweetalert2'

import { LOADER_OFF, LOADER_ON } from './auth.actions'
export const GET_ALL_STUDENTS_INIT = 'GET_ALL_STUDENTS_INIT'
export const GET_ALL_STUDENTS_SUCCESS = 'GET_ALL_STUDENTS_SUCCESS'
export const GET_ALL_STUDENTS_ERROR = 'GET_ALL_STUDENTS_ERROR'
export const NEW_STUDENT_INIT = 'NEW_STUDENT_INIT'
export const NEW_STUDENT_SUCCESS = 'NEW_STUDENT_SUCCESS'
export const NEW_STUDENT_ERROR = 'NEW_STUDENT_ERROR'
export const GET_STUDENT_INIT = 'GET_STUDENT_INIT'
export const GET_STUDENT_SUCCESS = 'GET_STUDENT_SUCCESS'
export const GET_STUDENT_ERROR = 'GET_STUDENT_ERROR'
export const UPDATE_STUDENT_INIT = 'UPDATE_STUDENT_INIT'
export const UPDATE_STUDENT_SUCCESS = 'UPDATE_STUDENT_SUCCESS'
export const UPDATE_STUDENT_ERROR = 'UPDATE_STUDENT_ERROR'
export const DELETE_STUDENT_INIT = 'DELETE_STUDENT_INIT'
export const DELETE_STUDENT_SUCCESS = 'DELETE_STUDENT_SUCCESS'
export const DELETE_STUDENT_ERROR = 'DELETE_STUDENT_ERROR'

export function getStudents() {
  return async (dispatch) => {
    try {
      dispatch({ type: LOADER_ON })
      dispatch({ type: GET_ALL_STUDENTS_INIT })
      const result = await fetchStudents()
      console.log(result)
      if (result.data) {
        dispatch({ type: LOADER_OFF })
        dispatch({ type: GET_ALL_STUDENTS_SUCCESS, data: [...result.data.data] })
      } else {
        dispatch({ type: LOADER_OFF })
        dispatch({ type: GET_ALL_STUDENTS_ERROR })
        await Swal.fire(
          'Advertencia',
          'Ha ocurrido algo inesperado, contacte a soporte',
          'warning'
        )
      }
    } catch (e) {
      dispatch({ type: LOADER_OFF })
      dispatch({ type: GET_ALL_STUDENTS_ERROR })

      /*const sessionActive =
        e.response.data.detalles[0] ===
        'El usuario cuenta con una sesion activa, puedes intentar nuevamente despúes de 10 minutos.'
      if (sessionActive) {
        Swal.fire({
          title: 'Error',
          text: e.response.data.detalles[0],
          confirmButtonText: 'Aceptar'
        }).then(async (result) => {
          if (result.isConfirmed) {
            dispatch({ type: GET_ALL_STUDENTS_ERROR })
          }
        })
      } else {
        await Swal.fire('Error', e.response.data.detalles[0], 'warning')
      }*/
    }
  }
}

export function newStudent(values) {
  return async (dispatch) => {
    try {
      dispatch({ type: LOADER_ON })
      dispatch({ type: NEW_STUDENT_INIT })
      const result = await saveStudent(values)
      // console.log(result)
      dispatch({ type: NEW_STUDENT_SUCCESS, data: { ...result.data } })
      dispatch({ type: LOADER_OFF })
      await Swal.fire('Éxito', 'Operación se realizó con éxito', 'success')
    } catch (e) {
      console.log('newStudent: error: ', e)
      dispatch({ type: NEW_STUDENT_ERROR })
      dispatch({ type: LOADER_OFF })
      await Swal.fire('Error', 'Ocurrió algo inesperado, contacte a soporte', 'error')
    }
  }
}

export function editStudent(values) {
  return async (dispatch) => {
    try {
      dispatch({ type: LOADER_ON })
      dispatch({ type: UPDATE_STUDENT_INIT })
      const result = await updateStudent(values)
      // console.log(result)
      dispatch({ type: UPDATE_STUDENT_SUCCESS, data: { ...result.data } })
      dispatch({ type: LOADER_OFF })
      await Swal.fire('Éxito', 'Operación se realizó con éxito', 'success')
    } catch (e) {
      console.log('editStudent: error: ', e)
      dispatch({ type: UPDATE_STUDENT_ERROR })
      dispatch({ type: LOADER_OFF })
      await Swal.fire('Error', 'Ocurrió algo inesperado, contacte a soporte', 'error')
    }
  }
}

/*export function getStudent(studentId) {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_STUDENT_INIT })
      const result = await fetchStudent(studentId)
      // console.log(result)
      dispatch({ type: GET_STUDENT_SUCCESS, data: { ...result.data } })
    } catch (e) {
      console.log('retrieveStudent: error: ', e)
      dispatch({ type: GET_STUDENT_ERROR })
      await Swal.fire('Error', e.response.data.detalles[0], 'warning')
    }
  }
}

export function editStudent(values) {
  return async (dispatch) => {
    try {
      dispatch({ type: UPDATE_STUDENT_INIT })
      const result = await updateStudent(values)
      // console.log(result)
      dispatch({ type: UPDATE_STUDENT_SUCCESS, data: { ...result.data } })
    } catch (e) {
      console.log('editStudent: error: ', e)
      dispatch({ type: UPDATE_STUDENT_ERROR })
      await Swal.fire('Error', e.response.data.detalles[0], 'warning')
    }
  }
}

export function removeStudent(studentId) {
  return async (dispatch) => {
    try {
      dispatch({ type: DELETE_STUDENT_INIT })
      const result = await deleteStudent(studentId)
      // console.log(result)
      dispatch({ type: DELETE_STUDENT_SUCCESS, data: { ...result.data } })
    } catch (e) {
      console.log('removeStudent: error: ', e)
      dispatch({ type: DELETE_STUDENT_ERROR })
      await Swal.fire('Error', e.response.data.detalles[0], 'warning')
    }
  }
}*/
