import {
  CHECK_IF_USER_IS_LOGGED_IN,
  DELETE_USER_ERROR,
  DELETE_USER_INIT,
  DELETE_USER_SUCCESS,
  EXPIRE_PASSWORD,
  FOTGOT_PASSWORD_ERROR,
  FOTGOT_PASSWORD_INIT,
  FOTGOT_PASSWORD_SUCCESS,
  GET_ALL_USER_BY_ROLE_ERROR,
  GET_ALL_USER_BY_ROLE_INIT,
  GET_ALL_USER_BY_ROLE_SUCCESS,
  GET_MODULES_ERROR,
  GET_MODULES_INIT,
  GET_MODULES_SUCCESS,
  GET_USER_ERROR,
  GET_USER_INIT,
  GET_USER_SUCCESS,
  GET_USERS_ERROR,
  GET_USERS_INIT,
  GET_USERS_SUCCESS,
  LOADER_OFF,
  LOADER_ON,
  LOGIN_ERROR,
  LOGIN_INIT,
  LOGIN_SUCCESS,
  LOGOUT_ERROR,
  LOGOUT_INIT,
  LOGOUT_SUCCESS,
  NEW_PASSWORD_ERROR,
  NEW_PASSWORD_INIT,
  NEW_PASSWORD_SUCCESS,
  NEW_USER_ERROR,
  NEW_USER_INIT,
  NEW_USER_SUCCESS,
  REFRESH_TOKEN_ERROR,
  REFRESH_TOKEN_INIT,
  REFRESH_TOKEN_SUCCESS,
  SESSION_ERROR,
  SESSION_INIT,
  SESSION_SUCCESS,
  UPDATE_USER_ERROR,
  UPDATE_USER_INIT,
  UPDATE_USER_SUCCESS,
  USER_PROFILE_ERROR,
  USER_PROFILE_INIT,
  USER_PROFILE_SUCCESS
} from '../actions/auth.actions'

const initialState = {
  dataLoaded: false,
  isLoggedIn: false,
  expirePassword: false,
  loading: false,
  error: null,
  newUser: null,
  token: null,
  profile: null,
  user: null,
  results: [],
  value: '',
  tokenIsRefreshing: false
}

export default (state = initialState, action) => {
  switch (action.type) {
    case LOADER_ON:
      return { ...state, loading: true }
    case LOADER_OFF:
      return { ...state, loading: false }
    case CHECK_IF_USER_IS_LOGGED_IN:
      return {
        ...state,
        dataLoaded: true,
        isLoggedIn: !!(action.token && action.user),
        loading: false,
        token: action.token,
        user: action.user,
        profile: action.profile
      }
    case GET_ALL_USER_BY_ROLE_INIT:
      return { ...state, loading: true, users: [] }
    case GET_ALL_USER_BY_ROLE_SUCCESS:
      return { ...state, loading: false, users: [...action.users] }
    case GET_ALL_USER_BY_ROLE_ERROR:
      return { ...state, loading: false, error: action.error }
    case SESSION_INIT:
      return { ...state, loading: false }
    case SESSION_SUCCESS:
      console.log('USER: ', action.data.jwt_decode.user[0])
      return {
        ...state,
        loading: false,
        isLoggedIn: true,
        token: action.data.token,
        expiresIn: action.data.expiresIn,
        user: { ...action.data.jwt_decode.user[0] },
        profile: action.data.profile
      }
    case SESSION_ERROR:
      return { ...state, loading: false, isLoggedIn: false, error: action.error }
    case LOGIN_INIT:
      return { ...state, loading: false }
    case LOGIN_SUCCESS:
      return {
        ...state,
        loading: false,
        isLoggedIn: true,
        token: action.data.token,
        expiresIn: action.data.expiresIn,
        user: { ...action.data.user },
        profile: action.data.profile
      }
    case LOGIN_ERROR:
      return { ...state, loading: false, isLoggedIn: false, error: action.error }
    case EXPIRE_PASSWORD:
      return { ...state, loading: false, isLoggedIn: false, expirePassword: true, error: action.error }
    case LOGOUT_INIT:
      return { ...state, loading: true }
    case LOGOUT_SUCCESS:
      return { ...state, loading: false, isLoggedIn: false, user: null, profile: null }
    case LOGOUT_ERROR:
      return { ...state, loading: false, isLoggedIn: false, error: action.error }
    case REFRESH_TOKEN_INIT:
      return { ...state, loading: false, tokenIsRefreshing: true }
    case REFRESH_TOKEN_SUCCESS:
      return { ...state, loading: false, tokenIsRefreshing: false, refreshToken: { ...action.data.resultado } }
    case REFRESH_TOKEN_ERROR:
      return { ...state, loading: false, tokenIsRefreshing: false, error: action.error }
    case FOTGOT_PASSWORD_INIT:
      return { ...state, loading: true }
    case FOTGOT_PASSWORD_SUCCESS:
      return { ...state, loading: false, forgotPassword: { ...action.data } }
    case FOTGOT_PASSWORD_ERROR:
      return { ...state, loading: false, error: action.error }
    case NEW_PASSWORD_INIT:
      return { ...state, loading: true }
    case NEW_PASSWORD_SUCCESS:
      return { ...state, loading: false, newPassword: { ...action.data } }
    case NEW_PASSWORD_ERROR:
      return { ...state, loading: false, error: action.error }
    case USER_PROFILE_INIT:
      return { ...state, loading: true }
    case USER_PROFILE_SUCCESS:
      return { ...state, loading: false, profile: { ...action.data } }
    case USER_PROFILE_ERROR:
      return { ...state, loading: false, error: action.error }
    case GET_MODULES_INIT:
      return { ...state, loading: true }
    case GET_MODULES_SUCCESS:
      return { ...state, loading: false, modules: { ...action.data } }
    case GET_MODULES_ERROR:
      return { ...state, loading: false, error: action.error }
    case NEW_USER_INIT:
      return { ...state, loading: true, newUser: null }
    case NEW_USER_SUCCESS:
      return { ...state, loading: false, newUser: { ...action.data } }
    case NEW_USER_ERROR:
      return { ...state, loading: false, newUser: null, error: action.error }
    case GET_USERS_INIT:
      return { ...state, loading: true }
    case GET_USERS_SUCCESS:
      return { ...state, loading: false, users: { ...action.data } }
    case GET_USERS_ERROR:
      return { ...state, loading: false, error: action.error }
    case GET_USER_INIT:
      return { ...state, loading: true, viewUser: null }
    case GET_USER_SUCCESS:
      return { ...state, loading: false, viewUser: { ...action.data.resultado } }
    case GET_USER_ERROR:
      return { ...state, loading: false, viewUser: null, error: action.error }
    case UPDATE_USER_INIT:
      return { ...state, loading: true, editUser: null }
    case UPDATE_USER_SUCCESS:
      return { ...state, loading: false, editUser: { ...action.data.resultado } }
    case UPDATE_USER_ERROR:
      return { ...state, loading: false, editUser: null, error: action.error }
    case DELETE_USER_INIT:
      return { ...state, loading: true, deleteUser: null }
    case DELETE_USER_SUCCESS:
      return { ...state, loading: false, deleteUser: { ...action.data.resultado } }
    case DELETE_USER_ERROR:
      return { ...state, loading: false, deleteUser: null, error: action.error }
    default:
      return state
  }
}
