import React from 'react'
import { ErrorMessage, Field, Form, Formik } from 'formik'

import { editSubject, newSubject } from '../../../../../../../commons/redux/actions/subject.actions'
import { useDispatch } from 'react-redux'

import './CourseForm.css'

const CourseForm = ({ keyOp, periods = null, item = null }) => {

  const dispatch = useDispatch()

  const handleSubmit = async (values) => {
    if (keyOp === 'add') {
      const addSubject = async () => {
        await dispatch(newSubject(values))
      }
      addSubject().then(_ => {
        console.log('Subject added...')
      })
    } else if (keyOp === 'upd') {
      const updSubject = async () => {
        await dispatch(editSubject(values))

        /*if (grid) {
          const isActive = window.$('.is-active')
          isActive.removeClass('is-active')
          isActive.next().slideToggle()
        } else {
          window.$('.accSelector').removeClass('open')
          window.$(`.user_${user.id}`).slideToggle()
        }*/

      }
      updSubject().then(_ => console.log('Subject updated...'))
    }
  }

  return (
    <>
      <Formik
        initialValues={{
          id: item ? item.id : 0,
          name: item ? item.name : '',
          objective: item ? item.objective : '',
          period: item ? item.period.id : '',
          subjects: item ? item.subjects : 0,
          score: item ? item.score : 0,
          cost: item ? item.score : 0,
          date: item ? item.data : ''
        }}
        validate={(myValues) => {
          let myErrors = {}
          if (!myValues.name) {
            myErrors.name = 'Debes ingresar el nombre'
          }
          if (!myValues.objective) {
            myErrors.objective = 'Debes ingresar un objetivo'
          }
          if (!myValues.period) {
            myErrors.period = 'Debes elegir un periodo'
          }
          if (!myValues.subjects) {
            myErrors.subjects = 'Debes elegir materias por mes'
          }
          if (!myValues.score) {
            myErrors.score = 'Debes ingresar el valor curricular'
          }
          if (!myValues.cost) {
            myErrors.cost = 'Debes ingresar el costo'
          }
          return myErrors
        }}
        onSubmit={async (myValues, { resetForm }) => {
          if (keyOp === 'add') resetForm()
          await handleSubmit(myValues)
        }}
      >
        {({ errors }) => (
          <Form className="userInfo">
            <div className="fieldset">
              <label>Matrícula</label>
              <div className="inputWrap">
                <span className="select rounded">
                  <select>
                    <option>Ministerio pastoral</option>
                    <option>Artes crativas</option>
                  </select>
                </span>
                <span className="error">Lorem ipsum dolor sit amet</span>
              </div>
            </div>
            <div className="dataCourse">
              <p className="title">Nombre del curso</p>
              {/*<p className="identifier">Curso básico</p>*/}
              {/*<div className="editLink"><a href="#">Editar</a></div>*/}
              <div className="inputWrap">
                <Field className="rounded"
                       type="text"
                       id="name"
                       name="name"
                       placeholder=""
                />
                <ErrorMessage name="name" component={() => (<label className="error">{errors.name}</label>)}/>
              </div>
            </div>
            <div className="fieldset textarea">
              <label>Objetivo del curso</label>
              <Field as="textarea"
                     className="white"
                     id="objective"
                     name="objective"
                     placeholder="Escriba objectivo del curso"/>
              <ErrorMessage name="objective" component={() => (<label className="error">{errors.objective}</label>)}/>
            </div>
            {/*<div className="fieldset">
          <label>Tipo del curso</label>
          <div className="inputWrap">
            <span className="select rounded">
              <select>
                <option>Curricular</option>
                <option>Extracurricular</option>
                <option>De verano</option>
                <option>Otro</option>
              </select>
            </span>
            <span className="error">Lorem ipsum dolor sit amet</span>
          </div>
        </div>*/}
            <div className="fieldset">
              <label>Duración del curso</label>
              <div className="inputWrap">
                <span className="select rounded">
                  <Field as="select"
                         id="period"
                         name="period"
                  >
                    <option value="">Elije una opción</option>
                    {periods && periods.map(item => (
                        <option key={item.id} value={item.id}>{item.name}</option>
                      )
                    )}
                  </Field>
                  <ErrorMessage name="period" component={() => (<label className="error">{errors.period}</label>)}/>
                </span>
              </div>
            </div>
            <div className="fieldset">
              <label>Materias por mes</label>
              <div className="inputWrap">
            <span className="select rounded">
              <select>
                <option>1 materia</option>
                <option>2 materias</option>
                <option>3 materias</option>
              </select>
            </span>
                <span className="error">Lorem ipsum dolor sit amet</span>
              </div>
            </div>
            <div className="fieldset">
              <label>Créditos para aprobación</label>
              <div className="inputWrap">
                <Field className="rounded"
                       type="text"
                       id="score"
                       name="score"
                       placeholder=""
                />
                <ErrorMessage name="score" component={() => (<label className="error">{errors.score}</label>)}/>
              </div>
            </div>
            <div className="fieldset">
              <label>Costo de inscripción</label>
              <div className="inputWrap">
                <Field className="rounded"
                       type="text"
                       id="cost"
                       name="cost"
                       placeholder=""
                />
                <ErrorMessage name="cost" component={() => (<label className="error">{errors.cost}</label>)}/>
              </div>
            </div>
            <div className="fieldset twoCols">
              <div className="colsu">
                <div className="subjects">
                  <h3>Todas las materias</h3>
                  <div className="square">
                    <span className="word">Homilética</span>
                    <span className="word">Teología</span>
                    <span className="word">Hermenéutica</span
                    ><span className="word">Antiguo Testamento</span>
                    <span className="word">Profecía Bíblica</span>
                    <span className="word">Evangelios sinópticos</span>
                    <span className="word">Historia de la Iglesia</span>
                  </div>
                </div>
              </div>
              <div className="colsu">
                <div className="subjects">
                  <h3>Este curso</h3>
                  <div className="square">
                    <span className="word">Homilética<div className="close"></div></span>
                    <span className="word">Teología<div className="close"></div></span>
                    <span className="word">Hermenéutica<div className="close"></div></span>
                    <span className="word">Antiguo Testamento<div className="close"></div></span>
                    <span className="word">Profecía Bíblica<div className="close"></div></span>
                    <span className="word">Evangelios sinópticos<div className="close"></div></span>
                    <span className="word">Historia de la Iglesia<div className="close"></div></span>
                  </div>
                </div>
              </div>
            </div>
            <div className="fieldset">
              <label><strong>Curso completo.</strong> Ya no puedes agregar materias</label>
            </div>
            <div className="fieldset">
              <label>Inicio del curso</label>
              <div className="inputWrap">
                <Field type="text"
                       className="rounded"
                       id="date"
                       name="date"
                       placeholder="Escriba inicio del curso"/>
                <ErrorMessage name="date" component={() => (<label className="error">{errors.date}</label>)}/>
              </div>
            </div>
            <div className="fieldset">
              <label><strong>Fin de curso.</strong></label>
              <label>Lúnes, 17 de febrerode 2022</label>
            </div>
            <div className="fieldset right">
              <button type="submit" className="btn purple"> Iniciar curso</button>
              <button type="submit" className="btn purple"> Guardar</button>
            </div>
          </Form>
        )}
      </Formik>
    </>
  )
}

export default CourseForm