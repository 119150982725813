import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { ErrorMessage, Field, Form, Formik } from 'formik'

import FileItem from '../FileItem/FileItem'
import FileList from '../FileList/FileList'
import { editSubject, newSubject } from '../../../../../../../commons/redux/actions/subject.actions'

// import imgSubjectExample1 from '../../../../../../../commons/assets/images/img-subject-example-1.jpg'
// import imgProfile from '../../../../../../../commons/assets/images/img-profile.jpg'
// import iconBadgeAdd from '../../../../../../../commons/assets/images/icon-badge-add.svg'
import $ from 'jquery'

import './SubjectForm.css'

const SubjectForm = ({ keyOp, teachers = null, item = null }) => {
  const dispatch = useDispatch()

  const [videos, setVideos] = useState([])
  console.log('EnrollmentForm: videos: 🐙🐙🐙🐙🐙 ', videos)
  const [thumbnail, setThumbnail] = useState('')
  console.log('EnrollmentForm: thumbnail: 🐯🐯🐯🐯🐯 ', thumbnail)
  const [banner, setBanner] = useState('')
  console.log('EnrollmentForm: banner: 🐹🐹🐹🐹🐹 ', banner)

  const handleSubmit = async (values) => {
    console.log('handleSubmit: values: 🐱🐱🐱🐱🐱 ', values)

    if (keyOp === 'add') {
      const addSubject = async () => {
        await dispatch(newSubject(values))
      }
      addSubject().then(_ => {
        console.log('Subject added...')
      })
    } else if (keyOp === 'upd') {
      const updateSubject = async () => {
        await dispatch(editSubject(values))

        /*if (grid) {
          const isActive = window.$('.is-active')
          isActive.removeClass('is-active')
          isActive.next().slideToggle()
        } else {
          window.$('.accSelector').removeClass('open')
          window.$(`.user_${user.id}`).slideToggle()
        }*/

      }
      updateSubject().then(_ => console.log('Subject updated...'))
    }
  }

  return (
    <>
      <Formik
        initialValues={{
          id: item ? item.id : 0,
          name: item ? item.name : '',
          videos: item ? item.videos : videos,
          banner: item ? item.banner : banner,
          thumbnail: item ? item.thumbnail : thumbnail,
          type: item ? item.type.id : 1,
          teacher: item ? item.teachers[0].id : '',
          cost: item ? item.cost : '',
          score: item ? item.score : ''
        }}
        validate={(myValues) => {
          myValues.videos = videos
          myValues.thumbnail = thumbnail
          myValues.banner = banner
          let myErrors = {}
          if (!myValues.name) {
            myErrors.name = 'Debes ingresar el nombre'
          }
          if (!myValues.thumbnail) {
            myErrors.thumbnail = 'Debes subir una miniatura'
          }
          if (!myValues.banner) {
            myErrors.banner = 'Debes subir un banner'
          }
          if (!myValues.teacher) {
            myErrors.teacher = 'Debes elegir un profesor(a)'
          }
          if (!myValues.cost) {
            myErrors.cost = 'Debes ingresar el costo'
          }
          if (!myValues.score) {
            myErrors.score = 'Debes ingresar el valor curricular'
          }
          return myErrors
        }}
        onSubmit={async (myValues, { resetForm }) => {
          // if (keyOp === 'add') resetForm()
          await handleSubmit(myValues)
        }}
      >
        {({ errors }) => (
          <Form className="userInfo subjetcsInfo">
            <Field type="hidden" id="id" name="id"/>
            <Field type="hidden" id="type" name="type"/>
            <div className="fieldset">
              <label>Nombre</label>
              <div className="inputWrap">
                <Field className="rounded"
                       type="text"
                       id="name"
                       name="name"
                       placeholder=""
                />
                <ErrorMessage name="name" component={() => (<label className="error">{errors.name}</label>)}/>
              </div>
            </div>
            <div className="fieldset">
              <label>Profesor(a)</label>
              <div className="inputWrap">
                <span className="select rounded">
                  <Field component="select"
                         id="teacher"
                         name="teacher"
                  >
                    <option value="">Elije una opción</option>
                    {teachers && teachers.map(item => (
                      <option key={item.id} value={item.id}>{item.name} {item.paternal}</option>))}
                  </Field>
                  <ErrorMessage name="teacher" component={() => (<label className="error">{errors.teacher}</label>)}/>
                </span>
              </div>
            </div>

            {/*VIDEOS*/}
            <Field type="hidden" id="videos" name="videos" value={videos}/>
            <FileList label="Video(s)" name="videos" setValue={setVideos} errors={errors.videos}/>
            {/*
              <div className="fieldset">
                <label>Video(s)</label>
                <div className="groupWrap">
                  <div className="fakeImageInput rounded">
                    <img src={imgSubjectExample1} alt=""/>
                    <span className="title">Intro.mp4</span>
                    <span className="number">1</span>
                    <span className="close"></span>
                  </div>
                  <div className="fakeImageInput rounded">
                    <img src={imgSubjectExample1} alt=""/>
                    <span className="title">Intro.mp4</span>
                    <span className="number">2</span>
                    <span className="close"></span>
                  </div>
                  <div className="fakeImageInput rounded">
                    <img src={imgSubjectExample1} alt=""/>
                    <span className="title">Intro.mp4</span>
                    <span className="number">3</span>
                    <span className="close"></span>
                  </div>
                </div>
              </div>
              <div className="fieldset">
                <label>&nbsp;</label>
                <div className="inputWrapUpload">
                  <input type="file"/>
                  <button>
                    <i><img src={iconBadgeAdd}/></i> Cargar archivo
                  </button>
                </div>
              </div>
            */}

            {/*BANNER SLIDER*/}
            <Field type="hidden" id="thumbnail" name="thumbnail" value={thumbnail}/>
            <FileItem label="Miniatura" name="thumbnail" setValue={setThumbnail} errors={errors.thumbnail}/>

            {/*BANNER SLIDER*/}
            <Field type="hidden" id="banner" name="banner" value="banner"/>
            <FileItem label="Banner Slider" name="banner" setValue={setBanner} errors={errors.banner}/>

            <div className="fieldset">
              <label>Costo</label>
              <div className="inputWrap">
                <Field className="rounded"
                       type="text"
                       id="cost"
                       name="cost"
                       placeholder=""
                />
                <ErrorMessage name="cost" component={() => (<label className="error">{errors.cost}</label>)}/>
              </div>
            </div>
            <div className="fieldset">
              <label>Valor curricular</label>
              <div className="inputWrap">
                <Field className="rounded"
                       type="text"
                       id="score"
                       name="score"
                       placeholder=""
                />
                <ErrorMessage name="score" component={() => (<label className="error">{errors.score}</label>)}/>
              </div>
            </div>
            <div className="fieldset right">
              {keyOp === 'add' && (<a className="close" href="#" onClick={() => {
                $('.newRequest').slideToggle()
              }}>Cerrar</a>)}
              <button className="btn purple" type="submit">Guardar</button>
            </div>
          </Form>
        )}
      </Formik>
    </>
  )
}

export default SubjectForm