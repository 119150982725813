import React, { useEffect, useState } from 'react'
import axios from 'axios'

// import imgSubjectExample1 from '../../../../../../../commons/assets/images/img-subject-example-1.jpg'
import env from '../../../../../../../commons/network/env'

import './FileList.css'

const chunkSize = 10 * 1024

const FileList = ({ label, name, setValue, errors }) => {
  // const [dropzoneActive, setDropzoneActive] = useState(false)
  const [files, setFiles] = useState([])
  // console.log('FileList: files: 🐶🐶🐶🐶🐶 ', files)
  const [videos, setVideos] = useState([])
  // console.log('FileList: videos: 🐶🐶🐶🐶🐶 ', videos)
  const [currentFileIndex, setCurrentFileIndex] = useState(null)
  const [lastUploadedFileIndex, setLastUploadedFileIndex] = useState(null)
  const [currentChunkIndex, setCurrentChunkIndex] = useState(null)

  const handleFileSelected = (e) => {
    const file = e.target.files[0]
    setFiles([...files, file])
  }

  const readAndUploadCurrentChunk = () => {
    const reader = new FileReader()
    const file = files[currentFileIndex]
    if (!file) {
      return
    }
    const from = currentChunkIndex * chunkSize
    const to = from + chunkSize
    const blob = file.slice(from, to)
    reader.onload = (e) => uploadChunk(e)
    reader.readAsDataURL(blob)
  }

  const uploadChunk = (readerEvent) => {
    const file = files[currentFileIndex]
    const data = readerEvent.target.result

    const params = new URLSearchParams()
    params.set('name', file.name)
    params.set('size', file.size)
    params.set('currentChunkIndex', currentChunkIndex)
    params.set('totalChunks', Math.ceil(file.size / chunkSize))
    const headers = { 'Content-Type': 'application/octet-stream' }

    const url = `${env.RESOURCES_URL}/video/upload?${params.toString()}`
    // console.log('uploadChunk: url: 🐢🐢🐢🐢🐢 ->', url)
    axios.post(url, data, { headers })
      .then(response => {
        const file = files[currentFileIndex]
        const filesize = files[currentFileIndex].size
        const chunks = Math.ceil(filesize / chunkSize) - 1
        const isLastChunk = currentChunkIndex === chunks
        if (isLastChunk) {
          setVideos([...videos, response.data.data.url])
          setValue([...videos, response.data.data.url])
          file.finalFilename = response.data.finalFilename
          setLastUploadedFileIndex(currentFileIndex)
          setCurrentChunkIndex(null)
        } else {
          setCurrentChunkIndex(currentChunkIndex + 1)
        }
      })
  }

  useEffect(() => {
    if (lastUploadedFileIndex === null)
      return

    const isLastFile = lastUploadedFileIndex === files.length - 1
    const nextFileIndex = isLastFile ? null : currentFileIndex + 1
    setCurrentFileIndex(nextFileIndex)
  }, [lastUploadedFileIndex])

  useEffect(() => {
    if (files.length > 0) {
      if (currentFileIndex === null) {
        setCurrentFileIndex(lastUploadedFileIndex === null ? 0 : lastUploadedFileIndex + 1)
      }
    }
  }, [files.length])

  useEffect(() => {
    if (currentFileIndex !== null) {
      setCurrentChunkIndex(0)
    }
  }, [currentFileIndex])

  useEffect(() => {
    if (currentChunkIndex !== null) {
      readAndUploadCurrentChunk()
    }
  }, [currentChunkIndex])

  return (
    <>
      <div className="fieldset">
        <label>{label}</label>
        <div className="groupWrap">
          {/*<div className="fakeImageInput rounded">
            <img src={imgSubjectExample1} alt=""/>
            <span className="title">Intro.mp4</span>
            <span className="number">1</span>
            <span className="close"></span>
          </div>
          <div className="fakeImageInput rounded">
            <img src={imgSubjectExample1} alt=""/>
            <span className="title">Intro.mp4</span>
            <span className="number">2</span>
            <span className="close"></span>
          </div>
          <div className="fakeImageInput rounded">
            <img src={imgSubjectExample1} alt=""/>
            <span className="title">Intro.mp4</span>
            <span className="number">3</span>
            <span className="close"></span>
          </div>*/}

          <div>
            {
              files.map((file, fileIndex) => {
                let uploading = false
                let progress = 0
                if (file.finalFilename) {
                  progress = 100
                } else {
                  uploading = fileIndex === currentFileIndex
                  const chunks = Math.ceil(file.size / chunkSize)
                  // console.log(`file: ${file.name}, currentChunkIndex: ${currentChunkIndex}`)
                  if (uploading) {
                    progress = Math.round(currentChunkIndex / chunks * 100)
                  } else {
                    progress = 0
                  }
                }

                return (
                  <a key={`f_${fileIndex}`}
                     className="file fakeImageInput rounded">
                    <div className={'name ' + (!uploading ? 'complete' : '')}>{file.name}</div>
                    <span className={(!uploading ? 'close' : '')}
                          onClick={() => console.log(`remove item ${file.name}`)}>
                  </span>
                    <div className={'progress ' + (!uploading ? 'done' : '')}
                         style={{ width: progress + '%' }}>{progress}%
                    </div>
                  </a>
                  /*
                    <div key={`f_${fileIndex}`}
                         className="fakeImageInput rounded">
                      <div className={'progress ' + (progress === 100 ? 'done' : '')}
                           style={{ width: progress + '%' }}>{progress}%
                        {/!*<img src={imgSubjectExample1} alt=""/>*!/}
                        <span className="title">{file.name}</span>
                        <span className="number">{fileIndex}</span>
                        <span className="close"></span>
                      </div>
                    </div>
                  */
                )
              })}
          </div>

        </div>
      </div>
      <div className="fieldset">
        <label>&nbsp;</label>
        <div className="inputWrapUpload">
          <input type="file"
                 id={name}
                 name={name}
                 onChange={handleFileSelected}/>
          <button>
            <i className="fa fa-plus"></i> Cargar archivo...
          </button>
        </div>
      </div>
    </>
  )
}

export default FileList