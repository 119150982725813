import React from 'react'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import bgSlider01 from '../../assets/images/bg-slider-01.jpg'
import bgSlider02 from '../../assets/images/bg-slider-02.jpg'
import bgSlider03 from '../../assets/images/bg-slider-03.jpg'
import imgSlider01 from '../../assets/images/img-slider-01.png'
import logoNamibhBlanco from '../../assets/images/logo-namibh-blanco.svg'

import './MySlider.css'

const MySlider = () => {

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  }

  return (
    <section className="topSlider">

      <Slider {...settings}>
        <div>
          <div className="topSliderCarousel">
            <div style={{ backgroundImage: `url(${bgSlider01})` }} className="item">
              <div className="itemText white">
                <div className="logoNamibhWhite">
                  <img src={logoNamibhBlanco} width="282" height="100" alt="Instituto Bíblico Hispano"/>
                </div>
                <p className="title">Homilética</p>
                <p>Jaime Hernández</p><a href="#" className="btn btnTrans noRounded lightedFont">Curso Básico</a>
              </div>
              <div className="imgObject"><img src={imgSlider01} alt="Jaime Hernández"/></div>
            </div>
          </div>
        </div>
        <div>
          <div className="topSliderCarousel">
            <div style={{ backgroundImage: `url(${bgSlider02})` }} className="item">
              <div className="itemText white">
                <div className="logoNamibhWhite">
                  <img src={logoNamibhBlanco} width="282" height="100" alt="Instituto Bíblico Hispano"/>
                </div>
                <p className="title">Hermenéutica</p>
                <p>Alfredo Botello</p><a href="#" className="btn btnTrans noRounded lightedFont">Curso Básico</a>
              </div>
              <div className="imgObject"><img src={imgSlider01} alt="Alfredo Botello"/></div>
            </div>
          </div>
        </div>
        <div>
          <div className="topSliderCarousel">
            <div style={{ backgroundImage: `url(${bgSlider03})` }} className="item">
              <div className="itemText white">
                <div className="logoNamibhWhite">
                  <img src={logoNamibhBlanco} width="282" height="100" alt="Instituto Bíblico Hispano"/>
                </div>
                <p className="title">Teología</p>
                <p>Erick T. García</p><a href="#" className="btn btnTrans noRounded lightedFont">Curso Básico</a>
              </div>
              <div className="imgObject"><img src={imgSlider01} alt="Erick T. García"/></div>
            </div>
          </div>
        </div>

        {/*<div className="topSliderCarousel">
        <div style={{ backgroundImage: `url(${bgSlider01})` }} className="item">
          <div className="itemText white">
            <div className="logoNamibhWhite">
              <img src={logoNamibhBlanco} width="282" height="100" alt="Instituto Bíblico Hispano"/>
            </div>
            <p className="title">Homilética</p>
            <p>Jaime Hernández</p><a href="#" className="btn btnTrans noRounded lightedFont">Curso Básico</a>
          </div>
          <div className="imgObject"><img src={logoNamibhBlanco} alt="Jaime Hernández"/></div>
        </div>
      </div>
      <div className="topSliderCarousel">
        <div style={{ backgroundImage: `url(${bgSlider01})` }} className="item">
          <div className="itemText white">
            <div className="logoNamibhWhite">
              <img src={logoNamibhBlanco} width="282" height="100" alt="Instituto Bíblico Hispano"/>
            </div>
            <p className="title">Homilética</p>
            <p>Jaime Hernández</p><a href="#" className="btn btnTrans noRounded lightedFont">Curso Básico</a>
          </div>
          <div className="imgObject"><img src={logoNamibhBlanco} alt="Jaime Hernández"/></div>
        </div>
      </div>*/}
      </Slider>
    </section>
  )
}

export default MySlider
