import React from 'react'

import SubjectContainer from '../commons/SubjectContainer/SubjectContainer'

// import imgSubjectExample1 from '../../../../../../commons/assets/images/img-subject-example-1.jpg'
// import imgSubjectExample2 from '../../../../../../commons/assets/images/img-subject-example-2.jpg'
// import imgSubjectExample3 from '../../../../../../commons/assets/images/img-subject-example-3.jpg'
// import imgSubjectExample4 from '../../../../../../commons/assets/images/img-subject-example-4.jpg'
// import imgSubjectExample5 from '../../../../../../commons/assets/images/img-subject-example-5.jpg'
// import imgSubjectExample6 from '../../../../../../commons/assets/images/img-subject-example-6.jpg'

import './SubjectsGrid.css'

const SubjectsGrid = ({ keyOp, teachers, items }) => {
  console.log(items)
  return (
    <dl className="gridTabCourses">
      {
        items && items.map(item => (
          <React.Fragment key={`sbg_${item.id}`}>
            <dt>
              <div className="userCard square">
                <div className="imgProfile">
                  <img src={item.thumbnail}/>
                </div>
                <p className="name">{item.name}</p>
                <p className="role">Curso Básico</p>
              </div>
            </dt>
            <dd>
              <SubjectContainer keyOp={keyOp}
                                teachers={teachers}
                                item={item}/>
            </dd>
          </React.Fragment>
        ))
      }

      {/*ESTUDIANTE 1
      <dt>
        <div className="userCard square">
          <div className="imgProfile">
            <img src={imgSubjectExample1}/>
          </div>
          <p className="name">Hermenéutica</p>
          <p className="role">Curso Básico</p>
        </div>
      </dt>
      <dd>
        <EnrollmentContainer keyId="1"/>
      </dd>

      ESTUDIANTE 2
      <dt>
        <div className="userCard square">
          <div className="imgProfile">
            <img src={imgSubjectExample2} alt=""/>
          </div>
          <p className="name">Teología</p>
          <p className="role">Curso Básico</p>
        </div>
      </dt>
      <dd>
        <EnrollmentContainer keyId="2"/>
      </dd>

      ESTUDIANTE 3
      <dt>
        <div className="userCard square">
          <div className="imgProfile">
            <img src={imgSubjectExample3} alt=""/>
          </div>
          <p className="name">Antiguo Testamento</p>
          <p className="role">Curso Básico</p>
        </div>
      </dt>
      <dd>
        <EnrollmentContainer keyId="3"/>
      </dd>

      ESTUDIANTE 4
      <dt>
        <div className="userCard square">
          <div className="imgProfile">
            <img src={imgSubjectExample4} alt=""/>
          </div>
          <p className="name">Homilética</p>
          <p className="role">Curso Básico</p>
        </div>
      </dt>
      <dd>
        <EnrollmentContainer keyId="4"/>
      </dd>

      ESTUDIANTE 5
      <dt>
        <div className="userCard square">
          <div className="imgProfile">
            <img src={imgSubjectExample5} alt=""/>
          </div>
          <p className="name">Profecía Bíblica</p>
          <p className="role">Curso Básico</p>
        </div>
      </dt>
      <dd>
        <EnrollmentContainer keyId="5"/>
      </dd>

      ESTUDIANTE 6
      <dt>
        <div className="userCard square">
          <div className="imgProfile">
            <img src={imgSubjectExample6} alt=""/>
          </div>
          <p className="name">Evangelios Sinópticos</p>
          <p className="role">Curso Básico</p>
        </div>
      </dt>
      <dd>
        <EnrollmentContainer keyId="6"/>
      </dd>

      ESTUDIANTE 7
      <dt>
        <div className="userCard square">
          <div className="imgProfile">
            <img src={imgSubjectExample1} alt=""/>
          </div>
          <p className="name">Profecía Bíblica</p>
          <p className="role">Curso Básico</p>
        </div>
      </dt>
      <dd>
        <EnrollmentContainer keyId="7"/>
      </dd>

      ESTUDIANTE 8
      <dt>
        <div className="userCard square">
          <div className="imgProfile">
            <img src={imgSubjectExample2} alt=""/>
          </div>
          <p className="name">Evangelios Sinópticos</p>
          <p className="role">Curso Básico</p>
        </div>
      </dt>
      <dd>
        <EnrollmentContainer keyId="8"/>
      </dd>*/}

    </dl>
  )
}

export default SubjectsGrid