import React from 'react'
import { Watch } from 'react-loader-spinner'

import './Loader.css'

export const Loader = () => {
  return (
    <div className="Loader">
      <div>
        <Watch color="#ffffff" height={70} width={70}/>
      </div>
    </div>
  )
}
