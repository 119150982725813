import React, { useEffect, useState } from 'react'

import VideoClass from '../VideoClass/VideoClass'
import SubjectInfo from '../SubjectInfo/SubjectInfo'

import styles from './Steps.module.css'

const Steps = ({ subjectName, lessons, teacher }) => {
  const [currentLesson, setCurrentLesson] = useState(0)

  const handleCurrentLesson = (idx) => {
    setCurrentLesson(idx)
  }

  const handleNextLesson = (idx) => {
    if (currentLesson < lessons.length - 1) {
      setCurrentLesson(idx)
    }
  }

  useEffect(() => {
    if (lessons && lessons.length > 0) {
      setCurrentLesson(0)
    }
  }, [])

  return (
    <>
      {
        lessons && lessons.length > 0 && (
          <>
            <VideoClass currenLesson={currentLesson}
                        lesson={lessons[currentLesson]}
                        nextLesson={handleNextLesson}
            />
            <SubjectInfo subjectName={subjectName}
                         lesson={lessons[currentLesson]}
                         teacher={teacher}/>
            <section className={styles.courseSteps}>
              <ul>
                {
                  lessons.map((lesson, idx) => (
                    // TODO: Verificar el estado de la lección para marcarla
                    //        según corresponsa: complete, active, ''
                    <li key={`l_${lesson.id}`}
                        onClick={() => handleCurrentLesson(idx)}
                        className={`${idx === currentLesson ? styles.active : ''}`}>
                      {idx + 1}
                    </li>
                  ))
                }
                {/*<li className={styles.complete}>1</li>
                  <li className={styles.complete}>2</li>
                  <li className={styles.active}>3</li>
                  <li>4</li>
                  <li>5</li>*/}
              </ul>
              <div className={styles.courseAlerts}>
                <p className={styles.orange}>Quedan 48 horas para concluir este video</p>
                <p className={styles.green}>Quedan 31 días para culminar esta materia</p>
              </div>
            </section>
          </>
        )
      }
    </>
  )
}

export default Steps
