import {
  GET_ALL_STUDENTS_ERROR,
  GET_ALL_STUDENTS_INIT,
  GET_ALL_STUDENTS_SUCCESS,
  NEW_STUDENT_INIT,
  NEW_STUDENT_SUCCESS,
  NEW_STUDENT_ERROR,
  UPDATE_STUDENT_INIT,
  UPDATE_STUDENT_SUCCESS,
  UPDATE_STUDENT_ERROR, newStudent
} from '../actions/student.actions'

const initialState = {
  loading: false,
  error: null,
  newStudent: null,
  student: null
}

export default (state = initialState, action) => {
  let descendingOrderedStudents = []
  switch (action.type) {
    case GET_ALL_STUDENTS_INIT:
      return { ...state, loading: true, students: [] }
    case GET_ALL_STUDENTS_SUCCESS:
      descendingOrderedStudents = [...action.data].sort((a, b) => b.id - a.id)
      return { ...state, students: descendingOrderedStudents, loading: false }
    case GET_ALL_STUDENTS_ERROR:
      return { ...state, loading: false, error: action.error }
    case NEW_STUDENT_INIT:
      return { ...state, loading: true, student: {} }
    case NEW_STUDENT_SUCCESS:
      descendingOrderedStudents = [...state.students, { ...action.data }].sort((a, b) => b.id - a.id)
      return { ...state, student: { ...action.data }, students: descendingOrderedStudents, loading: false }
    case NEW_STUDENT_ERROR:
      return { ...state, loading: false, student: {}, error: action.error }
    case UPDATE_STUDENT_INIT:
      return { ...state, loading: true }
    case UPDATE_STUDENT_SUCCESS:
      const filterStudents = state.students.filter(student => student.id != action.data.id)
      descendingOrderedStudents = [...filterStudents, { ...action.data }].sort((a, b) => b.id - a.id)
      return { ...state, loading: false, students: descendingOrderedStudents }
    case UPDATE_STUDENT_ERROR:
      return { ...state, loading: false, error: action.error }
    default:
      return state
  }
}
