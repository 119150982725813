import React, { useEffect, useState } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { getTeachers } from '../../../../../commons/redux/actions/teacher.actions'
import { selectTeacher } from '../../../../../commons/redux/selectors/teacher.selectors'
import TeacherForm from './commons/TeacherForm/TeacherForm'
import TeachersGrid from './TeachersGrid/TeachersGrid'
import TeachersList from './TeachersList/TeachersList'

import $ from 'jquery'

import './Teachers.css'

const Teachers = () => {
  const dispatch = useDispatch()
  const { teachers } = useSelector(selectTeacher)
  console.log(teachers)

  const [gridView, setGridView] = useState(true)

  const toEnableGrid = () => {
    $('.gridTeachers').gridtab({
      grid: 4,
      tabPadding: 0,
      borderWidth: 0,
      contentPadding: 0,
      responsive: [
        {
          breakpoint: 760,
          settings: {
            grid: 3
          }
        },
        {
          breakpoint: 520,
          settings: {
            grid: 2
          }
        }
      ]
    })

    $('.accRow .accSelector').on('click', function () {
      $(this).toggleClass('open')
      $(this).parent().find('.accWrap').slideToggle()
    })
  }

  useEffect(() => {
    const retrieveTeachers = async () => {
      await dispatch(getTeachers())
    }

    retrieveTeachers().then(_ => {
      console.log('All Teachers are loaded...')
      toEnableGrid()
    })
  }, [])

  return (
    <>
      <div className="consoleSection consoleHeader">
        <h2 className="sectionHeader withElements"><span>Profesores</span>
          <ul className="view">
            {/*<li>
              <a className="search" href="#" onClick={() => {
                $('.newSearch').slideToggle()
              }}>
              </Link>
            </li>*/}
            <li>
              <a className="create" href="#" onClick={() => {
                $('.newRequest').slideToggle()
              }}>
              </a>
            </li>
            <li>
              <a className={gridView ? 'viewList' : 'viewGrid'} href="#" onClick={
                () => setGridView(!gridView)
              }>
              </a>
            </li>
          </ul>
        </h2>
      </div>

      {/*FILTROS*/}
      {/*<div className="consoleClass">
        <div className="sectionSelect">
          <div className="gridClass">
            <div className="fieldset">
              <span className="select select-color square">
                <select className="color-filter">
                  <option>Matrícula</option>
                  <option>Clase 2019</option>
                  <option>Clase 2020</option>
                  <option>Clase 2021</option>
                </select>
              </span>
            </div>

            <div className="fieldset">
              <span className="select select-color-gray square">
                <select className="color-gray-filter">
                  <option>Estado</option>
                  <option>Avance de curso</option>
                  <option>Estatus de inscripción</option>
                  <option>Mas recientes</option>
                  <option>Mas antiguos</option>
                  <option>Alfabéticamente (A..Z)</option>
                </select>
              </span>
            </div>
          </div>
        </div>
      </div>*/}

      {/*BUSQUEDAS*/}
      {/*<div className="newSearch" style={{display: 'none'}}>
        <TeacherSearch/>
      </div>*/}

      {/*NUEVO ALUMNO*/}
      <div className="newRequest" style={{ display: 'none' }}>
        <TeacherForm keyOp="add"/>
      </div>

      <div className="consoleSection consoleUsers">
        <div className="consoleUsersList">
          {teachers && teachers.length > 0 && (
            <>
              <div style={{ display: `${gridView ? 'block' : 'none'}` }}>
                <TeachersGrid keyOp="upd"
                              items={teachers}/>
              </div>
              <div style={{ display: `${!gridView ? 'block' : 'none'}` }}>
                <TeachersList keyOp="upd"
                              items={teachers}/>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  )
}

export default Teachers
