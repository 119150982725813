import React from 'react'
import { NavLink } from 'react-router-dom'

import usePrefix from '../../../../../commons/hooks/usePrefix'
import imgProfile from '../../../../../commons/assets/images/img-profile.jpg'

import './AdministratorProfile.css'

const AdministratorProfile = () => {
  const prefix = usePrefix()

  return (
    <>
      {/*GENERAL*/}
      <div className="consoleSection consoleProfile">
        <h2 className="sectionHeader">General</h2>
        <div className="consoleProfileData">
          <div className="photoWrap">
            <p className="title">Foto de perfil</p>
            <div className="photo">
              <div className="imgProfile">
                <img src={imgProfile}/>
              </div>
              <div className="editLink"><NavLink to={`${prefix}/photo`}> Editar </NavLink></div>
            </div>
          </div>
          <div className="dataWrap">
            <p className="title">Nombre</p>
            <p className="identifier">Jaime Hernández</p>
            <div className="editLink"><NavLink to={`${prefix}/name`}> Editar </NavLink></div>
          </div>
          <div className="dataWrap">
            <p className="title">Correo electrónico</p>
            <p className="identifier">j.hdz@correo.com</p>
            <div className="editLink"><NavLink to={`${prefix}/email`}> Editar </NavLink></div>
          </div>
          <div className="dataWrap">
            <p className="title">Usuario</p>
            <p className="identifier">jhdz001</p>
            <div className="editLink"><NavLink to={`${prefix}/username`}> Editar </NavLink></div>
          </div>
        </div>
      </div>

      {/*SEGURIDAD*/}
      <div className="consoleSection consoleSecurity">
        <h2 className="sectionHeader">Seguridad</h2>
        <form action="#">
          <div className="fieldset">
            <label>Nueva contraseña</label>
            <div className="inputWrap">
              <input type="password" className="rounded"/><span className="error">Lorem ipsum dolor sit amet</span>
            </div>
          </div>
          <div className="fieldset">
            <label>Confirmar contraseña</label>
            <div className="inputWrap">
              <input type="password" className="rounded"/><span className="error">Lorem ipsum dolor sit amet</span>
            </div>
          </div>
          <div className="fieldset">
            <label>Código de verificación</label>
            <div className="inputWrap">
              <div className="codeVerify">
                <input type="text" className="rounded"/>
                <input type="text" className="rounded"/>
                <input type="text" className="rounded"/>
                <input type="text" className="rounded"/>
                <input type="text" className="rounded"/>
                <input type="text" className="rounded"/>
              </div>
              <span className="error">Lorem ipsum dolor sit amet</span>
            </div>
          </div>
          <div className="message">
            <span>Se ha enviado un correo electrónico a j***********@correo.com</span>
          </div>
          <div className="fieldset right">
            <button type="submit" className="btn purple">Verificar</button>
          </div>
        </form>
      </div>
    </>
  )
}

export default AdministratorProfile
