import React from 'react'

import './Payments.css'

const Payments = () => {

  return (
    <>
      <h4>MY CONSOLE :: PAYMENTS</h4>
    </>
  )
}

export default Payments
