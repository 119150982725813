import axiosClient from '../../network/apiClient'

import { STUDENT_ENDPOINT, ENROLLMENT_ENDPOINT } from '../../const'

export const fetchEnrollments = async () => {
  const url = ENROLLMENT_ENDPOINT.ENROLLMENTS
  const method = 'get'
  const encryptKeys = null
  const headers = {
    // Authorization: `Bearer ${token}`
  }

  const params = {}
  // console.log(params)

  return await axiosClient({
    method,
    url,
    data: params,
    headers,
    encryptKeys
  })
}

export const fetchEnrollmentsFromStudent = async (id) => {
  const token = sessionStorage.getItem('_k')
  const url = `${STUDENT_ENDPOINT.STUDENTS}/${id}/subjects`
  const method = 'get'
  const encryptKeys = null
  const headers = {
    Authorization: `Bearer ${token}`
  }

  const params = {}
  // console.log(params)

  return await axiosClient({
    method,
    url,
    data: params,
    headers,
    encryptKeys
  })
}

export const saveEnrollment = async (values) => {
  const url = ENROLLMENT_ENDPOINT.ENROLLMENTS
  const method = 'post'
  const encryptKeys = null
  const headers = {}

  const params = {
    ...values
  }
  // console.log(params)

  return await axiosClient({
    method,
    url,
    data: params,
    headers,
    encryptKeys
  })
}

export const updateEnrollment = async (item) => {
  console.log(item)
  const url = `${ENROLLMENT_ENDPOINT.ENROLLMENTS}/${item.id}`
  const method = 'put'
  const encryptKeys = null
  const headers = {}

  const params = {
    ...item
  }
  // console.log(params)

  return await axiosClient({
    method,
    url,
    data: params,
    headers,
    encryptKeys
  })
}


/*
export const fetchEnrollment = async (subjectId) => {
  const url = `${ENROLLMENT_ENDPOINT.GET_ENROLLMENTS}/${subjectId}`
  const method = 'get'
  const encryptKeys = null
  const headers = {}

  const params = {}
  // console.log(params)

  return await axiosClient({
    method,
    url,
    data: params,
    headers,
    encryptKeys
  })
}

export const deleteEnrollment = async (subjectId) => {
  const url = '/usuarios/actualizar-estatus'
  const method = 'put'
  const encryptKeys = null
  const headers = {}

  const params = {
    id: subjectId
  }
  // console.log(params)

  return await axiosClient({
    method,
    url,
    data: params,
    headers,
    encryptKeys
  })
}
*/
