import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { useIdle } from './useIdle'
import { getJwtRemainingTimeToExpire, removeDataInStorage } from '../utils'
import {
  changePassword,
  forgotPassword,
  getModules,
  getUserProfile,
  login,
  newPassword,
  refreshToken,
  session
} from '../redux/actions/auth.actions'
import { selectAuth, selectRefreshToken, selectTokenIsRefreshing } from '../redux/selectors/auth.selectors'
import { prefixes } from '../router/constants'
import { Redirect } from 'react-router-dom'
// import { useHeaders } from './useHeaders'
// import { AESCrypto, RSACrypto } from '../crypto'

const REFRESH_THRESHOLD_IN_SECONDS = 15
const IDLE_MAX_TIME_IN_MILISECONDS = 30000

export const useSession = () => {
  // const { fetchHeaders } = useHeaders()
  const dispatch = useDispatch()

  const auth = useSelector(selectAuth)
  const isLoggedIn = auth.isLoggedIn
  const selRefreshToken = useSelector(selectRefreshToken)
  const tokenIsRefreshing = useSelector(selectTokenIsRefreshing)
  const [isLoading, setIsLoading] = useState(false)
  const [tokens, setTokens] = useState({ jwt: '', renew: '' })
  const [timeLeft, setTimeLeft] = useState(0)
  const { userIsActive } = useIdle({ timeToIdle: IDLE_MAX_TIME_IN_MILISECONDS })

  useEffect(() => {
    // const { token, expiresIn } = auth
    const token = sessionStorage.getItem('_k')
    const expiresIn = sessionStorage.getItem('_e')
    setTokens({
      jwt: token,
      renew: expiresIn
    })
  }, [isLoggedIn])

  useEffect(() => {
    // if (!timeLeft) return
    if (!tokens.jwt) return

    const timeToExpire = getJwtRemainingTimeToExpire(tokens.jwt)
    setTimeLeft(timeToExpire)

    const intervalId = setInterval(() => {
      if (timeLeft > 0) setTimeLeft((prevTimeLeft) => prevTimeLeft - 1)
    }, 1000)

    return () => clearInterval(intervalId)
  }, [tokens, timeLeft])
  // }, [tokens])

  useEffect(() => {
    const refreshJwt = async () => {
      try {
        if (!userIsActive) {
          /*if (onSignIn) {
            await onSignIn(false)
          }*/

          if (isLoggedIn) await localLogout()
          return
        }
        await dispatch(refreshToken())
      } catch (err) {
        console.log(err)
      } finally {
      }
    }
    const isLoggedIn = !!sessionStorage.getItem('_unknown')

    // console.log('*----------------SECURITY----------------*')
    // console.log('userIsActive                : ', userIsActive)
    // console.log('timeLeft                    : ', timeLeft)
    // console.log('REFRESH_THRESHOLD_IN_SECONDS: ', REFRESH_THRESHOLD_IN_SECONDS)
    // console.log('!tokenIsRefreshing          : ', !tokenIsRefreshing)
    // console.log('︎︎︎︎︎︎︎︎isLoggedIn                  : ', isLoggedIn)
    // console.log('condition                   : ', (timeLeft <= REFRESH_THRESHOLD_IN_SECONDS && !tokenIsRefreshing && isLoggedIn))
    // console.log('*---------------SECURITY-----------------*')
    if (
      timeLeft > 0 &&
      timeLeft <= +REFRESH_THRESHOLD_IN_SECONDS &&
      !tokenIsRefreshing &&
      isLoggedIn
    ) {
      console.log('before execution refreshJwt()')
      refreshJwt().then((_) => {
        if (userIsActive) {
          console.log('token refreshed...')
        } else {
          console.log('logout executed...')
        }
      })
    }

    return () => {
      // console.log('unmounted....')
    }
  }, [timeLeft, tokenIsRefreshing])

  useEffect(() => {
    if (selRefreshToken) {
      const { token, expiracionToken } = selRefreshToken

      // FOR COOKIES
      // storeJwtAndRenew(tokenAcceso, tokenRenovacion)

      setTokens({
        jwt: token,
        renew: expiracionToken
      })

      const timeToExpire = getJwtRemainingTimeToExpire(token)
      setTimeLeft(timeToExpire)
    }
  }, [selRefreshToken])

  const localSession = async (credentials) => {
    console.log('localSession: credentials: 🌝🌝🌝🌝🌝 ', credentials)
    try {
      setIsLoading(true)

      const encryptKeys = null
      // const { proxyToken, encryptKeys } = await fetchHeaders()
      // console.log({ proxyToken, encryptKeys })
      // const { accesoPublico, accesoPrivado } = encryptKeys
      // console.log('localSession: accesoPublico: 🍏🍏🍏🍏🍏 ', accesoPublico)
      // console.log('localSession: accesoPrivado: 🍏🍏🍏🍏🍏 ', accesoPrivado)

      await dispatch(session(credentials.email, credentials.password, encryptKeys))

      setIsLoading(false)
      return isLoggedIn
    } catch (err) {
      console.log(err)
      // removeDataInStorage()
      // TODO: redirect to OKTA login page
      return false
    } finally {
      setIsLoading(false)
    }
  }

  const localLogin = async (credentials) => {
    try {
      setIsLoading(true)

      const encryptKeys = null
      // const { proxyToken, encryptKeys } = await fetchHeaders()
      // console.log({ proxyToken, encryptKeys })
      // const { accesoPublico, accesoPrivado } = encryptKeys
      // console.log('accesoPublico: ', accesoPublico)
      // console.log('accesoPrivado: ', accesoPrivado)

      // const aesCrypto = new AESCrypto()
      // const publicAccess = aesCrypto.decrypt(accesoPublico)
      // console.log('publicAccess[AES]: ', publicAccess)
      // const privateAccess = aesCrypto.decrypt(accesoPrivado)
      // console.log('privateAccess[AES]: ', privateAccess)

      // console.log('username [RSA]', credentials.username)
      // const rsaCrypto = new RSACrypto(accesoPublico, accesoPrivado)
      // const encryptedUsername = rsaCrypto.encrypt(credentials.username)
      // console.log('encryptedUsername [RSA]', encryptedUsername)

      await dispatch(login(credentials.username, credentials.password, encryptKeys))
      setIsLoading(false)
      return isLoggedIn
    } catch (err) {
      console.log(err)
      removeDataInStorage()

      // TODO: redirect to login page

      return false
    } finally {
      setIsLoading(false)
    }
  }

  const modules = async () => {
    try {
      setIsLoading(true)
      await dispatch(getModules())
      setIsLoading(false)
      return true
    } catch (err) {
      setIsLoading(false)
      console.log(err)
      return false
    } finally {
      setIsLoading(false)
    }
  }

  const localForgotPassword = async (body) => {
    const encryptKeys = null
    // const { encryptKeys } = await fetchHeaders()
    // const { accesoPublico, accesoPrivado } = encryptKeys
    // console.log(body)
    try {
      setIsLoading(true)
      await dispatch(forgotPassword(body.idUsuario, encryptKeys))
      return true
    } catch (err) {
      console.log(err)
      return false
    } finally {
      setIsLoading(false)
    }
  }

  const localNewPassword = async (values) => {
    const encryptKeys = null
    // const { encryptKeys } = await fetchHeaders()
    // const { accesoPublico, accesoPrivado } = encryptKeys

    try {
      setIsLoading(true)
      await dispatch(newPassword(values, encryptKeys))
      return true
    } catch (err) {
      console.log(err)
      return false
    } finally {
      setIsLoading(false)
    }
  }

  const localLogout = () => {
    const fetchLogout = async () => {
      removeDataInStorage()
      // await dispatch(logout())
      sessionStorage.removeItem('_k')
      sessionStorage.removeItem('_e')
      sessionStorage.removeItem('_u')
      sessionStorage.removeItem('_sa')
      sessionStorage.removeItem('_unknown')
      window.location.replace('/login')
      // console.log('localLogout: BASE_URL_OKTA_REDIRECT: 🟣🟣🟣🟣🟣> ', prefixes.BASE_URL_OKTA_REDIRECT)
      // window.location.replace(`${prefixes.BASE_URL_OKTA_REDIRECT}`)
    }

    fetchLogout().then((_) => 'logout is executed...')
  }

  const localChangePassword = async (body) => {
    const encryptKeys = null
    // const { encryptKeys } = await fetchHeaders()
    // const { accesoPublico, accesoPrivado } = encryptKeys

    try {
      setIsLoading(true)
      await dispatch(changePassword(body.pass, body.pass3, encryptKeys))
      return true
    } catch (err) {
      console.log(err)
      return false
    } finally {
      setIsLoading(false)
    }
  }

  return {
    isLoading,
    session: localSession,
    login: localLogin,
    forgotPassword: localForgotPassword,
    newPassword: localNewPassword,
    logout: localLogout,
    changePassword: localChangePassword,
    modules
  }
}
