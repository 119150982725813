import axiosClient from '../../network/apiClient'

import { COURSE_ENDPOINT } from '../../const'

export const fetchCourses = async () => {
  const token = sessionStorage.getItem('_k')
  const url = COURSE_ENDPOINT.COURSES
  const method = 'get'
  const encryptKeys = null
  const headers = {
    // Authorization: `Bearer ${token}`
  }

  const params = {}
  // console.log(params)

  return await axiosClient({
    method,
    url,
    data: params,
    headers,
    encryptKeys
  })
}

export const fetchCoursesFromStudent = async (id) => {
  const token = sessionStorage.getItem('_k')
  const url = `${COURSE_ENDPOINT.COURSES}/${id}/courses`
  const method = 'get'
  const encryptKeys = null
  const headers = {
    Authorization: `Bearer ${token}`
  }

  const params = {}
  // console.log(params)

  return await axiosClient({
    method,
    url,
    data: params,
    headers,
    encryptKeys
  })
}

export const saveCourse = async (values) => {
  const url = COURSE_ENDPOINT.COURSES
  const method = 'post'
  const encryptKeys = null
  const headers = {}

  const params = {
    ...values
  }
  // console.log(params)

  return await axiosClient({
    method,
    url,
    data: params,
    headers,
    encryptKeys
  })
}

export const updateCourse = async (item) => {
  console.log(item)
  const url = `${COURSE_ENDPOINT.COURSES}/${item.id}`
  const method = 'put'
  const encryptKeys = null
  const headers = {}

  const params = {
    ...item
  }
  // console.log(params)

  return await axiosClient({
    method,
    url,
    data: params,
    headers,
    encryptKeys
  })
}


/*
export const fetchCourse = async (courseId) => {
  const url = `${COURSE_ENDPOINT.GET_COURSES}/${courseId}`
  const method = 'get'
  const encryptKeys = null
  const headers = {}

  const params = {}
  // console.log(params)

  return await axiosClient({
    method,
    url,
    data: params,
    headers,
    encryptKeys
  })
}

export const deleteCourse = async (courseId) => {
  const url = '/usuarios/actualizar-estatus'
  const method = 'put'
  const encryptKeys = null
  const headers = {}

  const params = {
    id: courseId
  }
  // console.log(params)

  return await axiosClient({
    method,
    url,
    data: params,
    headers,
    encryptKeys
  })
}
*/
