import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import SubjectForm from '../Subjects/commons/SubjectForm/SubjectForm'
import SubjectsGrid from './SubjectsGrid/SubjectsGrid'
import SubjectsList from './SubjectsList/SubjectsList'

import { selectTeacher } from '../../../../../commons/redux/selectors/teacher.selectors'
import { getTeachers } from '../../../../../commons/redux/actions/teacher.actions'
import { selectSubject } from '../../../../../commons/redux/selectors/subject.selectors'
import { getSubjects } from '../../../../../commons/redux/actions/subject.actions'

import $ from 'jquery'

import './Subjects.css'

const Subjects = () => {
  const dispatch = useDispatch()
  const { teachers } = useSelector(selectTeacher)
  const { subjects } = useSelector(selectSubject)

  const [gridView, setGridView] = useState(true)

  const toEnableGrid = () => {
    $('.gridTabCourses').gridtab({
      grid: 3,
      tabPadding: 0,
      borderWidth: 0,
      contentPadding: 0,
      responsive: [{
        breakpoint: 520,
        settings: {
          grid: 2
        }
      }]
    })

    $('.respTabsProfile').responsiveTabs({
      startCollapsed: false,
      active: 0
    })

    $('.accRow .accSelector').on('click', function () {
      $(this).toggleClass('open')
      $(this).parent().find('.accWrap').slideToggle()
    })
  }

  useEffect(() => {
    const retrieveTeachers = async () => {
      await dispatch(getTeachers())
    }

    retrieveTeachers().then(_ => {
      console.log('All Teachers are loaded...')
    })
  }, [])

  useEffect(() => {
    const retrieveSubjects = async () => {
      await dispatch(getSubjects())
    }

    retrieveSubjects().then(_ => {
      console.log('All Subjects are loaded...')
      toEnableGrid()
    })
  }, [])

  return (
    <>
      <div className="consoleSection consoleHeader">
        <h2 className="sectionHeader withElements"><span>Materias</span>
          <ul className="view">
            {/*<li>
              <a className="search" href="#" onClick={() => {
                $('.newSearch').slideToggle()
              }}>
              </Link>
            </li>*/}
            <li>
              <a className="create" href="#" onClick={() => {
                $('.newRequest').slideToggle()
              }}>
              </a>
            </li>
            <li>
              <a className={gridView ? 'viewList' : 'viewGrid'} href="#" onClick={
                () => setGridView(!gridView)
              }>
              </a>
            </li>
          </ul>
        </h2>
      </div>

      {/*FILTROS*/}
      {/*<div className="consoleClass">
        <div className="sectionSelect">
          <div className="gridClass">
            <div className="fieldset">
              <span className="select select-color square">
                <select className="color-filter">
                  <option>Matrícula</option>
                  <option>Clase 2019</option>
                  <option>Clase 2020</option>
                  <option>Clase 2021</option>
                </select>
              </span>
            </div>

            <div className="fieldset">
              <span className="select select-color-gray square">
                <select className="color-gray-filter">
                  <option>Estado</option>
                  <option>Avance de curso</option>
                  <option>Estatus de inscripción</option>
                  <option>Mas recientes</option>
                  <option>Mas antiguos</option>
                  <option>Alfabéticamente (A..Z)</option>
                </select>
              </span>
            </div>
          </div>
        </div>
      </div>*/}

      {/*BUSQUEDAS*/}
      {/*<div className="newSearch" style={{display: 'none'}}>
        <SubjectSearch/>
      </div>*/}

      {/*NUEVA MATERIA*/}
      <div className="newRequest" style={{ display: 'none' }}>
        {/*<EnrollmentForm keyOp="add" teachers={teachers}/>*/}
      </div>

      <div className="consoleSection consoleUsers">
        <div className="consoleUsersList">

          {subjects && subjects.length > 0 && (
            <>
              <div style={{ display: `${gridView ? 'block' : 'none'}` }}>
                <SubjectsGrid keyOp="upd"
                              teachers={teachers}
                              items={subjects}/>
              </div>
              <div style={{ display: `${!gridView ? 'block' : 'none'}` }}>
                <SubjectsList keyOp="upd"
                              teachers={teachers}
                              items={subjects}/>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  )

}

export default Subjects
