import { fetchEnrollments, fetchEnrollmentsFromStudent, saveEnrollment, updateEnrollment } from '../services/enrollment.services'
import Swal from 'sweetalert2'

import { LOADER_OFF, LOADER_ON } from './auth.actions'
export const GET_ALL_ENROLLMENTS_INIT = 'GET_ALL_ENROLLMENTS_INIT'
export const GET_ALL_ENROLLMENTS_SUCCESS = 'GET_ALL_ENROLLMENTS_SUCCESS'
export const GET_ALL_ENROLLMENTS_ERROR = 'GET_ALL_ENROLLMENTS_ERROR'
export const GET_ALL_ENROLLMENTS_FROM_STUDENT_INIT = 'GET_ALL_ENROLLMENTS_FROM_STUDENT_INIT'
export const GET_ALL_ENROLLMENTS_FROM_STUDENT_SUCCESS = 'GET_ALL_ENROLLMENTS_FROM_STUDENT_SUCCESS'
export const GET_ALL_ENROLLMENTS_FROM_STUDENT_ERROR = 'GET_ALL_ENROLLMENTS_FROM_STUDENT_ERROR'
export const NEW_ENROLLMENT_INIT = 'NEW_ENROLLMENT_INIT'
export const NEW_ENROLLMENT_SUCCESS = 'NEW_ENROLLMENT_SUCCESS'
export const NEW_ENROLLMENT_ERROR = 'NEW_ENROLLMENT_ERROR'
export const GET_ENROLLMENT_INIT = 'GET_ENROLLMENT_INIT'
export const GET_ENROLLMENT_SUCCESS = 'GET_ENROLLMENT_SUCCESS'
export const GET_ENROLLMENT_ERROR = 'GET_ENROLLMENT_ERROR'
export const UPDATE_ENROLLMENT_INIT = 'UPDATE_ENROLLMENT_INIT'
export const UPDATE_ENROLLMENT_SUCCESS = 'UPDATE_ENROLLMENT_SUCCESS'
export const UPDATE_ENROLLMENT_ERROR = 'UPDATE_ENROLLMENT_ERROR'
export const DELETE_ENROLLMENT_INIT = 'DELETE_ENROLLMENT_INIT'
export const DELETE_ENROLLMENT_SUCCESS = 'DELETE_ENROLLMENT_SUCCESS'
export const DELETE_ENROLLMENT_ERROR = 'DELETE_ENROLLMENT_ERROR'

export function getEnrollments() {
  return async (dispatch) => {
    try {
      dispatch({ type: LOADER_ON })
      dispatch({ type: GET_ALL_ENROLLMENTS_INIT })
      const result = await fetchEnrollments()
      console.log(result)
      if (result.data?.data) {
        dispatch({ type: LOADER_OFF })
        dispatch({ type: GET_ALL_ENROLLMENTS_SUCCESS, data: [...result.data.data] })
      } else {
        dispatch({ type: LOADER_OFF })
        dispatch({ type: GET_ALL_ENROLLMENTS_ERROR })
        await Swal.fire(
          'Advertencia',
          'Ha ocurrido algo inesperado, contacte a soporte',
          'warning'
        )
      }
    } catch (e) {
      dispatch({ type: LOADER_OFF })
      dispatch({ type: GET_ALL_ENROLLMENTS_ERROR })

      /*const sessionActive =
        e.response.data.detalles[0] ===
        'El usuario cuenta con una sesion activa, puedes intentar nuevamente despúes de 10 minutos.'
      if (sessionActive) {
        Swal.fire({
          title: 'Error',
          text: e.response.data.detalles[0],
          confirmButtonText: 'Aceptar'
        }).then(async (result) => {
          if (result.isConfirmed) {
            dispatch({ type: GET_ALL_ENROLLMENT_ERROR })
          }
        })
      } else {
        await Swal.fire('Error', e.response.data.detalles[0], 'warning')
      }*/
    }
  }
}

export function getEnrollmentsFromStudent(id) {
  return async (dispatch) => {
    try {
      dispatch({ type: LOADER_ON })
      dispatch({ type:  GET_ALL_ENROLLMENTS_FROM_STUDENT_INIT })
      const result = await fetchEnrollmentsFromStudent(id)
      console.log(result)
      dispatch({ type: GET_ALL_ENROLLMENTS_FROM_STUDENT_SUCCESS, data: [ ...result.data.data ] })
      dispatch({ type: LOADER_OFF })
      // await Swal.fire('Éxito', 'Operación se realizó con éxito', 'success')
    } catch (e) {
      console.log('getEnrollmentsFromStudent: error: ', e)
      dispatch({ type: GET_ALL_ENROLLMENTS_FROM_STUDENT_ERROR })
      dispatch({ type: LOADER_OFF })
      await Swal.fire('Error', 'Ocurrió algo inesperado, contacte a soporte', 'error')
    }
  }
}

export function newEnrollment(values) {
  return async (dispatch) => {
    try {
      dispatch({ type: LOADER_ON })
      dispatch({ type: NEW_ENROLLMENT_INIT })
      const result = await saveEnrollment(values)
      // console.log(result)
      dispatch({ type: NEW_ENROLLMENT_SUCCESS, data: { ...result.data } })
      dispatch({ type: LOADER_OFF })
      await Swal.fire('Éxito', 'Operación se realizó con éxito', 'success')
    } catch (e) {
      console.log('newEnrollment: error: ', e)
      dispatch({ type: NEW_ENROLLMENT_ERROR })
      dispatch({ type: LOADER_OFF })
      await Swal.fire('Error', 'Ocurrió algo inesperado, contacte a soporte', 'error')
    }
  }
}

export function editEnrollment(values) {
  return async (dispatch) => {
    try {
      dispatch({ type: LOADER_ON })
      dispatch({ type: UPDATE_ENROLLMENT_INIT })
      const result = await updateEnrollment(values)
      // console.log(result)
      dispatch({ type: UPDATE_ENROLLMENT_SUCCESS, data: { ...result.data } })
      dispatch({ type: LOADER_OFF })
      await Swal.fire('Éxito', 'Operación se realizó con éxito', 'success')
    } catch (e) {
      console.log('editEnrollment: error: ', e)
      dispatch({ type: UPDATE_ENROLLMENT_ERROR })
      dispatch({ type: LOADER_OFF })
      await Swal.fire('Error', 'Ocurrió algo inesperado, contacte a soporte', 'error')
    }
  }
}

/*export function getEnrollment(enrollmentId) {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_ENROLLMENT_INIT })
      const result = await fetchEnrollment(enrollmentId)
      // console.log(result)
      dispatch({ type: GET_ENROLLMENT_SUCCESS, data: { ...result.data } })
    } catch (e) {
      console.log('retrieveEnrollment: error: ', e)
      dispatch({ type: GET_ENROLLMENT_ERROR })
      await Swal.fire('Error', e.response.data.detalles[0], 'warning')
    }
  }
}

export function editEnrollment(values) {
  return async (dispatch) => {
    try {
      dispatch({ type: UPDATE_ENROLLMENT_INIT })
      const result = await updateEnrollment(values)
      // console.log(result)
      dispatch({ type: UPDATE_ENROLLMENT_SUCCESS, data: { ...result.data } })
    } catch (e) {
      console.log('editEnrollment: error: ', e)
      dispatch({ type: UPDATE_ENROLLMENT_ERROR })
      await Swal.fire('Error', e.response.data.detalles[0], 'warning')
    }
  }
}

export function removeEnrollment(enrollmentId) {
  return async (dispatch) => {
    try {
      dispatch({ type: DELETE_ENROLLMENT_INIT })
      const result = await deleteEnrollment(enrollmentId)
      // console.log(result)
      dispatch({ type: DELETE_ENROLLMENT_SUCCESS, data: { ...result.data } })
    } catch (e) {
      console.log('removeEnrollment: error: ', e)
      dispatch({ type: DELETE_ENROLLMENT_ERROR })
      await Swal.fire('Error', e.response.data.detalles[0], 'warning')
    }
  }
}*/
