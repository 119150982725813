import React from 'react'

import styles from './CourseAdvance.module.css'

const CourseAdvance = () => {
  return (
    <section className={styles.coursePoints}>
      <div className={styles.coursePointsContainer}>
        <div data-title="Nombre del curso" className={`${styles.dot} ${styles.complete} ${styles.tooltip}`}></div>
        <div data-title="Nombre del curso 2" className={`${styles.dot} ${styles.complete} ${styles.tooltip}`}></div>
        <div data-title="Nombre del curso 3" className={`${styles.dot} ${styles.active} ${styles.tooltip}`}></div>
        <div data-title="Nombre del curso 4" className={`${styles.dot} ${styles.tooltip}`}></div>
        <div data-title="Nombre del curso 5" className={`${styles.dot} ${styles.tooltip}`}></div>
        <div data-title="Nombre del curso 6" className={`${styles.dot} ${styles.tooltip}`}></div>
        <div data-title="Nombre del curso 7" className={`${styles.dot} ${styles.tooltip}`}></div>
        <div data-title="Nombre del curso 8" className={`${styles.dot} ${styles.tooltip}`}></div>
        <div data-title="Nombre del curso 9" className={`${styles.dot} ${styles.tooltip}`}></div>
        <div data-title="Nombre del curso 10" className={`${styles.dot} ${styles.tooltip}`}></div>
        <div data-title="Nombre del curso 11" className={`${styles.dot} ${styles.tooltip}`}></div>
        <div data-title="Nombre del curso 12" className={`${styles.dot} ${styles.tooltip}`}></div>
      </div>
      <h3>Curso Básico</h3>
    </section>
  )
}

export default CourseAdvance
