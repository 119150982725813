import axiosClient from '../../network/apiClient'

import { STUDENT_ENDPOINT } from '../../const'

export const fetchStudents = async () => {
  const token = sessionStorage.getItem('_k')
  const url = STUDENT_ENDPOINT.STUDENTS
  const method = 'get'
  const encryptKeys = null
  const headers = {
    // Authorization: `Bearer ${token}`
  }

  const params = {}
  // console.log(params)

  return await axiosClient({
    method,
    url,
    data: params,
    headers,
    encryptKeys
  })
}

export const saveStudent = async (values) => {
  const url = STUDENT_ENDPOINT.STUDENTS
  const method = 'post'
  const encryptKeys = null
  const headers = {}

  const params = {
    ...values
  }
  // console.log(params)

  return await axiosClient({
    method,
    url,
    data: params,
    headers,
    encryptKeys
  })
}

export const updateStudent = async (item) => {
  console.log(item)
  const url = `${STUDENT_ENDPOINT.STUDENTS}/${item.id}`
  const method = 'put'
  const encryptKeys = null
  const headers = {}

  const params = {
    ...item
  }
  // console.log(params)

  return await axiosClient({
    method,
    url,
    data: params,
    headers,
    encryptKeys
  })
}


/*
export const fetchStudent = async (studentId) => {
  const url = `${USER_ENDPOINT.GET_USERS}/${studentId}`
  const method = 'get'
  const encryptKeys = null
  const headers = {}

  const params = {}
  // console.log(params)

  return await axiosClient({
    method,
    url,
    data: params,
    headers,
    encryptKeys
  })
}

export const deleteStudent = async (studentId) => {
  const url = '/usuarios/actualizar-estatus'
  const method = 'put'
  const encryptKeys = null
  const headers = {}

  const params = {
    id: studentId
  }
  // console.log(params)

  return await axiosClient({
    method,
    url,
    data: params,
    headers,
    encryptKeys
  })
}
*/
