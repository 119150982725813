import React from 'react'

import UserContainer from '../commons/UserContainer/UserContainer'

import defaultAvatar from '../../../../../../commons/assets/images/default-avatar.jpeg'
// import imgProfile from '../../../../../../commons/assets/images/img-profile.jpg'

import './UsersList.css'

const UsersList = ({ keyOp, items, setReload }) => {
  return (
    <div className="listUsers">
      {
        items.map(item => (
          <React.Fragment key={`tl_${item.id}`}>
            <div className="accRow">
              <div className="accSelector">
                <div className="personalData">
                  <div className="imgProfile"><img src={defaultAvatar} alt=""/></div>
                  <p className="name">{item.name} {item.paternal}</p>
                </div>
                <p className="role">Administración</p>
              </div>

              <div className="accWrap">
                <UserContainer keyId={`tl_${item.id}`}
                               keyOp={keyOp}
                               item={item}
                               setReload={setReload}
                />
              </div>
            </div>
          </React.Fragment>
        ))
      }
    </div>
  )
}

export default UsersList