import React from 'react'

import bgDevotional1 from '../../../../../../commons/assets/images/bg-devotional-1.png'
import bgDevotional2 from '../../../../../../commons/assets/images/bg-devotional-2.png'
import bgDevotional3 from '../../../../../../commons/assets/images/bg-devotional-3.png'
import bgDevotional4 from '../../../../../../commons/assets/images/bg-devotional-4.png'
import bgDevotional5 from '../../../../../../commons/assets/images/bg-devotional-5.png'
import bgDevotional6 from '../../../../../../commons/assets/images/bg-devotional-6.png'

import './DevotionalsGrid.css'
import DevotionalForm from '../commons/DevotionalForm/DevotionalForm'

const DevotionalsGrid = () => {

  return (
    <dl className="gridTabCourses">

      <dt>
        <div className="userCard square">
          <div className="imgProfile"><img src={bgDevotional1}/></div>
          <p className="name">5 características de un...</p>
          <p className="role">Jaime Hernández</p>
        </div>
      </dt>
      <dd>
        <DevotionalForm/>
      </dd>
      <dt>
        <div className="userCard square">
          <div className="imgProfile"><img src={bgDevotional2}/></div>
          <p className="name">5 características de un...</p>
          <p className="role">Jaime Hernández</p>
        </div>
      </dt>
      <dd>
        <DevotionalForm/>
      </dd>
      <dt>
        <div className="userCard square">
          <div className="imgProfile"><img src={bgDevotional3}/></div>
          <p className="name">5 características de un...</p>
          <p className="role">Jaime Hernández</p>
        </div>
      </dt>
      <dd>
        <DevotionalForm/>
      </dd>
      <dt>
        <div className="userCard square">
          <div className="imgProfile"><img src={bgDevotional4}/></div>
          <p className="name">5 características de un...</p>
          <p className="role">Jaime Hernández</p>
        </div>
      </dt>
      <dd>
        <DevotionalForm/>
      </dd>
      <dt>
        <div className="userCard square">
          <div className="imgProfile"><img src={bgDevotional5}/></div>
          <p className="name">5 características de un...</p>
          <p className="role">Jaime Hernández</p>
        </div>
      </dt>
      <dd>
        <DevotionalForm/>
      </dd>
      <dt>
        <div className="userCard square">
          <div className="imgProfile"><img src={bgDevotional6}/></div>
          <p className="name">5 características de un...</p>
          <p className="role">Jaime Hernández</p>
        </div>
      </dt>
      <dd>
        <DevotionalForm/>
      </dd>

    </dl>
  )
}

export default DevotionalsGrid