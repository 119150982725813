import React from 'react'

import bgDevotional1 from '../../../../../../commons/assets/images/bg-devotional-1.png'
import bgDevotional2 from '../../../../../../commons/assets/images/bg-devotional-2.png'
import bgDevotional3 from '../../../../../../commons/assets/images/bg-devotional-3.png'
import bgDevotional4 from '../../../../../../commons/assets/images/bg-devotional-4.png'
import bgDevotional5 from '../../../../../../commons/assets/images/bg-devotional-5.png'
import bgDevotional6 from '../../../../../../commons/assets/images/bg-devotional-6.png'

import './DevotionalsList.css'
import DevotionalForm from '../commons/DevotionalForm/DevotionalForm'

const DevotionalsList = () => {

  return (
    <div className="listUsers">

      <div className="accRow">
        <div className="accSelector">
          <div className="personalData">
            <div className="imgProfile"><img src={bgDevotional1}/></div>
            <p className="name">5 características de un...</p>
          </div>
          <p className="date">04/02/2020</p>
          <p className="author">Rick Bowling</p>
        </div>
        <div className="accWrap">
          <DevotionalForm/>
        </div>
      </div>
      <div className="accRow">
        <div className="accSelector">
          <div className="personalData">
            <div className="imgProfile"><img src={bgDevotional2}/></div>
            <p className="name">5 características de un...</p>
          </div>
          <p className="date">04/02/2020</p>
          <p className="author">Rick Bowling</p>
        </div>
        <div className="accWrap">
          <DevotionalForm/>
        </div>
      </div>
      <div className="accRow">
        <div className="accSelector">
          <div className="personalData">
            <div className="imgProfile"><img src={bgDevotional3}/></div>
            <p className="name">5 características de un...</p>
          </div>
          <p className="date">04/02/2020</p>
          <p className="author">Rick Bowling</p>
        </div>
        <div className="accWrap">
          <DevotionalForm/>
        </div>
      </div>
      <div className="accRow">
        <div className="accSelector">
          <div className="personalData">
            <div className="imgProfile"><img src={bgDevotional4}/></div>
            <p className="name">5 características de un...</p>
          </div>
          <p className="date">04/02/2020</p>
          <p className="author">Rick Bowling</p>
        </div>
        <div className="accWrap">
          <DevotionalForm/>
        </div>
      </div>
      <div className="accRow">
        <div className="accSelector">
          <div className="personalData">
            <div className="imgProfile"><img src={bgDevotional5}/></div>
            <p className="name">5 características de un...</p>
          </div>
          <p className="date">04/02/2020</p>
          <p className="author">Rick Bowling</p>
        </div>
        <div className="accWrap">
          <DevotionalForm/>
        </div>
      </div>
      <div className="accRow">
        <div className="accSelector">
          <div className="personalData">
            <div className="imgProfile"><img src={bgDevotional6}/></div>
            <p className="name">5 características de un...</p>
          </div>
          <p className="date">04/02/2020</p>
          <p className="author">Rick Bowling</p>
        </div>
        <div className="accWrap">
          <DevotionalForm/>
        </div>
      </div>

    </div>
)
}

export default DevotionalsList