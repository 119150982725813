const baseRoute = '/protected'
export const prefixes = {
  ADMIN: `${baseRoute}/administrator`,
  TEACHER: `${baseRoute}/teacher`,
  STUDENT: `${baseRoute}/student`
}

export const ADMIN_ROLE = 1
export const TEACHER_ROLE = 2
export const STUDENT_ROLE = 3
