import React from 'react'

import MySlider from '../../../../../../commons/components/MySlider/MySlider'
import StudentTeacherProfile from '../../../../../../commons/components/StudentTeacherProfile/StudentTeacherProfile'
import ClassInfo from '../../../../student/Courses/MyCourses/MySubject/Lecture/ClassInfo/ClassInfo'
import Footer from '../../../../../../commons/components/Footer/Footer'

import styles from '../../Students.module.css'

const TeacherInteraction = () => {
  const _u = JSON.parse(sessionStorage.getItem('_u'))

  return (
    <div className={styles.mainContainer}>
      <MySlider/>
      <StudentTeacherProfile/>
      <ClassInfo roleId={_u.roleId}/>
      <Footer/>
    </div>
  )
}

export default TeacherInteraction