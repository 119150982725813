import React, { useEffect, useState } from 'react'

import imgProfile03 from '../../assets/images/img-profile-03.jpg'

import styles from './UserProfile.module.css'

const UserProfile = ({ roleId = 1 }) => {
  const [user, setUser] = useState(JSON.parse(sessionStorage.getItem('_u')))
  const [roleName, setRoleName] = useState('')

  useEffect(() => {
    switch (+user?.role.id) {
      case 1: // ADMIN
        setRoleName('Administrador')
        break
      case 2: // TEACHER
        setRoleName('Profesor')
        break
      case 3: // STUDENT
        setRoleName('Estudiante')
        break
      default:
        break
    }
  }, [])

  return (
    <section className={styles.profileData}>
      <div className={styles.profileDataCont}>
        <div className={styles.profileDataInfo}>
          <div className={styles.imgProfile}><img src={imgProfile03} alt="Jaime Hernández"/></div>
          <p>{user.name} {user.paternal}</p>
          <span>{roleName}</span>
        </div>
        {roleId && (roleId === 2 || roleId === 3) && (
          <div className={styles.profileMessages}>
            <div className={styles.message}>
              <div className={styles.close}></div>
              <div className={styles['ico-email']}></div>
              <p>Tienes mensajes de tu profesor.</p>
            </div>
            <div className={styles.message}>
              <div className={styles.close}></div>
              <p>La ceremonía de graduación será el 5 de agosto.</p>
            </div>
          </div>
        )}
      </div>
    </section>
  )
}

export default UserProfile
