import {
  GET_ALL_PERIODS_ERROR,
  GET_ALL_PERIODS_INIT,
  GET_ALL_PERIODS_SUCCESS,
  GET_ALL_PERIODS_FROM_STUDENT_INIT,
  GET_ALL_PERIODS_FROM_STUDENT_SUCCESS,
  GET_ALL_PERIODS_FROM_STUDENT_ERROR,
  NEW_PERIOD_INIT,
  NEW_PERIOD_SUCCESS,
  NEW_PERIOD_ERROR,
  UPDATE_PERIOD_INIT,
  UPDATE_PERIOD_SUCCESS,
  UPDATE_PERIOD_ERROR
} from '../actions/period.actions'

const initialState = {
  loading: false,
  error: null,
  newPeriod: null,
  period: null
}

export default (state = initialState, action) => {
  let descendingOrderedPeriods = []
  switch (action.type) {
    case GET_ALL_PERIODS_INIT:
      return { ...state, loading: true, periods: [] }
    case GET_ALL_PERIODS_SUCCESS:
      descendingOrderedPeriods = [...action.data].sort((a, b) => b.id - a.id)
      return { ...state, periods: descendingOrderedPeriods, loading: false }
    case GET_ALL_PERIODS_ERROR:
      return { ...state, loading: false, error: action.error }

    case GET_ALL_PERIODS_FROM_STUDENT_INIT:
      return { ...state, loading: true, periods: [] }
    case GET_ALL_PERIODS_FROM_STUDENT_SUCCESS:
      descendingOrderedPeriods = [...action.data].sort((a, b) => b.id - a.id)
      return { ...state, periods: descendingOrderedPeriods, loading: false }
    case GET_ALL_PERIODS_FROM_STUDENT_ERROR:
      return { ...state, loading: false, error: action.error }

    case NEW_PERIOD_INIT:
      return { ...state, loading: true, period: {} }
    case NEW_PERIOD_SUCCESS:
      descendingOrderedPeriods = [...state.periods, { ...action.data }].sort((a, b) => b.id - a.id)
      return { ...state, period: { ...action.data }, periods: descendingOrderedPeriods, loading: false }
    case NEW_PERIOD_ERROR:
      return { ...state, loading: false, period: {}, error: action.error }
    case UPDATE_PERIOD_INIT:
      return { ...state, loading: true }
    case UPDATE_PERIOD_SUCCESS:
      const filterPeriods = state.periods.filter(period => period.id != action.data.id)
      descendingOrderedPeriods = [...filterPeriods, { ...action.data }].sort((a, b) => b.id - a.id)
      return { ...state, loading: false, periods: descendingOrderedPeriods }
    case UPDATE_PERIOD_ERROR:
      return { ...state, loading: false, error: action.error }
    default:
      return state
  }
}
