import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import { editSubject, newSubject } from '../../../../../../../commons/redux/actions/subject.actions'

import $ from 'jquery'

import './EnrollmentForm.css'

const EnrollmentForm = ({ keyOp = null, item = null }) => {
  const dispatch = useDispatch()

   const handleSubmit = async (values) => {
    console.log('handleSubmit: values: 🐱🐱🐱🐱🐱 ', values)

    if (keyOp === 'add') {
      const addSubject = async () => {
        await dispatch(newSubject(values))
      }
      addSubject().then(_ => {
        console.log('Subject added...')
      })
    } else if (keyOp === 'upd') {
      const updateSubject = async () => {
        await dispatch(editSubject(values))

        /*if (grid) {
          const isActive = window.$('.is-active')
          isActive.removeClass('is-active')
          isActive.next().slideToggle()
        } else {
          window.$('.accSelector').removeClass('open')
          window.$(`.user_${user.id}`).slideToggle()
        }*/

      }
      updateSubject().then(_ => console.log('Subject updated...'))
    }
  }

  return (
    <>
      <Formik
        initialValues={{
          id: item ? item.id : 0,
          name: item ? item.name : '',
          external_id: item ? item.external_id : 0
        }}
        validate={(myValues) => {
          let myErrors = {}
          if (!myValues.name) {
            myErrors.name = 'Debes ingresar el nombre'
          }
          if (!myValues.external_id) {
            myErrors.external_id = 'Debes ingresar ID'
          }
          return myErrors
        }}
        onSubmit={async (myValues, { resetForm }) => {
          // if (keyOp === 'add') resetForm()
          await handleSubmit(myValues)
        }}
      >
        {({ errors }) => (
          <Form className="userInfo subjetcsInfo">
            <Field type="hidden" id="id" name="id"/>
            <Field type="hidden" id="type" name="type"/>
            <div className="fieldset">
              <label>Nombre</label>
              <div className="inputWrap">
                <Field className="rounded"
                       type="text"
                       id="name"
                       name="name"
                       placeholder=""
                />
                <ErrorMessage name="name" component={() => (<label className="error">{errors.name}</label>)}/>
              </div>
            </div>

            <div className="fieldset">
              <label>ID</label>
              <div className="inputWrap">
                <Field className="rounded"
                       type="text"
                       id="external_id"
                       name="external_id"
                       placeholder=""
                />
                <ErrorMessage name="external_id" component={() => (<label className="error">{errors.external_id}</label>)}/>
              </div>
            </div>
            <div className="fieldset right">
              {keyOp === 'add' && (<a className="close" href="#" onClick={() => {
                $('.newRequest').slideToggle()
              }}>Cerrar</a>)}
              <button className="btn purple" type="submit">Guardar</button>
            </div>
          </Form>
        )}
      </Formik>
    </>
  )
}

export default EnrollmentForm