import React, { useState } from 'react'
import { ErrorMessage, Field, Form, Formik } from 'formik'

import { useSession } from '../../../commons/hooks/useSession'
import { prefixes } from '../../../commons/router/constants'
import styles from './Login.module.css'
// import { Redirect } from 'react-router-dom'

const prefix = prefixes.ADMIN

const Login = () => {
  // const history = useHistory()
  const session = useSession()
  // const regExpEMail = new RegExp('(?:[a-z0-9!#$%&\'*+/=?^_`{|}~-]+(?:\\.[a-z0-9!#$%&\'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\\])')
  const regExpEMail = new RegExp('(?:[a-z0-9!#$%&\'*+/=?^_`{|}~-]+(?:\\.[a-z0-9!#$%&\'*+/=?^_`{|}~-]+)*)@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9])\\])')

  // const { expirePassword } = useSelector(selectAuth)
  const [loading, setLoading] = useState(false)
  const [isSignedin, setIsSignedin] = useState(false)

  const handleSubmit = async (values) => {
    localStorage.clear()
    const loginSbm = async () => {
      setLoading(true)
      // await session.login(values)
      await session.session(values)
      /* console.log('handleSubmit: isSignedIn: ', isSignedIn)
      const isSignedIn = sessionStorage.getItem('_unknown') === 'true'

      setLoading(false)
      if (isSignedIn) {
        console.log(`handleSubmit: before redirect to ${prefix}/home`)
        // history.push(`${prefix}/home`)
        return <Redirect to={`${prefix}/home`}/>
      } else {
        console.log(`handleSubmit: before redirect to ${prefix}/login`)
        /!*if (expirePassword) {
          history.push(`${prefix}/change-password`)
        } else {*!/
        // history.push(`/login`)
        return <Redirect to={`/login`}/>
        // }
      }*/
    }

    loginSbm().then((_) => console.log('User signed...'))
  }

  return (
    <>
      <Formik
        initialValues={{
          email: '',
          password: ''
          // countryId: 0
        }}
        validate={(myValues) => {
          let myErrors = {}
          if (!myValues.email) {
            myErrors.email = 'Debes ingresar Correo electrónico'
          } else if (!regExpEMail.test(myValues.email)) {
            myErrors.email = 'Debes ingresar una cuenta válida'
          }
          if (!myValues.password) {
            myErrors.password = 'Debes ingresar Contraseña'
          }
          /*if (myValues.countryId === 0) {
            myErrors.countryId = 'Debes elejir un País'
          }*/
          return myErrors
        }}
        onSubmit={async (myValues, {resetForm}) => {
          resetForm()
          console.log('Sended form...', myValues)
          await handleSubmit(myValues)
        }}
      >
        {({errors}) => (
          <main className={styles.wrapper}>
            <section className={styles.namibhLogin}>
              <div className={styles.loginBox}>
                <div className={styles.logoNamibh}></div>
                <h1 className={`${styles.lightedFont} colored`}>¡Bienvenido de vuelta!</h1>
                <Form>
                  <fieldset>
                    <Field className={styles.lightedFont}
                           type="text"
                           id="email"
                           name="email"
                           placeholder="Correo electrónico"
                    />
                    <ErrorMessage name="email" component={() => (<label className="error">{errors.email}</label>)}/>
                  </fieldset>
                  <fieldset>
                    <Field className={styles.lightedFont}
                           type="password"
                           id="password"
                           name="password"
                           placeholder="Contraseña"
                    />
                    <ErrorMessage name="password"
                                  component={() => (<label className="error">{errors.password}</label>)}/>
                  </fieldset>

                  <fieldset>
                    <button className="btn formButton" type="submit">Iniciar Sesión</button>
                  </fieldset>
                </Form>
                <div className={styles.forgotBtns}>
                  <a className="btn lessPadd" href="#">Olvidé mi contraseña</a>
                  <a className="btn lessPadd" href="#">Olvidé mi correo electrónico</a>
                </div>
              </div>
            </section>
          </main>
        )}

        {/*PRIMER VERSION*/}
        {/*{({
            values,
            errors,
            touched,
            handleSubmit,
            handleChange,
            handleBlur
          }) => (
          <main className={styles.wrapper}>
            <section className={styles.namibhLogin}>
              <div className={styles.loginBox}>
                <div className={styles.logoNamibh}></div>
                <h1 className={`${styles.lightedFont} colored`}>¡Bienvenido de vuelta!</h1>
                <form onSubmit={handleSubmit}>
                  <fieldset>
                    <input className={styles.lightedFont}
                           type="text"
                           id="email"
                           name="email"
                           placeholder="Correo electrónico"
                           value={values.email}
                           onChange={handleChange}
                           onBlur={handleBlur}
                    />
                    {touched.email && errors.email && <label className="error">{errors.email}</label>}
                  </fieldset>
                  <fieldset>
                    <input className={styles.lightedFont}
                           type="password"
                           id="password"
                           name="password"
                           placeholder="Contraseña"
                           value={values.password}
                           onChange={handleChange}
                           onBlur={handleBlur}
                    />
                    {touched.password && errors.password && <label className="error">{errors.password}</label>}
                  </fieldset>
                  <fieldset>
                    <Field className={styles.lightedFont}
                           as="select"
                           name="countryId">
                      <option value="0">Elije una opción</option>
                      <option value="1">Mexico</option>
                      <option value="2">Colombia</option>
                      <option value="3">Argentina</option>
                    </Field>
                    <ErrorMessage name="countryId" component={() => (<label className="error">{errors.countryId}</label>)}/>
                  </fieldset>
                  <fieldset>
                    <div>
                      <label>
                        <Field type="radio" name="gender" value="Male"/> Male
                      </label>
                    </div>
                    <div>
                      <label>
                        <Field type="radio" name="gender" value="Female"/> Female
                      </label>
                    </div>
                  </fieldset>
                  <fieldset>
                    <Field as="textarea" name="message" placeholder="Escriba su mensaje"/>
                  </fieldset>

                  <fieldset>
                    <button className="btn formButton" type="submit">Iniciar Sesión</button>
                  </fieldset>
                </form>
                <div className={styles.forgotBtns}>
                  <a className="btn lessPadd" href="#">Olvidé mi contraseña</a>
                  <a className="btn lessPadd" href="#">Olvidé mi correo electrónico</a>
                </div>
              </div>
            </section>
          </main>
        )}*/}
      </Formik>
    </>
  )
}

export default Login
