import axiosClient from '../../network/apiClient'

import { STUDENT_ENDPOINT, SUBJECT_ENDPOINT } from '../../const'

export const fetchSubjects = async () => {
  const url = SUBJECT_ENDPOINT.SUBJECTS
  const method = 'get'
  const encryptKeys = null
  const headers = {
    // Authorization: `Bearer ${token}`
  }

  const params = {}
  // console.log(params)

  return await axiosClient({
    method,
    url,
    data: params,
    headers,
    encryptKeys
  })
}

export const fetchSubjectsFromStudent = async (id) => {
  const token = sessionStorage.getItem('_k')
  const url = `${STUDENT_ENDPOINT.STUDENTS}/${id}/subjects`
  const method = 'get'
  const encryptKeys = null
  const headers = {
    Authorization: `Bearer ${token}`
  }

  const params = {}
  // console.log(params)

  return await axiosClient({
    method,
    url,
    data: params,
    headers,
    encryptKeys
  })
}

export const saveSubject = async (values) => {
  const url = SUBJECT_ENDPOINT.SUBJECTS
  const method = 'post'
  const encryptKeys = null
  const headers = {}

  const params = {
    ...values
  }
  // console.log(params)

  return await axiosClient({
    method,
    url,
    data: params,
    headers,
    encryptKeys
  })
}

export const updateSubject = async (item) => {
  console.log(item)
  const url = `${SUBJECT_ENDPOINT.SUBJECTS}/${item.id}`
  const method = 'put'
  const encryptKeys = null
  const headers = {}

  const params = {
    ...item
  }
  // console.log(params)

  return await axiosClient({
    method,
    url,
    data: params,
    headers,
    encryptKeys
  })
}


/*
export const fetchSubject = async (subjectId) => {
  const url = `${SUBJECT_ENDPOINT.GET_SUBJECTS}/${subjectId}`
  const method = 'get'
  const encryptKeys = null
  const headers = {}

  const params = {}
  // console.log(params)

  return await axiosClient({
    method,
    url,
    data: params,
    headers,
    encryptKeys
  })
}

export const deleteSubject = async (subjectId) => {
  const url = '/usuarios/actualizar-estatus'
  const method = 'put'
  const encryptKeys = null
  const headers = {}

  const params = {
    id: subjectId
  }
  // console.log(params)

  return await axiosClient({
    method,
    url,
    data: params,
    headers,
    encryptKeys
  })
}
*/
