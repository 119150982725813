import React from 'react'

import SubjectForm from '../SubjectForm/SubjectForm'

import './SubjectContainer.css'

const SubjectContainer = ({ keyOp, teachers, item }) => {

  return (
    <SubjectForm keyOp={keyOp}
                 teachers={teachers}
                 item={item}/>
  )

}

export default SubjectContainer