import React from 'react'

import MySlider from '../../../../commons/components/MySlider/MySlider'
import UserProfile from '../../../../commons/components/UserProfile/UserProfile'
import Subjects from '../../teacher/Home/Subjects/Subjects'
import MyDevotional from '../../../../commons/components/MyDevotional/MyDevotional'
import Congrats from '../../../../commons/components/Congrats/Congrats'
import InviteFriend from '../../../../commons/components/InviteFriend/InviteFriend'
import Footer from '../../../../commons/components/Footer/Footer'

import styles from './Home.module.css'

const Home = () => {
  return (
    <div className={styles.mainContainer}>
      <MySlider/>
      <UserProfile roleId={3}/>
      <Subjects/>
      <MyDevotional/>
      <Congrats/>
      <InviteFriend/>
      <Footer/>
    </div>
    )
}

export default Home
