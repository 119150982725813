import React from 'react'

import StudentForm from '../StudentForm/StudentForm'
import StudentPayment from '../StudentPayment/StudentPayment'
import StudentCourse from '../StudentCourse/StudentCourse'

// import imgProfile from '../../../../../../../commons/assets/images/img-profile.jpg'
import defaultAvatar from '../../../../../../../commons/assets/images/default-avatar.jpeg'

import './StudentContainer.css'

const StudentContainer = ({ keyId = 0, keyOp, item }) => {

  return (
    <div className="studentInfo">
      <div className="imgProfile"><img src={defaultAvatar} alt=""/></div>
      <div className="studentInfoText">
        <p className="name">{item.name} {item.paternal}</p>
        <section className="respTabsProfile">
          <ul className="indicatorGray">
            <li><a href={`#tab-${keyId}1`}>Perfil</a></li>
            <li><a href={`#tab-${keyId}2`}>Pagos</a></li>
            <li><a href={`#tab-${keyId}3`}>Cursos</a></li>
          </ul>

          {/*PERFIL*/}
          <div className="tabContProfile" id={`tab-${keyId}1`}>
            <StudentForm keyOp={keyOp}
                         item={item}/>
          </div>

          {/*PAGOS*/}
          <div className="tabContProfile" id={`tab-${keyId}2`}>
            <StudentPayment/>
          </div>

          {/*CURSOS*/}
          <div className="tabContProfile" id={`tab-${keyId}3`}>
            <StudentCourse/>
          </div>
        </section>
      </div>
    </div>
  )
}

export default StudentContainer